import Api, {params} from '../modules/Api.js';
import {IS_LOADING, ISNT_LOADING} from './isLoading.js';
import {SET_ERROR, RESET_ERROR} from './error.js';

export const GET_RENDEZVOUS_REQUESTED = 'mesRendezVous/GET_RENDEZVOUS_REQUESTED';
export const GET_RENDEZVOUS_SUCCESSFUL = 'mesRendezVous/GET_RENDEZVOUS_SUCCESSFUL';
export const GET_RENDEZVOUS_FAILED = 'mesRendezVous/GET_RENDEZVOUS_FAILED';
export const GET_IS_MEETING_IS_VISIO_REQUESTED = 'mesRendezVous/GET_IS_MEETING_IS_VISIO_REQUESTED';
export const GET_IS_MEETING_IS_VISIO_SUCCESFUL = 'mesRendezVous/GET_IS_MEETING_IS_VISIO_SUCCESFUL';
export const GET_IS_MEETING_IS_VISIO_FAILED = 'mesRendezVous/GET_IS_MEETING_IS_VISIO_FAILED';
export const GET_TIMESLOTS_REQUESTED = 'mesRendezVous/GET_TIMESLOTS_REQUESTED';
export const GET_TIMESLOTS_SUCCESFUL = 'mesRendezVous/GET_TIMESLOTS_SUCCESFUL';
export const GET_TIMESLOTS_FAILED = 'mesRendezVous/GET_TIMESLOTS_FAILED';
export const POST_NEW_RDV_REQUESTED = 'mesRendezVous/POST_NEW_RDV_REQUESTED';
export const POST_NEW_RDV_SUCCESFUL = 'mesRendezVous/POST_NEW_RDV_SUCCESFUL';
export const POST_NEW_RDV_FAILED = 'mesRendezVous/POST_NEW_RDV_FAILED';
export const CANCEL_RDV_REQUESTED = 'mesRendezVous/CANCEL_RDV_REQUESTED';
export const CANCEL_RDV_SUCCESSFUL = 'mesRendezVous/CANCEL_RDV_SUCCESSFUL';
export const CANCEL_RDV_FAILED = 'mesRendezVous/CANCEL_RDV_FAILED';
export const CLEAR_TIME_SLOT = 'mesRendezVous/CLEAR_TIME_SLOT';
export const START_MEETING = 'mesRendezVous/START_MEETING';
export const END_MEETING = 'mesRendezVous/END_MEETING';

const initialState = {
  rendezVous: {},
  startTime: null,
  endTime: null
};

export default function MesRendezVous(state = initialState, action) {
  switch (action.type) {
    case GET_RENDEZVOUS_REQUESTED:
    case GET_RENDEZVOUS_FAILED:
      return initialState;
    case CANCEL_RDV_REQUESTED:
      return state; 
    case GET_RENDEZVOUS_SUCCESSFUL:
      return {...state,  rendezVous : action.rendezVous, types: action.types, categories: action.categories };
    case GET_TIMESLOTS_SUCCESFUL:
      return {...state, timeslots: action.timeslots };
    case CANCEL_RDV_SUCCESSFUL :
      return {...state, rendezVous : state.rendezVous.map(item => action.itBeenCanceled.idMeeting === item.idMeeting ? {
        ...item,
      } : item),
      rdvStatusFromAPI : action.rdvStatusFromAPI
      };
    case GET_IS_MEETING_IS_VISIO_SUCCESFUL :
      return {...state, rendezVous: state.rendezVous.map(item => action.idMeeting === item.idMeeting ? {
        ...item,
        canBevisio: action.canBevisio
      } : item),
      rdvStatusFromAPI : action.rdvStatusFromAPI
      };
    case POST_NEW_RDV_SUCCESFUL :
      return {...state, rdvStatusFromAPI : action.rdvStatusFromAPI };
    case CLEAR_TIME_SLOT :
      return {...state, timeslots: null};
    case START_MEETING:
      return { ...state, startTime: Date.now() };
    case END_MEETING:
      return { ...state, endTime: Date.now() };
    default:
      return state;
  }
}

export const getMeetings = () =>
  dispatch => {
    dispatch({type: RESET_ERROR});
    dispatch({type: IS_LOADING});
    dispatch({type: GET_RENDEZVOUS_REQUESTED});

    return Api('/meetings', {params: params})
      .then(res => {
        dispatch({
          type: GET_RENDEZVOUS_SUCCESSFUL,
          rendezVous: res.data.sessions,
          types: res.data.types,
          categories : res.data.categories
        });
        dispatch({type: ISNT_LOADING});
      })
      .catch(err => {
        dispatch({type: GET_RENDEZVOUS_FAILED});
        if (err.response.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({type: ISNT_LOADING});
      });
  };

export const isModifyMeetingCanBeVisio = async (idMeeting) =>
  dispatch => {
    dispatch({type: RESET_ERROR});
    dispatch({type: IS_LOADING});
    dispatch({type: GET_IS_MEETING_IS_VISIO_REQUESTED});

    return Api('/meetings/is-eligible-to-video', { params: params })
      .then(res => {
        dispatch({
          type: GET_IS_MEETING_IS_VISIO_SUCCESFUL,
          canBevisio: res.data.is_eligible_to_video,
          idMeeting: idMeeting
        });
        dispatch({type: ISNT_LOADING});
      })
      .catch(err => {
        dispatch({type: GET_IS_MEETING_IS_VISIO_FAILED});
        if (err.response.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({type: ISNT_LOADING});
      });
  };

export const getTimeSlots = ( idMeeting, type ) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({type: GET_TIMESLOTS_REQUESTED});

    return Api(`/meeting/${idMeeting}/timeslots/${type}`, { params: params })
      .then((res) => {
        dispatch({
          type: GET_TIMESLOTS_SUCCESFUL,
          timeslots : res.data
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_TIMESLOTS_FAILED });
        if (err.response.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const postNewRendezvous = async (monRendezVous, date, idCoach, rdvType) =>
  dispatch => {
    dispatch({type: RESET_ERROR});
    dispatch({type: IS_LOADING});
    dispatch({type: POST_NEW_RDV_REQUESTED});

    const data = {
      'meeting_id' : monRendezVous.idMeeting,
      'date' : date,
      'type_id' : rdvType,
      'coach_id' : idCoach
    };

    return Api.put('/meetings/check-availability', data, { params: params })
      .then((res) => {
        dispatch({
          type: POST_NEW_RDV_SUCCESFUL,
          rdvStatusFromAPI : res.data ? res.data : res.status
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: POST_NEW_RDV_FAILED });
        if (err.response.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const cancelRendezvous = async (idMeeting) =>
  dispatch => {
    dispatch({type: RESET_ERROR});
    dispatch({type: IS_LOADING});
    dispatch({type: CANCEL_RDV_REQUESTED});

    return Api.put(`/meeting/${idMeeting}/cancelation`, null, { params: params })
      .then((res) => {
        dispatch({
          type: CANCEL_RDV_SUCCESSFUL,
          itBeenCanceled: { response: res.status === 200, idMeeting: idMeeting },
          rdvStatusFromAPI : res.data ? res.data : res.status
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type:CANCEL_RDV_FAILED });
        if (err.response.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };



export const startMeeting = () => dispatch => {
  dispatch({type: START_MEETING});
};

export const endMeeting = () => dispatch => {
  dispatch({type: END_MEETING});
};

export const postMeetingDuration  = (idMeeting) => (dispatch, getState) => {
  const { startTime, endTime }= getState().mesRendezVous;

  if (startTime !== null && endTime !== null) {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });

    const meetingDuration = (endTime - startTime) / 60000;
    return Api.put(`/meeting/${idMeeting}/save-duration`, {meetingDuration}, { params: params })
      .then((res) => {
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        if (err?.response?.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err,
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  }
};

export const clearTimeSlot = () =>
  dispatch => {
    dispatch({ type: CLEAR_TIME_SLOT });
  };