import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateParameter } from '@/stores/me';
import { setDegrees } from '@/stores/degrees';

const Settings = ({ me, goToNextSection, updateParameter, setDegrees }) => {
  const onChangeCheckbox = (e) =>
    updateParameter({ [e.target.name]: e.target.checked });

  const submitDegrees = () => {
    setDegrees([]).then(() => {
      goToNextSection();
    });
  };

  return (
    <React.Fragment>
      <div className="flex layout-column bdb-1-gray-light pgb-1">
        <div className="flex align-start-center">
          <div className="w-3 h-3 bd-radius bg-success text-white flex align-center-center mgr-0_5">
            <svg className="w-2 h-2"><use xlinkHref="#icon-chat" /></svg>
          </div>
          <p className="no-margin mgr-auto">Recevoir des messages des élèves de ma promotion</p>
          <input type="checkbox" name="receiveMessages" id="receiveMessages" className="checkbox-switch" onChange={onChangeCheckbox} defaultChecked={me.parameter.receiveMessages} />
          <label htmlFor="receiveMessages" className="checkbox-switch mgb-0"></label>
        </div>
      </div>
      <div className="mgt-1">
        <button className="button primary" onClick={() => submitDegrees()}>Valider</button>
      </div>
    </React.Fragment>
  );
};

Settings.propTypes = {
  me: PropTypes.object,
  onChangeCheckbox: PropTypes.func,
  submitDegrees: PropTypes.func,
  goToNextSection: PropTypes.func,
  updateParameter: PropTypes.any,
  setDegrees: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    me: state.me,
  };
};

export default connect(mapStateToProps, { updateParameter, setDegrees })(
  Settings
);
