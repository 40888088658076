import Api, { params } from '../modules/Api.js';
import queryString from 'query-string';
import { SET_ERROR, RESET_ERROR } from './error';

export const SEND_TIME_REQUESTED = 'Send_time/SEND_TIME_REQUESTED';
export const SEND_TIME_SUCCESSFUL = 'Send_time/SEND_TIME_SUCCESSFUL';
export const SEND_TIME_FAILED = 'Send_time/SEND_TIME_FAILED';

let initialState = {};

export default function UserTime(state = initialState, action) {
  switch (action.type) {
    case SEND_TIME_SUCCESSFUL:
      return {
        ...state,
      };
    case SEND_TIME_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export const sendTime = (data, contractNumber) => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  let coach =
    queryString.parse(window?.location?.search)['_coaching'] ||
    localStorage.getItem('coach');
  if (!coach && !!contractNumber?.length) {
    return Api.post(`/user_domain_offer/time/${contractNumber}`, data, {
      params: params,
    })
      .then(() => {
        dispatch({ type: SEND_TIME_SUCCESSFUL });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
        dispatch({ type: SEND_TIME_FAILED });
      });
  } else {
    return null;
  }
};
