import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Alertbanner = ({
  me,
}) => {

  const [showAlertBanner, updateShowAlertBanner] = useState(true);

  const closeAlertBanner = () => {
    updateShowAlertBanner(!showAlertBanner);
    localStorage.setItem('alertbanner', me?.alert.id.toString());
  };

  const createMarkup = (text) => {
    return {__html: text};
  };

  return (
    <div className={ `alertbanner ${me?.alert?.alwaysVisible ? 'bg-turquoise' : 'bg-teal-dark'} pgv-0_75 ${!showAlertBanner && 'hide'}` }>
      <div className="row">
        <div className={`cef-text-white cef-flex cef-justify-center cef-w-full ${me?.alert?.alwaysVisible ? 'cef-font-bold' : ''}`}>
          <span dangerouslySetInnerHTML={createMarkup(me?.alert?.message)}></span>
          {me?.alert?.alwaysVisible === false && (
            <span className="cef-absolute cef-right-0 cef-text-white hover:cef-text-neutral-600 anim-all curp" onClick={ () => closeAlertBanner() }><svg className="w-3 h-3"><use xlinkHref="#icon-close"/></svg></span>
          )}
        </div>
      </div>
    </div>
  );
};


Alertbanner.propTypes = {
  me: PropTypes.object,
  closeAlertBanner: PropTypes.func,
  showAlertBanner: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    me: state.me,
  };
};

export default connect(mapStateToProps, null)(Alertbanner);

