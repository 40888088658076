import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDegrees, setDegrees } from '@/stores/degrees';

const Degrees = ({ degrees, getDegrees, setDegrees, goToNextSection }) => {
  useEffect(() => {
    getDegrees();
  }, [getDegrees]);

  const [settedDegrees, setSettedDegrees] = useState([]);

  const handleDegreeUpdate = (degreeId) => {
    let index = settedDegrees.indexOf(degreeId);
    index > -1
      ? settedDegrees.splice(index, 1)
      : setSettedDegrees((prevState) => [...prevState, degreeId]);
    // settedDegrees.push(degreeId);
  };

  const submitDegrees = () => {
    setDegrees(settedDegrees).then(() => {
      goToNextSection();
    });
  };

  return (
    <React.Fragment>
      <div className='onboarding__scroller__container mgb-1'>
        <div className='onboarding__scroller'>
          <div className='onboarding__scroller__shadow'></div>
          <h3 className='no-margin mgb-0_25'>Vos diplômes</h3>
          <p className='no-margin mgb-1'>
            Certains diplômes peuvent modifier votre parcours. Une vérification
            sera effectuée par CENEF pour valider l’obtention de ces diplômes
          </p>
          {degrees.length &&
            degrees.map((degree, index) => (
              <div
                className={`flex align-start-center bdb-1-gray-light pgv-0_5 ${
                  !index ? 'bdt-1-gray-light' : ''
                }`}
                key={degree.id}
              >
                <p className='no-margin mgr-auto'>{degree.name}</p>
                <input
                  type='checkbox'
                  name={`degree${degree.id}`}
                  id={`degree${degree.id}`}
                  className='checkbox-switch'
                  onChange={() => {
                    handleDegreeUpdate(degree.id);
                  }}
                  defaultChecked={degree.hasBeenDone}
                />
                <label
                  htmlFor={`degree${degree.id}`}
                  className='checkbox-switch mgb-0'
                ></label>
              </div>
            ))}
          <div className='onboarding__scroller__shadow'></div>
        </div>
      </div>
      <div className='mgt-1'>
        <button className='button primary' onClick={() => submitDegrees()}>
          Valider
        </button>
      </div>
    </React.Fragment>
  );
};

Degrees.propTypes = {
  degrees: PropTypes.array,
  submitDegrees: PropTypes.func,
  handleDegreeUpdate: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    degrees: state.degrees,
  };
};

export default connect(mapStateToProps, { getDegrees, setDegrees })(Degrees);
