import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { NavLink, Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { addToGTM } from '../molecules/gtm';

const EnhancedLink = ({
  isNavLink,
  children,
  track,
  isAuthenticated,
  isActive,
  activeClassName,
  style,
  paymentBanner,
  staticContext,
  dispatch,
  ...props
}) => {
  props.to =
    typeof props.to === 'string'
      ? { pathname: props.to, isAuthenticated, isActive, paymentBanner }
      : { ...props.to, isAuthenticated, isActive, paymentBanner };

  const onClick = () => {
    return track ? 
      addToGTM(track)
      : null;
  };

  useEffect(() => {
    if (track)
      onClick();
  }, [track]);

  
  return isNavLink ? (
    <NavLink style={style} activeClassName={activeClassName} {...props} >{children}</NavLink>
  ) : (
    <Link style={style} {...props} >{children} </Link>
  );
};

EnhancedLink.propTypes = {
  isNavLink: PropTypes.bool,
  children: PropTypes.any,
  to: PropTypes.any,
  isAuthenticated: PropTypes.any,
  isActive: PropTypes.any,
  paymentBanner: PropTypes.any,
  track: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
    isActive: state.me?.selectedOffer && state.me?.selectedOffer?.isActive,
    paymentBanner:
      state.me?.selectedOffer && state.me?.selectedOffer?.paymentBanner,
  };
};

export default withRouter(connect(mapStateToProps)(EnhancedLink));
