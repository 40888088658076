import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import fr from '@/lang/fr.js';
import {connect} from 'react-redux';

const PrivateRoute = ({ isAuthenticated, maintenance, isActive, paymentBanner, suspendedForNegotiation, component: Component, props, ...rest }) => (
  <>
    <Route
      {...rest}
      render={p =>
        isAuthenticated ? (
          !maintenance ? (
            isActive ||
          (!isActive && paymentBanner && p.location?.pathname === `/${fr.route.account}/${fr.route.profile_payment}`) ||
          (!isActive && !paymentBanner && p.location?.pathname === `/${fr.route.suspendedAccount}` ) ||
          (!isActive && (paymentBanner || suspendedForNegotiation) && p.location?.pathname === `/${fr.route.chat}`) ||
          (!isActive && p.location.pathname === '/contentPreview' && p.location.search.includes('ContentType') && !suspendedForNegotiation && !paymentBanner)
              ? (
                <Component {...p} { ...props } />
              ) : (
                paymentBanner ? (
                  <Redirect
                    to={{
                      pathname: `/${fr.route.account}/${fr.route.profile_payment}`,
                      state: { from: p.location }
                    }}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: `/${fr.route.suspendedAccount}`,
                      state: { from: p.location }
                    }}
                  />
                )
              )
          ) : (
            <Redirect
              to={{
                pathname: '/maintenance',
                state: { from: p.location }
              }}
            />
          )
        ) : !maintenance ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: p.location }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/maintenance',
              state: { from: p.location }
            }}
          />
        )
      }
    />
  </>
);

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  maintenance: PropTypes.bool,
  isActive: PropTypes.bool,
  paymentBanner: PropTypes.bool,
  component: PropTypes.any.isRequired,
  props: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    me: state.me,
    isAuthenticated: state.auth?.isAuthenticated,
    maintenance: state.auth?.maintenance,
    isActive: state.me?.selectedOffer && state.me?.selectedOffer?.isActive,
    paymentBanner:
      state.me?.selectedOffer && state.me?.selectedOffer?.paymentBanner,
    suspendedForNegotiation: state.me?.selectedOffer && state.me?.selectedOffer?.suspendedForNegotiation
  };
};
export default connect(mapStateToProps)(PrivateRoute);
