import React from 'react';
import PropTypes from 'prop-types';
import formatDate from '@/utils/formatDate';
import { useHistory } from 'react-router-dom';
import { useDispatch } from'react-redux';
import { setBreadcrumb, setBreadcrumbLastItem, resetBreadcrumb } from '@/stores/contentBlock';
import fr from '@/lang/fr.js';

const AlertbannerLateHomework = ({
  numberLateHomeworks,
  dateLateHomworks,
  idLateCours,
  idLateHomework,
  isContentBlock
}) => {

  let history = useHistory();
  const dispatch = useDispatch();

  const transformDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const year = dateObj.getFullYear();
    const final = day + ' ' + month + ' ' + year;
    return final;
  };

  const handleClick = ()=> {
    if (isContentBlock) {
      dispatch(resetBreadcrumb());
      history.push(`/${fr.route.homeworkV2}/${idLateHomework}`);
      dispatch(setBreadcrumbLastItem(fr.route.home));
      dispatch(setBreadcrumb('devoir en retard'));
    } else if (!isContentBlock) {
      history.push(
        `/${fr.route.evaluate}/${idLateCours}/${fr.route.homework}/${idLateHomework}/${fr.route.presentation}`
      );
    }
  };

  return (
    <div className='alertbanner pgv-0_75 bg-yellow'>
      <div className='row'>
        <div className='column-12 fz-13 fw-600 text-white text-center mgb-0'>
          <p style={{ marginBottom: '0' }} className='bold'>
            Vous avez {numberLateHomeworks}{' '}
            {numberLateHomeworks > 1 ? 'devoirs' : 'devoir'} en retard depuis le{' '}
            {transformDate(formatDate(dateLateHomworks, 'MM-dd-yyyy'))}.
          </p>
          <p style={{ marginBottom: '0' }} className='bold'>
            {numberLateHomeworks > 1
              ? 'Pour réaliser votre devoir le plus en retard'
              : 'Pour le réaliser'}
            , cliquez{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleClick}
            >
              ICI
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

AlertbannerLateHomework.propTypes = {
  numberLateHomeworks: PropTypes.number,
  isContentBlock: PropTypes.bool
};

export default AlertbannerLateHomework;
