import React from 'react';
import PropTypes from 'prop-types';

import fr from '@/lang/fr.js';

const AlertBox = ({ message, type, className }) => {
  message =
    message
      .split(/\n/)
      .reduce(
        (t, s, i) =>
          i === 0
            ? [...t, <React.Fragment key={i.toString()}>{s}</React.Fragment>]
            : [
              ...t,
              <br key={(i + 20).toString()} />,
              <React.Fragment key={i.toString()}>{s}</React.Fragment>,
            ],
        []
      ) || fr.error.default;
  let FinalClassName = [
    'pg-0_5',
    ...(type === 'alert' ? 'bg-alert-muted text-alert bd-1-alert text-center' : '').split(
      ' '
    ),
    ...(type === 'success' ? 'bg-success-muted bd-1-success text-center' : '').split(' '),
    ...(type === 'info' ? 'bg-info-light bd-1-info text-center' : '').split(' '),
  ].join(' ');

  return (
    <div className={FinalClassName}>
      <p className='no-margin'>{message}</p>
    </div>
  );
};

AlertBox.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default AlertBox;
