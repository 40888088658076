import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import 'react-intl-tel-input/dist/main.css';
import flags from 'react-phone-number-input/flags';
import frLocales from 'react-phone-number-input/locale/fr.json';
import fr from '@/lang/fr.js';

const Input = (props) => {
  const {
    input,
    label,
    type,
    svg,
    className,
    span,
    preferredCountries,
    phoneValue,
    placeholder,
    SetPlaceholderObject,
    SetPhonesObject,
    phonesObject,
    placeholderObject,
    required,
    validations,
    maxlength,
    name,
    ...custom
  } = props;

  const [Validations, setValidations] = useState();
  const [valid, setValid] = useState();

  const typePattern = {
    email:
      /(^([A-Z|a-z|0-9](\.|_|\+){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$)|^$/,
    date: /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
    year: /^\d{4}$/,
    number: /[+-]?([0-9]*[.])?[0-9]+/,
    studentNumber: /[A-Za-z]{1}[\d]{6,6}[A-Za-z]{1}$/,
  };

  useEffect(() => {
    setValidations(validations);
  }, [validations]);

  useEffect(() => {
    setValid([
      ...(Validations || []),
      ...[
        required && {
          pattern: /^.+$/,
          error: fr.input.required,
        },
        fr.input[type] &&
          typePattern[type] && {
          pattern: typePattern[type],
          error: fr.input[type],
        },
      ].filter((v) => v),
    ]);
  }, [Validations]);

  const validate = (element) => {
    let errors = valid
      .map((v) => (!v.pattern.test(element.value) ? v.error : false))
      .filter((e) => e)
      .join('\n');
    let parent = element.parentNode;
    parent.classList.toggle('error', errors !== '');
    parent.setAttribute('data-error', errors);
    element.classList.toggle('valid', errors === '');
  };

  // const handleKeyPress = (e) => {
  //     maxlength &&
  //       e.target.value.length >= maxlength &&
  //       e.preventDefault();
  // }

  const HandleChangeNumber = (v, name, SetPhonesObject, phonesObject) => {
    if (name === 'phone1') {
      SetPhonesObject({ ...phonesObject, 1: v });
    }
    if (name === 'phone2') {
      SetPhonesObject({ ...phonesObject, 2: v });
    }
    if (name === 'phone3') {
      SetPhonesObject({ ...phonesObject, 3: v });
    }
  };

  const handleFetchCallingCode = (
    v,
    name,
    SetPlaceholderObject,
    placeholderObject
  ) => {
    if (v) {
      getCountryCallingCode(v);
      if (SetPlaceholderObject) {
        if (name === 'phone1') {
          SetPlaceholderObject({
            ...placeholderObject,
            1: getCountryCallingCode(v),
          });
        }
        if (name === 'phone2') {
          SetPlaceholderObject({
            ...placeholderObject,
            2: getCountryCallingCode(v),
          });
        }
        if (name === 'phone3')
          SetPlaceholderObject({
            ...placeholderObject,
            3: getCountryCallingCode(v),
          });
      }
    }
  };

  return (
    <>
      {svg ? (
        <span className={`${className} input-group search`}>
          {label}
          <input
            placeholder={placeholder}
            autoComplete='on'
            type={type}
            label={label}
            {...input}
            {...custom}
          />
          <button type='submit'>
            <svg>
              <use xlinkHref={`#icon-${svg}`} />
            </svg>
          </button>
        </span>
      ) : type !== 'tel' ? (
        <label className={className}>
          {label}
          <input
            onKeyUp={(element) => validate(element.target)}
            name={name}
            // onKeyPress={element => handleKeyPress(element)}
            placeholder={placeholder}
            autoComplete='on'
            type={type}
            label={label}
            {...input}
            {...custom}
          />
        </label>
      ) : (
        type === 'tel' && (
          <span>
            {label}
            <PhoneInput
              defaultCountry='FR'
              placeholder={
                placeholder ? '+' + placeholder : fr.profile.form.phone
              }
              name={name}
              flags={flags}
              className='phoneInput'
              limitMaxLength={true}
              addInternationalOption={false}
              value={phoneValue}
              labels={frLocales}
              onCountryChange={(v) => {
                handleFetchCallingCode(
                  v,
                  name,
                  SetPlaceholderObject,
                  placeholderObject
                );
              }}
              countryOptionsOrder={preferredCountries}
              onChange={(v) => {
                HandleChangeNumber(v, name, SetPhonesObject, phonesObject);
              }}
            />
          </span>
        )
      )}
    </>
  );
};

Input.propTypes = {
  input: PropTypes.any,
  span: PropTypes.element,
  className: PropTypes.string,
  svg: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  preferredCountries: PropTypes.array,
  phoneValue: PropTypes.string,
  placeholder: PropTypes.string,
  SetPlaceholderObject: PropTypes.string,
  SetPhonesObject: PropTypes.string,
  phonesObject: PropTypes.string,
  placeholderObject: PropTypes.string,
  required: PropTypes.any,
  validations: PropTypes.any,
  maxlength: PropTypes.number,
  name: PropTypes.string,
};


export default Input;
