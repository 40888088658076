import { useEffect } from 'react';

import gtmParts from 'react-google-tag-manager';

import env from '../../env';

const GoogleTagManager = () => {

  let gtm = gtmParts({
    id: env.GTM.code,
    additionalEvents: { loginStatus: 'unlogged' },
    dataLayerName: 'dataLayer'
  });

  let hasCode = !!env.GTM.code;

  let Script = hasCode ? gtm.noScriptAsReact() : null;

  useEffect(() => {
    if (!window['dataLayer'] && hasCode) {
      eval(gtm.scriptAsHTML().replace(/<\/?script>/g, '')); // eslint-disable-line
    }
  }, []);

  return Script;
};

export default GoogleTagManager;


export const addToGTM = data => {
  if (env.debug) { console.log('Adding to GTM dataLayer', data); }
  window['dataLayer'] && window['dataLayer'].push(data);
};