import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_SURVEY_QUESTIONS_REQUESTED = 'badges/GET_SURVEY_QUESTIONS_REQUESTED';
export const GET_SURVEY_QUESTIONS_SUCCESSFUL = 'badges/GET_SURVEY_QUESTIONS_SUCCESSFUL';
export const GET_SURVEY_QUESTIONS_FAILED = 'badges/GET_SURVEY_QUESTIONS_FAILED';
export const POST_SURVEY_QUESTIONS_REQUESTED = 'badges/POST_SURVEY_QUESTIONS_REQUESTED';
export const POST_SURVEY_QUESTIONS_SUCCESSFUL = 'badges/POST_SURVEY_QUESTIONS_SUCCESSFUL';
export const POST_SURVEY_QUESTIONS_FAILED = 'badges/POST_SURVEY_QUESTIONS_FAILED';
export const SKIP_SURVEY_REQUESTED = 'badges/SKIP_SURVEY_REQUESTED';
export const SKIP_SURVEY_SUCCESSFUL = 'badges/SKIP_SURVEY_SUCCESSFUL';
export const SKIP_SURVEY_FAILED = 'badges/SKIP_SURVEY_FAILED';

const initialState = [];

export default function satisfactionSurvey(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_QUESTIONS_REQUESTED:
    case GET_SURVEY_QUESTIONS_FAILED:
    case POST_SURVEY_QUESTIONS_REQUESTED:
    case POST_SURVEY_QUESTIONS_SUCCESSFUL:
    case POST_SURVEY_QUESTIONS_FAILED:
    case SKIP_SURVEY_REQUESTED:
    case SKIP_SURVEY_FAILED:
      return initialState;
    case GET_SURVEY_QUESTIONS_SUCCESSFUL:
      return action.data;
    default:
      return state;
  }
}

export const getSatisfactionSurveyQuestions = (surveyId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_SURVEY_QUESTIONS_REQUESTED });

  return Api(`/satisfactionSurvey/${surveyId}/questions`, { params: params })
    .then((res) => {
      dispatch({
        type: GET_SURVEY_QUESTIONS_SUCCESSFUL,
        data: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_SURVEY_QUESTIONS_FAILED });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const postSatisfactionSurveyAnswers = (answers, surveyId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: POST_SURVEY_QUESTIONS_REQUESTED });

  return Api.post(`/satisfactionSurvey/${surveyId}/answers`,answers, { params: params })
    .then((res) => {
      dispatch({
        type: POST_SURVEY_QUESTIONS_SUCCESSFUL
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: POST_SURVEY_QUESTIONS_FAILED });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const skipSatisfactionSurey = (surveyId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: SKIP_SURVEY_REQUESTED });

  return Api.post(`/satisfactionSurvey/${surveyId}/skipped`,{}, { params: params })
    .then((res) => {
      dispatch({
        type: SKIP_SURVEY_SUCCESSFUL
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: POST_SURVEY_QUESTIONS_FAILED });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

