import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_RESSOURCES_REQUESTED = 'ressources/GET_RESSOURCES_REQUESTED';
export const GET_RESSOURCES_SUCCESSFUL = 'ressources/GET_RESSOURCES_SUCCESSFUL';
export const GET_LEARNING_RESSOURCES_SUCCESSFUL = 'ressources/GET_LEARNING_RESSOURCES_SUCCESSFUL';
export const GET_RESSOURCES_FAILED = 'ressources/GET_RESSOURCES_FAILED';

export const GET_PLAYLIST_REQUESTED = 'ressources/GET_PLAYLIST_REQUESTED';
export const GET_PLAYLIST_SUCCESSFUL = 'ressources/GET_PLAYLIST_SUCCESSFUL';
export const GET_PLAYLIST_FAILED = 'ressources/GET_PLAYLIST_FAILED';

export const RESSOURCES_DOWNLOAD_REQUESTED =
  'ressources/RESSOURCES_DOWNLOAD_REQUESTED';
export const RESSOURCES_DOWNLOAD_SUCCESSFUL =
  'ressources/RESSOURCES_DOWNLOAD_SUCCESSFUL';
export const RESSOURCES_DOWNLOAD_FAILED =
  'ressources/RESSOURCES_DOWNLOAD_FAILED';
export const GET_DOCUMENT_WITH_SIGN =
    'ressources/RESSOURCES_DOCUMENT_WITH_SIGN';

const initialState = {
  documents: [],
  playlist: [],
  download: { file: null, name: null },
  documentWithSign: null,
};

export default function Ressources(state = initialState, action) {
  switch (action.type) {
    case GET_RESSOURCES_SUCCESSFUL:
      return {
        ...state,
        documents: action.ressources,
      };
    case GET_LEARNING_RESSOURCES_SUCCESSFUL:
      return {
        ...state,
        documents: action.ressources,
      };
    case GET_RESSOURCES_FAILED:
      return initialState;
    case GET_PLAYLIST_SUCCESSFUL:
      return {
        ...state,
        playlist: action.playlist,
      };
    case GET_PLAYLIST_FAILED:
      return initialState;
    case RESSOURCES_DOWNLOAD_SUCCESSFUL:
      return {
        ...state,
        download: { file: action.download.file, name: action.download.name },
      };
    case GET_DOCUMENT_WITH_SIGN:
      return {
        ...state,
        documentWithSign: action.documentWithSign,
      };
    case RESSOURCES_DOWNLOAD_FAILED:
      return state;
    default:
      return state;
  }
}

export const downloadDocument = (document) => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESSOURCES_DOWNLOAD_REQUESTED });
  return Api.get(document.url, { responseType: 'blob' })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers['content-type'] });
      dispatch({
        type: RESSOURCES_DOWNLOAD_SUCCESSFUL,
        download: { file: file, name: document.name },
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch(() => {
      dispatch({ type: RESSOURCES_DOWNLOAD_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const getRessources = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_RESSOURCES_REQUESTED });

  return Api('/ressources', { params: params })
    .then((res) => {
      dispatch({
        type: GET_RESSOURCES_SUCCESSFUL,
        ressources: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_RESSOURCES_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getLearningRessources = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_RESSOURCES_REQUESTED });

  return Api('/learning_ressources', { params: params })
    .then((res) => {
      dispatch({
        type: GET_LEARNING_RESSOURCES_SUCCESSFUL,
        ressources: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_RESSOURCES_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getPlaylist = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_PLAYLIST_REQUESTED });
  return Api('/playlist', { params: params })
    .then((res) => {
      dispatch({
        type: GET_PLAYLIST_SUCCESSFUL,
        playlist: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_PLAYLIST_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const signRessources = (resourceId) => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  return Api(`/ressources/${resourceId}/sign`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getLearningRessources());
        dispatch({type: ISNT_LOADING});
      } else {
        dispatch({
          type: SET_ERROR,
          value: 'Une erreur est survenue.',
        });
      }
    })
    .catch((err) => {
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const downloadRessourceWithSignature = (document, name) => async (dispatch) => {
  dispatch({
    type: RESSOURCES_DOWNLOAD_SUCCESSFUL,
    download: { file: document, name: name },
  });
};