import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_REPORTS_REQUESTED = 'GET_REPORTS_REQUESTED';
export const GET_REPORTS_SUCCESSFUL = 'GET_REPORTS_SUCCESSFUL';
export const GET_REPORTS_FAILED = 'GET_REPORTS_FAILED';

const initialState = {};

export default function Report(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS_REQUESTED:
      return initialState;
    case GET_REPORTS_SUCCESSFUL:
      return action.data;
    case GET_REPORTS_FAILED:
      return initialState;
    default:
      return state;
  }
}

export const getReport = () => async dispatch => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_REPORTS_REQUESTED });

  return Api('/report', { params: params })
    .then((data) => {
      dispatch({
        type: GET_REPORTS_SUCCESSFUL,
        data: data.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_REPORTS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
