import React, {useState} from 'react';
import PropTypes from 'prop-types';

import fr from '@/lang/fr';

function createMarkup(html) {
  return {__html: html};
}

const Legals = ({ goToNextSection }) => {
  
  const [canSubmitLegals, updateSubmitLegalsAbility] = useState(false);
  
  const onChange = e => updateSubmitLegalsAbility(e.target.checked);
  
  return (

    <React.Fragment>
      <div className="onboarding__scroller__container mgb-1">
        <div className="onboarding__scroller">
          <div className="onboarding__scroller__shadow"></div>
          <h2>{fr.cgu.soustitre}</h2>
          {
            Object.keys(fr.cgu.articles).map(article =>{
              return (
                <>
                  <h3>Article {article} : {fr.cgu.articles[article].titre}</h3>
                  {
                    Object.keys(fr.cgu.articles[article].content).map(paragraphe => {
                      if(typeof fr.cgu.articles[article].content[paragraphe] !== 'object') {
                        return <p dangerouslySetInnerHTML={createMarkup(fr.cgu.articles[article].content[paragraphe])}></p>;
                      }else {
                        return <div dangerouslySetInnerHTML={createMarkup(fr.cgu.articles[article].content[paragraphe].content)}></div>;
                      }
                    })
                  }
                </>
              );
            })
          }
          <div className="onboarding__scroller__shadow"></div>
        </div>
      </div>
      <div>
        <div className="flex align-start-center pgb-0_5">
          <input type="checkbox" name="legalsSubmitValidation" id="legalsSubmitValidation" className="checkbox-switch" onChange={e => onChange(e) } />
          <label htmlFor="legalsSubmitValidation" className="checkbox-switch mgb-0"></label>
          <label htmlFor="legalsSubmitValidation" className="mgb-0 mgl-0_5">J'ai lu et j'accepte les conditions générales d'utilisation</label>
        </div>
        <button className="button primary" disabled={!canSubmitLegals} onClick={goToNextSection}>Accepter</button>
      </div>
    </React.Fragment>
  );
};

Legals.propTypes = {
  goToNextSection: PropTypes.func,
};

export default Legals;
