import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';

import isLoading from '@/stores/isLoading';
import error from '@/stores/error';
import auth from '@/stores/auth';
import me from '@/stores/me';
import countries from '@/stores/countries';
import selected from '@/stores/selected';
import toasts from '@/stores/toasts';
import courses from '@/stores/courses';
import chapters from '@/stores/chapters';
import subChapters from '@/stores/subChapters';
import homeworks from '@/stores/homeworks';
import questionsGroups from '@/stores/questionsGroups';
import positioningGroups from '@/stores/questionsPositioningGroups';
import videos from '@/stores/videos';
import users from '@/stores/users';
import faq from '@/stores/faq';
import agenda from '@/stores/agenda';
import internships from '@/stores/internships';
import report from '@/stores/report';
import ranking from '@/stores/ranking';
import userTime from '@/stores/userTime';
import joboffers from '@/stores/joboffers';
import flipbook from '@/stores/flipbook';
import dashboard from '@/stores/dashboard';
import degrees from '@/stores/degrees';
import badges from '@/stores/badges';
import challenges from '@/stores/challenges';
import chat from '@/stores/chat';
import schoolReport from '@/stores/schoolReport';
import notifications from '@/stores/notifications';
import examinations from '@/stores/examinations';
import ressources from '@/stores/ressources';
import invoices from '@/stores/invoices';
import rules from '@/stores/rules';
import contracts from '@/stores/contracts';
import userDomainOfferReducer from '@/stores/userDomainOfferExaminations';
import videoconferences from '@/stores/videoconferences';
import contentBlock from '@/stores/contentBlock';
import mesRendezVous from '@/stores/mesRendezVous';
import satisafactionSurvey from '@/stores/satisfactionSurvey';

export default combineReducers({
  form: formReducer,
  isLoading,
  error,
  auth,
  me,
  countries,
  positioningGroups,
  selected,
  toasts,
  schoolReport,
  courses,
  chapters,
  subChapters,
  homeworks,
  questionsGroups,
  videos,
  flipbook,
  users,
  faq,
  agenda,
  internships,
  report,
  userTime,
  ranking,
  dashboard,
  degrees,
  userDomainOfferReducer,
  joboffers,
  badges,
  challenges,
  chat,
  notifications,
  examinations,
  ressources,
  invoices,
  rules,
  contracts,
  videoconferences,
  contentBlock,
  mesRendezVous,
  satisafactionSurvey
});
