import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading.js';
import { SET_ERROR, RESET_ERROR } from './error.js';

export const GET_DASHBOARD_REQUESTED = 'dashboard/GET_DASHBOARD_REQUESTED';
export const GET_DASHBOARD_SUCCESSFUL = 'dashboard/GET_DASHBOARD_SUCCESSFUL';
export const GET_DASHBOARD_FAILED = 'dashboard/GET_DASHBOARD_FAILED';

const initialState = {};

export default function Dashboard(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_REQUESTED:
      return initialState;
    case GET_DASHBOARD_SUCCESSFUL:
      return action.data;
    case GET_DASHBOARD_FAILED:
      return initialState;
    default:
      return state;
  }
}

export const getDashboard = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_DASHBOARD_REQUESTED });

  return Api('/dashboard', { params: params })
    .then((res) => {
      dispatch({
        type: GET_DASHBOARD_SUCCESSFUL,
        data: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_DASHBOARD_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
