import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './index.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Modal = ({style, ShowOrNot, setShowPopin, children, sm, md, xl, video, addClass, layerClosable = true, buttonClosable = true, shouldCloseModal, stopMeeting}) => {

  const [timeoutDisplay, setTimeoutDisplay] = useState(false);

  useEffect(() => {
    if (ShowOrNot === true)
    {
      setTimeout(() => {setTimeoutDisplay(true);}, 50);
      document.body.style.overflow = 'hidden';
    }
    else if (ShowOrNot === false);
    document.body.style.overflow = 'unset';
  }, [ShowOrNot]);

  const classes = classNames({
    'popin': true,
    'bg-bg-primary': true,
    'closable': true,
    'loaded': true,
    'show': timeoutDisplay && true
  });

  const handleClose = (closable, e) => {
    if ((shouldCloseModal === undefined || shouldCloseModal())) {
      if (closable) {
        if (stopMeeting !== undefined) {
          stopMeeting();
        }
        setTimeoutDisplay(false);
        setTimeout(() => {
          setShowPopin(false);
        }, 500);
      }
    } else {
      alert('Veuillez cliquer sur j\'ai lu ce document');
    }
    e.stopPropagation();
  };
  
  return (
    <React.Fragment>
      <div
        style={style}
        id="close"
        className={classes}
        onClick={(e) => {
          handleClose(layerClosable, e);
        }}
      >
        { buttonClosable &&
          <span onClick={(e) => handleClose(buttonClosable, e)} data-dismiss="modal" aria-label="Close"
            className="close icon pg-0_25 round">
            <svg className="w-4 h-4">
              <use xlinkHref="#icon-close"/>
            </svg>
          </span>
        }
        <div onClick={e => e.stopPropagation() && e.preventDefault()} style={style} className={`popin__inner ${addClass ? addClass : ''} ${xl ? 'homework__presentation' : ''} ${sm ? '__small' : ''} ${md ? '__medium' : ''} ${video ? 'video__presentation' : ''}`}>
          {children && children}
        </div>
      </div>
    </React.Fragment>
  );
};

Modal.propTypes = {
  ShowOrNot: PropTypes.bool,
  setShowPopin: PropTypes.func,
  children: PropTypes.any,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  xl: PropTypes.bool,
  video: PropTypes.bool,
  style: PropTypes.any,
  layerClosable: PropTypes.bool,
  buttonClosable: PropTypes.bool,
  shouldCloseModal: PropTypes.func,
  stopMeeting: PropTypes.func
};


export default connect()(Modal);