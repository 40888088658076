import Api, { params } from '../modules/Api.js';
import store from '../store';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_INTERNSHIPS_REQUESTED =
  'internships/GET_INTERNSHIPS_REQUESTED';
export const GET_INTERNSHIPS_SUCCESSFUL =
  'internships/GET_INTERNSHIPS_SUCCESSFUL';
export const GET_INTERNSHIPS_FAILED = 'internships/GET_INTERNSHIPS_FAILED';
export const GET_CONTRACT_FORM_REQUESTED = 'internships/GET_CONTRACT_FORM_REQUESTED';
export const GET_CONTRACT_FORM_SUCCESFULL = 'internships/GET_CONTRACT_FORM_SUCCESFULL';
export const GET_CONTRACT_FORM_FAILED = 'internships/GET_CONTRACT_FORM_FAILED';
export const NEXT_BUTTON_STATUS = 'internships/NEXT_BUTTON_STATUS';
export const POST_NEW_ORGANISATION_REQUESTED = 'internships/POST_NEW_ORGANISATION_REQUESTED';
export const POST_NEW_ORGANISATION_SUCCESSFULL = 'internships/POST_NEW_ORGANISATION_SUCCESSFULL';
export const POST_NEW_ORGANISATION_FAILED = 'internships/POST_NEW_ORGANISATION_FAILED';
export const UPDATE_ORGANISATION_REQUESTED = 'internships/UPDATE_ORGANISATION_REQUESTED';
export const UPDATE_ORGANISATION_SUCCESSFUL = 'internships/UPDATE_ORGANISATION_SUCCESSFUL';
export const UPDATE_ORGANISATION_FAILED = 'internships/UPDATE_ORGANISATION_FAILED';
export const RESET_INPUT_FORM = 'internships/RESET_INPUT_FORM';
export const POST_NEW_CONVENTION_REQUESTED = 'internships/POST_NEW_CONVENTION_REQUESTED';
export const POST_NEW_CONVENTION_SUCCESSFULL = 'internships/POST_NEW_CONVENTION_SUCCESSFULL';
export const POST_NEW_CONVENTION_FAILED = 'internships/POST_NEW_CONVENTION_FAILED';
export const DELETE_CONVENTION_REQUESTED = 'internships/DELETE_CONVENTION_REQUESTED';
export const DELETE_CONVENTION_SUCCESSFULL = 'internships/DELETE_CONVENTION_SUCCESSFULL';
export const DELETE_CONVENTION_FAILED = 'internships/DELETE_CONVENTION_FAILED';
export const GET_INTERNSHIPS_EVALUATION_REQUESTED  = 'internships/GET_INTERNSHIPS_EVALUATION_REQUESTED';
export const GET_INTERNSHIPS_EVALUATION_SUCCESSFUL = 'internships/GET_INTERNSHIPS_EVALUATION_SUCCESSFUL';
export const GET_INTERNSHIPS_EVALUATION_FAILED = 'internships/GET_INTERNSHIPS_EVALUATION_FAILED';
export const POST_INTERNSHIPS_EVALUATION_REQUESTED  = 'internships/POST_INTERNSHIPS_EVALUATION_REQUESTED';
export const POST_INTERNSHIPS_EVALUATION_FAILED = 'internships/POST_INTERNSHIPS_EVALUATION_FAILED';
export const GET_INTERNSHIPS_DOCUMENTS_REQUESTED  = 'internships/GET_INTERNSHIPS_DOCUMENTS_REQUESTED';
export const GET_INTERNSHIPS_DOCUMENTS_SUCCESSFUL = 'internships/GET_INTERNSHIPS_DOCUMENTS_SUCCESSFUL';
export const GET_INTERNSHIPS_DOCUMENTS_FAILED = 'internships/GET_INTERNSHIPS_DOCUMENTS_FAILED';
export const PUT_INTERNSHIP_TO_DRAFT_REQUESTED  = 'internships/PUT_INTERNSHIP_TO_DRAFT_REQUESTED';
export const PUT_INTERNSHIP_TO_DRAFT_SUCCESSFUL = 'internships/PUT_INTERNSHIP_TO_DRAFT_SUCCESSFUL';
export const PUT_INTERNSHIP_TO_DRAFT_FAILED = 'internships/PUT_INTERNSHIP_TO_DRAFT_FAILED';
export const PUT_INTERNSHIP_FORCE_EVALUATION_REQUESTED  = 'internships/PUT_INTERNSHIP_FORCE_EVALUATION_REQUESTED';
export const PUT_INTERNSHIP_FORCE_EVALUATION_SUCCESSFUL  = 'internships/PUT_INTERNSHIP_FORCE_EVALUATION_SUCCESSFUL';
export const PUT_INTERNSHIP_FORCE_EVALUATION_FAILED  = 'internships/PUT_INTERNSHIP_FORCE_EVALUATION_FAILED';
export const RESET_YOUSIGN_LINK = 'internships/RESET_YOUSIGN_LINK';
export const GET_INTERN_LINK = 'internships/GET_INTERN_LINK';

const initialState = {};

export default function Interships(state = initialState, action) {
  switch (action.type) {
    case GET_INTERNSHIPS_SUCCESSFUL:
      return { ...state, instershipsInformation : action.data};
    case GET_INTERNSHIPS_FAILED:
    case GET_CONTRACT_FORM_FAILED:
    case POST_NEW_ORGANISATION_FAILED:
    case UPDATE_ORGANISATION_FAILED:
    case POST_NEW_CONVENTION_FAILED:
    case DELETE_CONVENTION_FAILED:
    case GET_INTERNSHIPS_EVALUATION_FAILED:
    case GET_INTERNSHIPS_DOCUMENTS_FAILED:
    case POST_INTERNSHIPS_EVALUATION_FAILED:
      return initialState;
    case GET_CONTRACT_FORM_REQUESTED:
    case GET_INTERNSHIPS_REQUESTED:
    case POST_NEW_ORGANISATION_REQUESTED:
    case UPDATE_ORGANISATION_REQUESTED:
    case POST_NEW_CONVENTION_REQUESTED:
    case GET_INTERNSHIPS_EVALUATION_REQUESTED:
    case GET_INTERNSHIPS_DOCUMENTS_REQUESTED:
    case DELETE_CONVENTION_REQUESTED:
    case POST_INTERNSHIPS_EVALUATION_REQUESTED:
      return state;
    case GET_CONTRACT_FORM_SUCCESFULL:
      return {...state,
        contractFormInformation: action.data,
        nextStep: action.data.nextStep,
        apiCodeStatus: action.data.code,
        apiMessage: action.data.message,
        conventionId: action.data.convention ? action.data.convention : state.conventionId,
        timestamp: new Date().getTime()
      };
    case NEXT_BUTTON_STATUS:
      return {...state, nextButtonStatus: action.data};
    case UPDATE_ORGANISATION_SUCCESSFUL:
      return {...state, organisationId: action.data.structure, conventionId: action.data.convention};
    case POST_NEW_CONVENTION_SUCCESSFULL:
      return {...state, conventionId: action.data};
    case RESET_INPUT_FORM:
      return {...state, resetInputForm: action.data};
    case DELETE_CONVENTION_SUCCESSFULL:
      return {...state,
        internshipsTable:
          {...state.internshipsTable,
            internships: state.internshipsTable.internships.filter((item) => item.agreement.id !== action.data)}};
    case GET_INTERNSHIPS_EVALUATION_SUCCESSFUL:
      return {...state, internshipsEvaluation: action.data};
    case GET_INTERNSHIPS_DOCUMENTS_SUCCESSFUL:
      return {...state, internshipsTable: action.data};
    case RESET_YOUSIGN_LINK:
      return {...state, contractFormInformation : { ...state.contractFormInformation, link : null }};
    case GET_INTERN_LINK:
      return {...state, internLink: action.data};
    default:
      return state;
  }
}

export const getInternships = () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_INTERNSHIPS_REQUESTED });

  return Api('/internships', { params: params })
    .then((res) => {
      dispatch({
        type: GET_INTERNSHIPS_SUCCESSFUL,
        data: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_INTERNSHIPS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const postIntership = async (data, conventionId, goBack = false, force = false) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_CONTRACT_FORM_REQUESTED });
  let state = store.getState();
  let statut = state?.internships?.contractFormInformation?.statut;
  const allowNull = ['autreAvantageGratification', 'gratificationModePaiement', 'gratificationMontant'];
  Object.keys(data).forEach(k => data[k] === null && !allowNull.some(allow => k === allow) ? delete data[k] : data[k]);

  if (force) {
    data['force'] = true;
  }

  let files = Object.keys(data).includes('filesOrganisation') ? data?.filesOrganisation : null;

  return Api.post(`/internship_form${conventionId ? '/' + conventionId : ''}`,
    (goBack && statut && statut > 1) ? { statut: statut - 2, force : data.force } : data,
    { params: params })
    .then((res) => {
      dispatch({
        type: GET_CONTRACT_FORM_SUCCESFULL,
        data: res.data
      });
      if (files && res.status === 200 && conventionId) {
        dispatch({ type: IS_LOADING });
        return Api.post(`/internship_form/files/${conventionId}`, files, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: params,
        }).then(()=>{
          dispatch({ type: ISNT_LOADING });
          return res.data.nextStep;
        })
          .catch(() => {
            dispatch({ type: GET_CONTRACT_FORM_FAILED });
            dispatch({ type: ISNT_LOADING });
          });
      }
      dispatch({ type: ISNT_LOADING });
      return res.data.nextStep;
    })
    .catch((err) => {
      dispatch({ type: GET_CONTRACT_FORM_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const setNextButtonStatus = (status) => (dispatch) => {
  dispatch({
    type : NEXT_BUTTON_STATUS,
    data : status
  });
};

export const resetInputForm = (value) => (dispatch) => {
  dispatch({
    type: RESET_INPUT_FORM,
    data: value
  });
};
export const createConvention = async () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: POST_NEW_CONVENTION_REQUESTED });

  return Api.post('/form-internship-infos', {}, { params: params })
    .then((res) => {
      dispatch({
        type: POST_NEW_CONVENTION_SUCCESSFULL,
        data: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: POST_NEW_CONVENTION_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const deleteConvention = async (id, isIntership = true) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: DELETE_CONVENTION_REQUESTED });

  return Api.delete(`/internship_delete/${isIntership ? 'agreement' : 'evaluation'}/${id}`,{ params: params })
    .then((res) => {
      dispatch({
        type: DELETE_CONVENTION_SUCCESSFULL,
        data: id,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: DELETE_CONVENTION_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const createEvaluationForm = async (conventionId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: POST_INTERNSHIPS_EVALUATION_REQUESTED });

  return Api.post('/internships-evaluation/' + conventionId, {statut : 0}, { params: params })
    .then((res) => {
      dispatch({ type: ISNT_LOADING });
      if(res.data.nextStep) {
        return res.data.evaluation;
      }
    })
    .catch((err) => {
      dispatch({ type: POST_INTERNSHIPS_EVALUATION_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const getEvaluationForm = async (conventionId, evaluationId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_INTERNSHIPS_EVALUATION_REQUESTED });

  return Api.get('/internships-evaluation/' + conventionId + '/' + evaluationId, { params: params })
    .then((res) => {
      dispatch({ type: ISNT_LOADING });
      dispatch({
        type : GET_INTERNSHIPS_EVALUATION_SUCCESSFUL,
        data: res.data
      });
    })
    .catch((err) => {
      dispatch({ type: GET_INTERNSHIPS_EVALUATION_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const saveEvaluationForm = async (conventionId, evaluationId, data, status) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: POST_INTERNSHIPS_EVALUATION_REQUESTED });

  data.statut = status;
  return Api.post('/internships-evaluation/' + conventionId + '/' + evaluationId, data, { params: params })
    .then((res) => {
      dispatch({ type: ISNT_LOADING });
      if(res.data.nextStep) {
        return true;
      }
    })
    .catch((err) => {
      dispatch({ type: POST_INTERNSHIPS_EVALUATION_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const getInternshipDocuments = async () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_INTERNSHIPS_DOCUMENTS_REQUESTED });

  return Api.get('/internship_documents/', { params: params })
    .then((res) => {
      dispatch({
        type: GET_INTERNSHIPS_DOCUMENTS_SUCCESSFUL,
        data: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_INTERNSHIPS_DOCUMENTS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const removeInternshipDocument = async (documentId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });

  return Api.delete('/internship_document_delete/' + documentId, { params: params })
    .then((res) => {
      dispatch({ type: ISNT_LOADING });
      return res.data;
    })
    .catch((err) => {
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
      return false;
    });
};

export const putInternshiptoDraft = async (intershipId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: PUT_INTERNSHIP_TO_DRAFT_REQUESTED });

  return Api.put('/internship_draft/' + intershipId, { params: params })
    .then((res) => {
      dispatch({ type: PUT_INTERNSHIP_TO_DRAFT_SUCCESSFUL });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: PUT_INTERNSHIP_TO_DRAFT_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const putEvaluationToDraft = async(evaluationId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: PUT_INTERNSHIP_TO_DRAFT_REQUESTED });

  return Api.put('/internship_evaluation_draft/' + evaluationId, { params: params })
    .then((res) => {
      dispatch({ type: PUT_INTERNSHIP_TO_DRAFT_SUCCESSFUL });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: PUT_INTERNSHIP_TO_DRAFT_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });

};

export const putForceEvaluation = async(intershipId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: PUT_INTERNSHIP_FORCE_EVALUATION_REQUESTED });

  return Api.put('/internship_force_evaluation/' + intershipId, { params: params })
    .then((res) => {
      dispatch({ type: PUT_INTERNSHIP_FORCE_EVALUATION_SUCCESSFUL });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: PUT_INTERNSHIP_FORCE_EVALUATION_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const clearYouSignLink = () => (dispatch) => {
  dispatch({
    type: RESET_YOUSIGN_LINK
  });
};

export const getPdf = (type, id, hash) => (dispatch)  => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});

  return Api.get(`${type}_pdf/${id}/${hash}`, {params: params})
    .then((res) => {
      dispatch({type: ISNT_LOADING});
      dispatch({
        type: GET_INTERN_LINK,
        data: res.data,
      });
    })
    .catch((err) => {

      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR ,
          value: err ,
        });
      }
      dispatch({type: ISNT_LOADING});
    });
};

export const signPdf = (type, id, signDocument) => (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});

  return Api.put(`${type}_sign_pdf/${id}`,{signatureOk : signDocument}, {params: params})
    .then((res) => {
      dispatch({type: ISNT_LOADING});

    })
    .catch((err) => {

      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR ,
          value: err ,
        });
      }
      dispatch({type: ISNT_LOADING});
    });
};