import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { check } from '@/stores/me';
import OnboardingPositioningTest from './Onboarding';

const mapStateToProps = (state) => {
  return {
    posts: state.posts,
    me: state.me,
    isAuthenticated: state.auth?.isAuthenticated,
    positioningState: state.auth.positioningState,
  };
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      check,
    },
    dispatch
  )
)(OnboardingPositioningTest);
