import Api, {params} from '../modules/Api.js';

import {IS_LOADING, ISNT_LOADING} from './isLoading.js';
import {SET_ERROR, RESET_ERROR} from './error.js';

export const GET_VIDEOCONFERENCES_REQUESTED = 'videoconferences/GET_VIDEOCONFERENCES_REQUESTED';
export const GET_VIDEOCONFERENCES_SUCCESSFUL = 'videoconferences/GET_VIDEOCONFERENCES_SUCCESSFUL';
export const GET_VIDEOCONFERENCES_FAILED = 'videoconferences/GET_VIDEOCONFERENCES_FAILED';

export const POST_URLVISIO_REQUESTED = 'videoconferences/POST_URLVISIO_REQUESTED';
export const POST_URLVISIO_SUCCESSFUL = 'videoconferences/POST_URLVISIO_SUCCESSFUL';
export const POST_URLVISIO_FAILED = 'videoconferences/POST_URLVISIO_FAILED';
export const DELETE_URL_VISIO = 'videoconferences/DELETE_URL_VISIO';

const initialState = {
  currentMeetingId: null,
  urlVisio: null,
  videoConferencesGoWrong : null
};

export default function Videoconferences(state = initialState, action) {
  switch (action.type) {
    case GET_VIDEOCONFERENCES_REQUESTED:
      return initialState;
    case GET_VIDEOCONFERENCES_SUCCESSFUL:
      return action.videoconferences;
    case GET_VIDEOCONFERENCES_FAILED:
      return initialState;
    case POST_URLVISIO_REQUESTED:
      return {
        ...state
      };
    case POST_URLVISIO_SUCCESSFUL:
      return {
        ...state,
        urlVisio: action.urlVisio,
        currentMeetingId: action.meetingId,
        videoConferencesGoWrong : null
      };
    case POST_URLVISIO_FAILED:
      return {
        ...state,
        videoConferencesGoWrong : true
      };
    case DELETE_URL_VISIO :
      return {
        ...state,
        urlVisio: null,
        currentMeetingId: null
      };
    default:
      return state;
  }
}

export const getVideoconferences = () =>
  dispatch => {
    dispatch({type: RESET_ERROR});
    dispatch({type: IS_LOADING});
    dispatch({type: GET_VIDEOCONFERENCES_REQUESTED});

    return Api('/agenda/visios', {params: params})
      .then(res => {
        dispatch({
          type: GET_VIDEOCONFERENCES_SUCCESSFUL,
          videoconferences: res.data
        });
        dispatch({type: ISNT_LOADING});
      })
      .catch(err => {
        dispatch({type: GET_VIDEOCONFERENCES_FAILED});
        if (err.response.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({type: ISNT_LOADING});
      });
  };

export const getUrlVisio = infos =>
  dispatch => {
    dispatch({type: RESET_ERROR});
    dispatch({type: IS_LOADING});
    dispatch({type: POST_URLVISIO_REQUESTED});

    return Api.post('/agenda/meeting', infos, {params: params})
      .then(res => {
        if(Object.keys(res.data).length === 0){
          dispatch({type: POST_URLVISIO_FAILED});
          dispatch({
            type: SET_ERROR,
            value: 'error'
          });
        } else {
          dispatch({
            type: POST_URLVISIO_SUCCESSFUL,
            meetingId: infos?.meeting_id,
            urlVisio: res.data.url
          });
        }
        dispatch({type: ISNT_LOADING});
      })
      .catch(err => {
        dispatch({type: POST_URLVISIO_FAILED});
        if (err.response.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({type: ISNT_LOADING});
      });
  };
export const deleteUrl = () =>
  dispatch => {
    dispatch({type: DELETE_URL_VISIO});
  };