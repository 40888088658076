import Api, { params } from '../modules/Api.js';
import { isAfter, isBefore } from 'date-fns';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { RESET_ERROR, SET_ERROR } from './error';

export const GET_COURSES_REQUESTED = 'courses/GET_COURSES_REQUESTED';
export const GET_COURSES_SUCCESSFUL = 'courses/GET_COURSES_SUCCESSFUL';
export const GET_COURSES_FAILED = 'courses/GET_COURSES_FAILED';

const initialState = [];

export default function Courses(state = initialState, action) {
  switch (action.type) {
    case GET_COURSES_REQUESTED:
      return initialState;
    case GET_COURSES_SUCCESSFUL:
      return action.courses;
    case GET_COURSES_FAILED:
      return initialState;
    default:
      return state;
  }
}

let getCourseDateRange = (course) => {
  let result = {};

  course.userCourses.forEach((uc) => {
    (!result.startDate || isBefore(uc.startDate, result.startDate)) &&
      uc.startDate &&
      (result.startDate = uc.startDate);
    (!result.endDate || isAfter(uc.endDate, result.endDate)) &&
      uc.endDate &&
      (result.endDate = uc.endDate);
  });

  return result;
};

export const getCourses = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_COURSES_REQUESTED });

  return Api('/courses', { params: params })
    .then((res) => {
      let { data } = res;
      let datas = {};
      datas.mandatoryCourses = data.mandatory.courses
        .map((c) => ({
          ...c,
          mandatory: true,
          categoryName: data.mandatory.categoryName,
          subCategoryName: c.categoryName,
          userCourses: c.userCourses
            // Remove empty userCourses
            .filter((uc) => uc.scoring.divisor)
            // Replace date from server with JS date
            .map((uc) => ({
              ...uc,
              startDate: uc.startDate ? uc.startDate : null,
              endDate: uc.endDate ? uc.endDate : null,
            })),
        }))
        // Set global course date range
        .map((c) => ({ ...c, ...getCourseDateRange(c) }));
      datas.optionnalCourses = data.optional.courses
        .map((c) => ({
          ...c,
          mandatory: false,
          categoryName: data.optional.categoryName,
          subCategoryName: c.categoryName,
          userCourses: c.userCourses
            // Remove empty userCourses
            .filter((uc) => uc.scoring.divisor)
            // Replace date from server with JS date
            .map((uc) => ({
              ...uc,
              startDate: uc.startDate ? uc.startDate : null,
              endDate: uc.endDate ? uc.endDate : null,
            })),
        }))
        // Set global course date range
        .map((c) => ({ ...c, ...getCourseDateRange(c) }));

      dispatch({
        type: GET_COURSES_SUCCESSFUL,
        courses: datas.mandatoryCourses.concat(datas.optionnalCourses),
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_COURSES_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
