import React from 'react';
import { useHistory } from 'react-router-dom';
import fr from '@/lang/fr.js';
import { useSelector } from 'react-redux';

const AlertbannerLatePayment = () => {

  const showCPFContractSuspended = useSelector(state=>state?.me?.selectedOffer?.showCPFContractSuspended);

  let history = useHistory();

  return (
    <div className='alertbanner pgv-0_75 bg-alert'>
      <div className='row'>
        <div className='column-12 fz-13 fw-600 text-white text-center mgb-0'>
          <p style={{ marginBottom: '0' }} className='bold'>
            {showCPFContractSuspended ?
              <span>
                Suite à un défaut de réalisation de vos conditions CPF, les cours pris en charge par votre compte CPF ne sont plus accessibles.
              </span>
              :
              <>
                <span>
              Suite à un défaut de paiement, votre accès au CEF Learning est restreint aux cours pris en charge par votre compte CPF.
            Merci de bien vouloir régulariser cette situation en procédant au paiement en cliquant{' '}
                </span>
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() =>
                    history.push(
                      `/${fr.route.account}/${fr.route.profile_payment}`
                    )
                  }
                >
              ICI
                </span>
                .
              </>
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default AlertbannerLatePayment;