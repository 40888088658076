import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Action from '../../atoms/Action';
import { sendTimesheet } from '../../../stores/me';
import queryString from 'query-string';

const Timesheetbanner = () => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  let coach =
    queryString.parse(window?.location?.search)['_coaching'] ||
    localStorage.getItem('coach');
  const transformDate = (date) => {
    const dateObj = (new Date(date));
    return dateObj.toLocaleString('fr-FR', {
      hour12: false,
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const now = new Date();

  return now.getDay() !== 6 && now.getDay() !== 7 && me?.selectedOffer?.timeSheet && (
    <div className='alertbanner bg-teal-dark pgv-0_75'>
      <div className="row">
        <div className='cef-text-white cef-flex cef-justify-left cef-w-full cef-font-bold'>
          <span>
            {me?.selectedOffer?.timeSheet?.connected
              ? `Bonjour, vous êtes présent depuis le ${ transformDate(me?.selectedOffer?.timeSheet?.connectedDate) }`
              : 'Bonjour, vous n\'êtes pas connecté.'
            }
          </span>
        </div>
        <div className='cef-text-white cef-flex cef-justify-left cef-w-full cef-font-bold'>
          <Action
            className='flex-auto align-start-stretch cef-w-fit'
            onClick={()=>dispatch(sendTimesheet())}
            state='Primary'
            variant='Solid'
            size='md'
            disabled={coach}
          >
            {me?.selectedOffer?.timeSheet?.connected ? 'Enregistrer ma sortie' : 'Enregistrer ma présence' }
          </Action>
        </div>
      </div>
    </div>
  );
};

export default Timesheetbanner;

