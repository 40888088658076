import axios from 'axios';

import store from '../store';
import { forced_logout, maintenanceMode, isErrorAlert, resetErrorAlert } from '@/stores/auth';
import env from '../env';

let authorizationInterceptor = undefined;
let notAuthorizedInterceptor = undefined;
export let params = {};

let config = {
  baseURL: env.apiUrl,
  headers: {
    Accept: 'application/json',
  },
};

let Api = axios.create(config);

export const addAutorization = (token) => {
  authorizationInterceptor = Api.interceptors.request.use((config) => {
    config.headers.Authorization = 'Bearer ' + token;
    return config;
  });
  notAuthorizedInterceptor = Api.interceptors.response.use(
    (r) => r,
    (err) => {
      if (
        err?.response?.status === 401 &&
        window?.location?.pathname !== '/login'
      ) {
        forced_logout(store.dispatch);
      }
      return Promise.reject(err);
    }
  );
};

Api.interceptors.response.use(
  (response) => {
    resetErrorAlert(store.dispatch);
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      forced_logout(store.dispatch);
    }
    if(error?.response?.status === 500 || typeof error?.response === 'undefined'){
      isErrorAlert(store.dispatch);
    }
    if (error?.response?.status === 503) {
      maintenanceMode(store.dispatch);
    }
    return error?.response;
  }
);

export const addCoaching = (coach) => {
  localStorage.setItem('coach', coach);
  params['_coaching'] = coach;
};

export const removeAuthorization = () => {
  Api.interceptors.request.eject(authorizationInterceptor);
  Api.interceptors.response.eject(notAuthorizedInterceptor);
};

export default Api;
