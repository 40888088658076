import React from 'react';

/**
 * Action
 */

const Action = ({
  className,
  children,
  onClick,
  href,
  target,
  state = 'Primary',
  variant = 'Borderless',
  size = 'none',
  rounded = false,
  hover = true,
  disabled = false,
}) => {

  const variantAction = {
    'Borderless': {
      'Primary': `cef-text-primary ${hover ? 'hover:cef-text-primary/50' : ''}`,
      'Secondary': `cef-text-secondary ${hover ? 'hover:cef-text-secondary/50' : ''}`,
      'Neutral': `cef-text-neutral6 ${hover ? 'hover:cef-text-neutral6/50' : ''}`,
      'White': 'cef-bg-white',
    },
    'Solid': {
      'Primary': `cef-text-white cef-bg-primary ${hover ? 'hover:cef-bg-primary/50' : 'hover:cef-text-white'}`,
      'Secondary': `cef-text-white cef-bg-secondary ${hover ? 'hover:cef-bg-secondary/50' : 'hover:cef-text-white'}`,
      'Neutral': `cef-text-white cef-bg-neutral6 ${hover ? 'hover:cef-bg-neutral6/50' : 'hover:cef-text-white'}`,
      'White': 'cef-bg-white',
    },
    'Outlined': {
      'Primary': `cef-outline cef-outline-1 cef-outline-primary cef-text-primary ${hover ? 'hover:cef-outline-primary/50 hover:cef-text-primary/50' : ''}`,
      'Secondary': `cef-outline cef-outline-1 cef-outline-secondary cef-text-secondary ${hover ? 'hover:cef-outline-secondary/50 hover:cef-text-secondary/50' : ''}`,
      'Neutral': `cef-outline cef-outline-1 cef-outline-neutral6 cef-text-neutral6 ${hover ? 'hover:cef-outline-neutral6/50 hover:cef-text-neutral6/50' : 'hover:cef-text-neutral6'}`,
      'White': 'cef-outline cef-outline-1 cef-outline-white cef-text-white',
    }
  };

  const sizeAction = {
    'none': 'cef-p-0',
    'sm': 'cef-py-2',
    'md': 'cef-py-4',
    'lg': 'cef-py-6',
  };

  return (
    <a
      className={
        `${className} 
        ${disabled ? 'cef-opacity-50 cef-cursor-not-allowed' : ''} 
        ${variantAction[variant][state]} cef-max-w-max 
        ${rounded ? 'cef-rounded-full' : 'cef-rounded-xl'} 
        ${sizeAction[size]} cef-px-4`
      }
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      href={href}
      target={target}
    >
      { children && children }
    </a>
  );
};

export default Action;