import React from 'react';
import PropTypes from 'prop-types';

const TopBanner = ({ displayedSection }) => (
  <div
    className={ `onboarding__topbanner bg-primary flex layout-column align-center-center pgv-1 ${displayedSection > 0 ? 'active' : ''}` }
  >
    <h5 className="no-margin mgb-0_5 text-center fz-20 lh-1_425 fw-700 text-white">Initialisez votre compte</h5>
    <div className="flex align-center-center">
      <div
        className={ `onboarding__bullet mgh-0_5 ${displayedSection >= 1 ? 'active' : ''}` }
      ></div>
      <div
        className={ `onboarding__bullet mgh-0_5 ${displayedSection >= 2 ? 'active' : ''}` }
      ></div>
      <div
        className={ `onboarding__bullet mgh-0_5 ${displayedSection >= 3 ? 'active' : ''}` }
      ></div>
    </div>
  </div>
);

TopBanner.propTypes = {
  displayedSection: PropTypes.number
};

export default TopBanner;
