
import { compose, withState, withHandlers, lifecycle, withProps } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { logout } from '@/stores/auth';
import { changeOffer } from '@/stores/users';
import { check } from '@/stores/me';

import Menu from './Menu';

const enhance = compose(
  connect(
    null,
    dispatch => bindActionCreators({
      logout,
      changeOffer,
      check
    }, dispatch)
  ),
  withState('shouldRedirect', 'updateShouldRedirect', false),
  withHandlers((props) => {
    let openOfferPopin;
    let selectedOffer = undefined;

    let setOffer = offer => selectedOffer = offer;

    return {
      getOffer: () => () => selectedOffer,
      onOfferPopinRef: () => (r) => ( openOfferPopin = r ),
      onOpenOfferPopin: () => () => openOfferPopin(),
      submitOffer: ({ changeOffer, check, updateShouldRedirect }) => e => {
        e.preventDefault();
        setOffer(e.target.querySelector('select[name="new-offer"]').value);
        changeOffer({
          domainOfferId: e.target.querySelector('select[name="new-offer"]').value
        }).then(() => {
          check();
          updateShouldRedirect(r => true);
        });
      }
    };
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      this.props.shouldRedirect &&
        this.props.updateShouldRedirect(r => false);
    }
  }),
  withProps(props => ({
    selectedOffer: props.getOffer()
  }))
);

export default enhance(Menu);
