import React from 'react';
import fr from '@/lang/fr.js';

const AlertbannerPreregistrationNotPecCpf = () => {

  const getYear = () => {
    let date = new Date();
    let year = date.getUTCFullYear();
    return year + 1;
  };

  return (
    <>
      <div className="alertbanner pgv-0_75 bg-turquoise">
        <div className="row">
          <div className="column-12 fz-13 fw-600 text-white text-center mgb-0">
            <div className="w-3 h-3 flex align-center-center mgb-0_5">
              <svg className="text-primary">
                <use xlinkHref="#icon-school" />
              </svg>
            </div>
            <p className="no-margin bold">
              {fr.banners.bannerPreInscriptionNotPecCpf.first}{getYear()}{fr.banners.bannerPreInscriptionNotPecCpf.second}{' '}
              <a
                className="link underline text-white"
                style={{ cursor: 'pointer' }}
                target="_blank"
                href={fr.banners.bannerPreInscriptionNotPecCpf.link} rel="noreferrer"
              >
                {fr.molecules.more}
              </a>{' '}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertbannerPreregistrationNotPecCpf;
