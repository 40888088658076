import React, {Suspense, lazy, useEffect, useState} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Header from '@components/molecules/header/Header';
import {connect} from 'react-redux';
import PrivateRoute from './PrivateRoute.js';
import PropTypes from 'prop-types';
import fr from '@/lang/fr.js';
import OnboardingPositioningTest from '@components/molecules/OnboardPositionnement';
import AlertbannerLateHomework from '@components/molecules/AlertbannerLateHomework';
import AlertbannerPreregistrationForm from '@components/molecules/AlertbannerPreregistrationForm';
import AlertbannerPreregistrationNotPecCpf from '@components/molecules/AlertbannerPreregistrationNotPecCpf';
import Onboarding from '@components/molecules/Onboarding/Onboarding';
import PositionnementBanner from '@components/molecules/PositionnementBanner';
import AlertModalReminder from '@/components/molecules/AlertModalReminder';
import AlertbannerLatePayment from '@/components/molecules/AlertbannerLatePayment';
import SatisfactionSurvey from '@/components/molecules/satisfactionSurvey/satisfactionSurvey';
import ScrollToTop from './ScrollToTop';
import queryString from 'query-string';

const AsyncLogin = lazy(() => import('@/pages/login'));
const AsyncPdfViewer = lazy(() => import('@/pages/internships/internshipPdfViewer/InternshipPdfViewer.js'));
const AsyncNoMatch = lazy(() => import('@/pages/404'));
const AsyncHome = lazy(() => import('@/pages/home'));
const AsyncHomeV2 = lazy(() => import('@/V2/pages/home'));
const AsyncCourses = lazy(() => import('@/pages/courses'));
const AsyncLearningIndex = lazy(() => import('@/pages/learning/index'));
const AsyncLearningShow = lazy(() => import('@/pages/learning/show'));
const AsyncHomeworks = lazy(() => import('@/pages/homeworks'));
const AsyncQuestionsGroups = lazy(() => import('@/pages/questionsGroups'));
const AsyncProfile = lazy(() => import('@/pages/profile'));
const AsyncAccount = lazy(() => import('@/pages/account'));
const AsyncVideos = lazy(() => import('@/pages/videos'));
const AsyncAgenda = lazy(() => import('@/pages/agenda'));
const AsyncAgendaV2 = lazy(() => import('@/V2/pages/agenda'));
const AsyncVideoconferences = lazy(() => import('@/pages/rendezvous/MesRendezVousView'));
const AsyncFaq = lazy(() => import('@/pages/faq'));
const AsyncInternships = lazy(() => import('@/pages/internships/InternShip'));
const AsyncJobOffers = lazy(() => import('@/pages/jobOffers/JobOffers.js'));
const AsyncEvaluationIntershipForm = lazy(() => import('@/pages/internships/Evaluation/EvaluationForm.js'));
const AsyncJobOffersDetails = lazy(() =>
  import('@/pages/jobOffersDetails/JobOffersDetails.js')
);
const AsyncLogoutVisio = lazy(() => import('@/pages/logoutVisio/logoutVisio'));
const AsyncRanking = lazy(() => import('@/pages/ranking'));
const AsyncChat = lazy(() => import('@/pages/chat/Chat'));
const AsyncPositioning = lazy(() => import('@/pages/positioning/positioning'));
const AsyncSchoolReport = lazy(() => import('@/pages/schoolReport/index'));
const AsyncHomeworkPresentation = lazy(() =>
  import('@/pages/HomeworkPresentation/index')
);
const AsyncQuizzPresentation = lazy(() =>
  import('@/pages/learning/quizzPresentation/index')
);
const AsyncHomeworkFreeQuestion = lazy(() =>
  import('@/pages/HomeworkFreeQuestion/index')
);
const AsyncRessources = lazy(() => import('@/pages/ressources'));
const AsyncPlaylist = lazy(() => import('@/pages/playlist'));
const AsyncSuspendedAccount = lazy(() => import('@/pages/suspendedAccount'));
const AsyncStyleguide = lazy(() => import('@/pages/styleguide'));
const AsyncReport = lazy(() => import('@/pages/report'));
const AsyncReportV2 = lazy(() => import('@/V2/pages/report'));
const AsyncBadges = lazy(() => import('@/pages/badges'));
const AsyncChallenges = lazy(() => import('@/pages/challenges'));
const AsyncPaymentSuccess = lazy(() => import('@/pages/payment/success'));
const AsyncPaymentFailure = lazy(() => import('@/pages/payment/failure'));
const AsyncMaintenance = lazy(() => import('@/pages/Maintenance/Maintenance'));
const AsyncPreRegistrationExamination = lazy(() => import('@/pages/preRegistrationExamination'));
const AsyncShippings = lazy(() => import('@/pages/shippings'));
const AsyncCoursesV2 = lazy(()=>import('@/V2/pages/learning/learning'));
const AsyncPreviewFromBo2 = lazy(()=>import('@/V2/pages/learning/body/content/Lessons'));

const query = queryString.parse(window?.location?.search);
const isInContentPreviewMode = query?.id && query?.ContentType;

const Router = ({isAuthenticated, maintenance, state, updateState, pathname, me}) => {

  useEffect(() => {
    (me && (me?.remindersLevel === 4 || me?.remindersLevel > 4)) && updateState({...state, displayModalRelaunch: true});
  }, [me]);

  const [satisfactionSurveyState, closeSatisfactionSurvey] = useState(true);

  return (
    <BrowserRouter>
      <ScrollToTop/>
      {isAuthenticated && state.OpenBanner && (
        <PositionnementBanner/>
      )}
      {isAuthenticated && !maintenance && <Header/>}
      {me?.selectedOffer && me?.selectedOffer?.isFirstConnection === true && (
        <Onboarding/>
      )}
      {me.selectedOffer && me.selectedOffer.isFirstConnection === false && me.selectedOffer.isActive === true && !isInContentPreviewMode && <OnboardingPositioningTest
        OpenBanner={state.OpenBanner}
        isAuthenticated={isAuthenticated}
        SetOpenBanner={updateState}
      />}
      {isAuthenticated && !maintenance && me.lateHomeworks && me.lateHomeworks?.numberLateHomeworks && !isInContentPreviewMode &&
        <AlertbannerLateHomework
          numberLateHomeworks={me.lateHomeworks.numberLateHomeworks}
          dateLateHomworks={me.lateHomeworks.dateLateHomework} idLateCours={me.lateHomeworks.idLateCourse}
          idLateHomework={me.lateHomeworks.idLateHomework}
          isContentBlock = {me.selectedOffer?.contentTag === 2}
        />}
      {isAuthenticated && !maintenance && pathname !== '/'+fr.route.preregistration_exam && me.selectedOffer?.alertPreRegistrationExam && <AlertbannerPreregistrationForm guidePreRegistrationExam={me.selectedOffer.guidePreRegistrationExam} />}
      {isAuthenticated && !maintenance && me.selectedOffer?.isPreRegistrationNoCpfOrPec && <AlertbannerPreregistrationNotPecCpf />}
      {isAuthenticated && !maintenance && me.selectedOffer?.alertLatePayment && <AlertbannerLatePayment />}

      {isAuthenticated && !maintenance && pathname !== '/login' && state.displayModalRelaunch &&
        <AlertModalReminder state={state} updateState={updateState} lateHomeworks={me.lateHomeworks}
          remindersLevel={me.remindersLevel} firstName={me.firstName}/>}
      {isAuthenticated && !maintenance && pathname !== '/login' && me?.selectedOffer?.satisfactionSurvey?.idSurvey && satisfactionSurveyState &&
        <SatisfactionSurvey closeSatisfactionSurvey={closeSatisfactionSurvey} surveyId={me?.selectedOffer?.satisfactionSurvey?.idSurvey}/>
      }
      <Suspense fallback={null}>
        <Switch>
          {isAuthenticated && !maintenance && me.selectedOffer?.contentTag === 1 ?
            <PrivateRoute exact path="/" component={AsyncHome}/>
            :
            <PrivateRoute exact path="/" component={AsyncHomeV2}/>
          }
          {isAuthenticated && !maintenance && me.selectedOffer?.contentTag === 1 ?
            <PrivateRoute
              exact
              path={`/${fr.route.courses}`}
              component={AsyncCourses}
            />
            :
            <PrivateRoute
              exact
              path={`/${fr.route.courses}`}
              component={AsyncCoursesV2}
            />
          }
          <PrivateRoute
            exact
            path={'/contentPreview'}
            component={AsyncPreviewFromBo2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId`}
            component={AsyncLearningIndex}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId/${fr.route.chapter}/:chapterId`}
            component={AsyncLearningShow}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId/${fr.route.chapter}/:chapterId/${fr.route.videos}`}
            component={AsyncVideos}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId/${fr.route.chapter}/:chapterId/${fr.route.subchapter}/:subchapterId/${fr.route.question}`}
            component={AsyncQuestionsGroups}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.evaluate}/:courseId`}
            component={AsyncHomeworks}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.evaluate}/:courseId/${fr.route.homework}/:homeworkId`}
            component={AsyncQuestionsGroups}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.evaluate}/:courseId/${fr.route.homeworkCorrection}/:homeworkCorrectionId`}
            component={AsyncQuestionsGroups}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.revise}/:courseId`}
            component={AsyncQuestionsGroups}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.challenge}/:challengeId`}
            component={AsyncQuestionsGroups}
          />
          <Route
            exact
            path={'/questionPreview'}
            component={AsyncQuestionsGroups}
          />

          <PrivateRoute
            exact
            path={`/${fr.route.suspendedAccount}`}
            component={AsyncSuspendedAccount}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.position}`}
            component={AsyncPositioning}
          />
          {isAuthenticated && !maintenance && me.selectedOffer?.contentTag === 1 ?
            <PrivateRoute
              path={`/${fr.route.agenda}`}
              component={AsyncAgenda}
            /> :
            <PrivateRoute
              path={`/${fr.route.agenda}`}
              component={AsyncAgendaV2}
            />
          }
          <PrivateRoute
            path={`/${fr.route.videoconferences}`}
            component={AsyncVideoconferences}
          />
          <PrivateRoute
            path={`/${fr.route.mentoring}`}
            component={AsyncVideoconferences}
          />
          <PrivateRoute
            path={`/${fr.route.profile}`}
            component={AsyncProfile}
          />
          <PrivateRoute
            path={`/${fr.route.account}`}
            component={AsyncAccount}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.internships}`}
            component={AsyncInternships}
          />
          <PrivateRoute
            path={`/${fr.route.internships}/:conventionId`}
            component={AsyncInternships}
          />
          <PrivateRoute
            path={`/${fr.route.evaluationForm}/:conventionId/:evaluationId`}
            component={AsyncEvaluationIntershipForm}
          />
          <PrivateRoute
            path={`/${fr.route.jobOffers}`}
            component={AsyncJobOffers}
            exact
          />
          <PrivateRoute
            path={`/${fr.route.jobOffers}/:id`}
            component={AsyncJobOffersDetails}
          />
          <PrivateRoute
            path={`/${fr.route.ranking}`}
            component={AsyncRanking}
          />
          {isAuthenticated && !maintenance && me.selectedOffer?.contentTag === 1 ?
            <PrivateRoute
              path={`/${fr.route.report}`}
              component={AsyncReport}
            />
            :
            <PrivateRoute
              path={`/${fr.route.report}`}
              component={AsyncReportV2}
            />
          }
          <PrivateRoute
            path={`/${fr.route.badges}`}
            component={AsyncBadges}
          />
          <PrivateRoute
            path={`/${fr.route.challenges}`}
            component={AsyncChallenges}
          />
          <PrivateRoute
            path={`/${fr.route.chat}/:section?/:idValue?`}
            component={AsyncChat}
          />
          <PrivateRoute
            path={`/${fr.route.schoolReport}`}
            component={AsyncSchoolReport}
          />
          <PrivateRoute
            path={`/${fr.route.evaluate}/:courseId/${fr.route.homework}/:homeworkId/${fr.route.presentation}`}
            component={AsyncHomeworkPresentation}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId/${fr.route.chapter}/:chapterId/${fr.route.subchapter}/:subchapterId/${fr.route.presentation}`}
            component={AsyncQuizzPresentation}
          />
          <PrivateRoute
            path={`/${fr.route.evaluate}/:courseId/${
              fr.route.homework
            }/:homeworkId/${'editor'}`}
            component={AsyncHomeworkFreeQuestion}
          />
          <Route
            path={`/${fr.route.payment_success}`}
            component={AsyncPaymentSuccess}
          />
          <Route
            path={`/${fr.route.payment_failure}`}
            component={AsyncPaymentFailure}
          />

          <PrivateRoute path={`/${fr.route.faq}`} component={AsyncFaq} />
          <PrivateRoute
            path={`/${fr.route.ressources}`}
            component={AsyncRessources}
          />
          <PrivateRoute
            path={`/${fr.route.learning_ressources}`}
            component={AsyncRessources}
          />
          <PrivateRoute
            path={`/${fr.route.playlist}`}
            component={AsyncPlaylist}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.profile_shippings}`}
            component={AsyncShippings}
          />
          <PrivateRoute
            exact
            path='/styleguide'
            component={AsyncStyleguide}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.preregistration_exam}`}
            component={AsyncPreRegistrationExamination}
          />
          {/*---------------------------ROUTES V2---------------------------*/}
          <PrivateRoute
            path={`/${fr.route.homeworkV2}/:homeworkId/${fr.route.presentation}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.homeworkV2}/:homeworkId`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            path={`/${fr.route.homeworkV2}/:homeworkId/${fr.route.doingHomework}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            path={`/${fr.route.homeworkV2}/:homeworkCorrectionId/${fr.route.homeworkCorrection}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.quiz}/:quizzId/${fr.route.presentation}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.quiz}/:quizzId/${fr.route.question}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.quiz}/:quizzId`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.questionV2}/:questionId`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.reviseV2}/:reviseId`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.challengeV2}/:challengeId`}
            component={AsyncCoursesV2}
          />
          <Route path="/maintenance" component={AsyncMaintenance}/>
          <Route path="/logoutvisio" component={AsyncLogoutVisio}/>
          <Route path="/login" component={AsyncLogin}/>
          <Route path="/visionneusePdf/:type/:id/:hash" component={AsyncPdfViewer}/>
          <Route component={AsyncNoMatch}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

Router.propTypes = {
  isAuthenticated: PropTypes.bool,
  maintenance: PropTypes.bool,
  state: PropTypes.object,
  updateState: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    me: state.me,
    isAuthenticated: state.auth?.isAuthenticated,
    maintenance: state.auth?.maintenance,
  };
};
export default connect(mapStateToProps)(Router);
