import React from 'react';
import PropTypes from 'prop-types';
import EnhancedLink from '@components/atoms/enhancedLink';

const Item = ({ text, path, onItemClick }) => (
  <span onClick={onItemClick} className='flex'>
    <EnhancedLink
      className='menu--item'
      //activeClassName='text-primary'
      isNavLink={true}
      to={path}
    >
      {text}
    </EnhancedLink>
  </span>
);

Item.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
  onItemClick: PropTypes.func,
};

export default Item;
