import React from 'react';
import { useHistory } from 'react-router-dom';
import fr from '@/lang/fr.js';
import ReactDOM from 'react-dom';
import Modal from '@components/atoms/modal/Modal';
import PropTypes from 'prop-types';

const AlertModalReminder = ({state, updateState, lateHomeworks, firstName, remindersLevel }) => {

  const history = useHistory();

  const goToReport = () => {
    updateState({...state, displayModalRelaunch: false});
    history.push(`/${fr.route.report}`);
  };

  const goToHomework = () => {
    updateState({...state, displayModalRelaunch: false});
    lateHomeworks && history.push(`/${fr.route.evaluate}/${lateHomeworks.idLateCourse}/${fr.route.homework}/${lateHomeworks.idLateHomework}/${fr.route.presentation}`);
  };

  return (
    <>
      {
        ReactDOM.createPortal(<Modal ShowOrNot={state.displayModalRelaunch} setShowPopin={() => {}}>
          <div className="flex layout-column align-center-center pg-1" style={{ paddingBottom: '0px' }}>
            <div className="w-7 h-7 round bg-alert text-white flex align-center-center mgb-1">
              <svg className="w-4 h-4"><use xlinkHref="#icon-warning" /></svg>
            </div>
            <h2 className="no-margin mgb-1 no-shadow">Bonjour {firstName}</h2>
            <div className="bd-1-gray-light bd-radius size-100p flex layout-column align-center-center pg-0_5">
              <p className="mgt-0_5 text-justify" style={{ marginBottom: '2%' }}>Cela fait maintenant {(remindersLevel > 4) ? '4' : '3'} mois que vous n'avez pas réalisé de devoirs. Il est encore temps de rattraper votre retard. Noubliez pas votre objectif ! Votre coach et l'ensemble des équipes du centre européen de formation restent à vos côtés pour vous accompagner dans votre réussite !</p>
            </div>
            <button className="button primary mgt-1" 
              onClick={ () => {
                (remindersLevel > 4) ? goToHomework() : goToReport();
              }}>
              {(remindersLevel > 4) ? 'Reprendre ma formation' : 'Accéder à mon bulletin'}
            </button>
          </div>
        </Modal>,
        document.body
        )}
    </>
  );
};

AlertModalReminder.propTypes = {
  state: PropTypes.object,
  lateHomeworks: PropTypes.any,
  firstName: PropTypes.string,
  updateState: PropTypes.func,
  remindersLevel: PropTypes.any

};

export default AlertModalReminder;

