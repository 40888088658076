/* eslint-disable no-mixed-spaces-and-tabs */
import Api, { params } from '../modules/Api.js';
import env from '@/env.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

import { SET_SELECTED_COURSE, RESET_SELECTED_COURSE } from './selected';

export const GET_HOMEWORKS_REQUESTED = 'homeworks/GET_HOMEWORKS_REQUESTED';
export const GET_HOMEWORKS_SUCCESSFUL = 'homeworks/GET_HOMEWORKS_SUCCESSFUL';
export const GET_HOMEWORKS_FAILED = 'homeworks/GET_HOMEWORKS_FAILED';

export const POST_HOMEWORK_REQUESTED = 'homeworks/POST_HOMEWORK_REQUESTED';
export const POST_HOMEWORK_SUCCESSFUL = 'homeworks/POST_HOMEWORK_SUCCESSFUL';
export const POST_HOMEWORK_FAILED = 'homeworks/POST_HOMEWORK_FAILED';

export const RESET_SENDING = 'homeworks/RESET_SENDING';

// const initialState = [];

const initialState = {
  homeworks: [],
  homeworkIsSent: undefined,
};

export default function Homeworks(state = initialState, action) {
  switch (action.type) {
    case GET_HOMEWORKS_REQUESTED:
      return {
        ...state,
      };
    case GET_HOMEWORKS_SUCCESSFUL:
      return {
        ...state,
        homeworks: action.list,
        waitingForSend: action.waitingForSend,
      };
    case GET_HOMEWORKS_FAILED:
      return {
        ...state,
      };

    case POST_HOMEWORK_SUCCESSFUL:
      return {
        ...state,
        homeworkIsSent: action.homeworkIsSent,
      };
    case POST_HOMEWORK_FAILED:
      return {
        ...state,
        homeworkIsSent: action.homeworkIsSent,
      };
    case RESET_SENDING:
      return {
        ...state,
        homeworkIsSent: undefined,
      };
    default:
      return state;
  }
}

export const resetSending = () => (dispatch) => {
  dispatch({ type: RESET_SENDING });
};

export const getHomeworks = (courseId,contentTag) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESET_SELECTED_COURSE });
  dispatch({ type: GET_HOMEWORKS_REQUESTED });

  let url = contentTag === 2 ? `/contents/homework/${courseId}`:
    `/courses/${courseId}/homeworks`;

  return Api(url, { params: params }).then((res) => {
    let { homeworks: list, course: selectedCourse } = res.data;

    list = list.map((h) => {
      return h.corrections.length > 0
        ? {
          ...h,
          scoringAvg: {
            dividend:
                  h.corrections.filter((c) => c.scoring.dividend !== null)
                    .length > 0
                    ? h.corrections.reduce(
                      (t, c) => t + c.scoring.dividend,
                      0
                    ) / h.corrections.length
                    : null,
            divisor: h.corrections[0].scoring.divisor,
          },
        }
        : h;
    });

    dispatch({
      type: GET_HOMEWORKS_SUCCESSFUL,
      list,
      waitingForSend: false,
    });
    dispatch({
      type: SET_SELECTED_COURSE,
      selectedCourse,
    });
    dispatch({ type: ISNT_LOADING });
  })
    .catch((err) => {
      dispatch({ type: GET_HOMEWORKS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const postHomework = (homeworkId, files) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: POST_HOMEWORK_REQUESTED });

  let formData = new FormData();
  //formData.append('homework', file);

  files
    .filter((file) => !!file)
    .forEach((file, index) => {
      formData.append('homework[' + index + ']', file);
    });

  return Api.post(`/homeworks/${homeworkId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: params,
  })
    .then(() => {
      dispatch({
        type: POST_HOMEWORK_SUCCESSFUL,
        homeworkIsSent: true,
      });
      setTimeout(() => {
        dispatch({ type: RESET_SENDING });
      }, env.homework.sentMessageDuration);
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: POST_HOMEWORK_FAILED,
        homeworkIsSent: false,
      });

      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
