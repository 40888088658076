import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import fr from '@/lang/fr.js';
import env from '@/env';
import ReactDOM from 'react-dom';
import Modal from '@components/atoms/modal/Modal';
import EnhancedLink from '@components/atoms/enhancedLink';

const AlertbannerPreregistrationForm = (guidePreRegistrationExam) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const getYear = () => {
    let date = new Date();
    let year = date.getUTCFullYear();
    return year + 1;
  };

  return (
    <>
      <div className="alertbanner pgv-0_75 bg-turquoise">
        <div className="row">
          <div className="column-12 fz-13 fw-600 text-white text-center mgb-0">
            <div className="w-3 h-3 flex align-center-center mgb-0_5">
              <svg className="text-primary">
                <use xlinkHref="#icon-school" />
              </svg>
            </div>
            <p className="no-margin bold">
              {fr.banners.bannerPecInscriptionForm.first} {getYear()}{fr.banners.bannerPecInscriptionForm.second}{' '}
              <EnhancedLink
                className='cef-text-white'
                activeClassName='text-primary-dark'
                isNavLink={true}
                to={`/${fr.route.profile}/${fr.route.profile_examinations}`}
                exact
              >
                {fr.molecules.here.toUpperCase()}
              </EnhancedLink>
              {' '}
            </p>
          </div>
        </div>
      </div>
      {openModal &&
        ReactDOM.createPortal(
          <Modal ShowOrNot={openModal} setShowPopin={() => setOpenModal(false)}>
            <div
              className="flex layout-column align-center-center pg-1"
              style={{ paddingBottom: '0px' }}
            >
              <div className="w-7 h-7 flex align-center-center mgb-1">
                <svg className="text-primary">
                  <use xlinkHref="#icon-school" />
                </svg>
              </div>
              <div>
                <p className="mgt-0_5 text-justify">
                  <h3 className="cef-text-center">{fr.examinations.popin.preRegistrationText.first}</h3><br />
                  {fr.examinations.popin.preRegistrationText.second}<br /><br />
                  {fr.examinations.popin.preRegistrationText.third}
                  <a
                    href={guidePreRegistrationExam}
                    className="link underline"
                    style={{ cursor: 'pointer' }}
                    target="_blank" rel="noreferrer"
                  >
                    {fr.molecules.here.toUpperCase()}
                  </a>{' '}<br /><br />
                  <strong><u>{fr.examinations.popin.preRegistrationText.fourth}</u></strong><br /><br />
                  {fr.examinations.popin.preRegistrationText.fifth}<br /><br />
                  {fr.examinations.popin.preRegistrationText.sixth}<br /><br />
                  {fr.examinations.popin.preRegistrationText.seventh}
                </p>
              </div>
              <button
                className="button primary mgt-1"
                onClick={() => {
                  history.push(`/${fr.route.preregistration_exam}`, {
                    from: history.location,
                  });
                  setOpenModal(false);
                }}
              >
                {fr.examinations.popin.preRegistrationText.button}
              </button>
            </div>
          </Modal>,
          document.body
        )}
    </>
  );
};

export default AlertbannerPreregistrationForm;
