import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_RANKING_REQUESTED = 'ranking/GET_RANKING_REQUESTED';
export const GET_RANKING_SUCCESSFUL = 'ranking/GET_RANKING_SUCCESSFUL';
export const GET_RANKING_FAILED = 'ranking/GET_RANKING_FAILED';

const initialState = {};

export default function Ranking(state = initialState, action) {
  switch (action.type) {
    case GET_RANKING_REQUESTED:
      return initialState;
    case GET_RANKING_SUCCESSFUL:
      return action.ranking;
    case GET_RANKING_FAILED:
      return initialState;
    default:
      return state;
  }
}

export const getRanking = () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_RANKING_REQUESTED });

  return Api('/rank', { params: params })
    .then((res) => {
      dispatch({
        type: GET_RANKING_SUCCESSFUL,
        ranking: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_RANKING_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
