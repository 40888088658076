import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_BADGES_REQUESTED = 'badges/GET_BADGES_REQUESTED';
export const GET_BADGES_SUCCESSFUL = 'badges/GET_BADGES_SUCCESSFUL';
export const GET_BADGES_FAILED = 'badges/GET_BADGES_FAILED';

const initialState = [];

export default function Badges(state = initialState, action) {
  switch (action.type) {
    case GET_BADGES_REQUESTED:
      return initialState;
    case GET_BADGES_SUCCESSFUL:
      return action.data;
    case GET_BADGES_FAILED:
      return initialState;
    default:
      return state;
  }
}

export const getBadges = () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_BADGES_REQUESTED });

  return Api('/badges', { params: params })
    .then((res) => {
      dispatch({
        type: GET_BADGES_SUCCESSFUL,
        data: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_BADGES_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
