import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import fr from '@/lang/fr';
import EnhancedLink from '@components/atoms/enhancedLink';
import { addToGTM } from '@components/molecules/gtm';
import { getMe } from '@/stores/users';
import env from '@/env';
import { connect } from 'react-redux';
import '../Onboarding/index.css';

const OnboardingPositioningTest = (props) => {
  const {
    SetOpenBanner,
    check,
    positioningState,
    OpenBanner,
    me,
    isAuthenticated,
  } = props;

  // # Already visited positionning test page state
  const [alreadyVisited, SetAlreadyVisited] = useState(false);
  // # Counter for content view
  const [counter, setCounter] = useState(0);
  // # Open state for pop-up
  const [open, setOpen] = useState(false);
  // # Confirm pop-up is closed
  const [isClosed, setIsClose] = useState(false);

  //  # Set counter state
  useEffect(() => {
    if (me?.selectedOffer?.connexionAmount) {
      setCounter(me?.selectedOffer?.connexionAmount);
    }
  });

  // # Use / Remove localStorage
  useEffect(() => {
    if (isClosed) {
      localStorage.setItem('popinAlreadyOpen', true);
    }
    if (positioningState === !true) {
      localStorage.removeItem('popinAlreadyOpen');
      localStorage.removeItem('BannerOpen');
      setOpen(false);
    }
  });

  // # Remove banner if tests is OK
  useEffect(() => {
    if (
      (me?.selectedOffer?.firstPositioningTestStatus === 2 &&
        me?.selectedOffer?.lastPositioningTestStatus === -1) ||
      me?.selectedOffer?.lastPositioningTestStatus === 2
    ) {
      localStorage.removeItem('BannerOpen');
      localStorage.removeItem('alreadyVisited');
      SetOpenBanner(prevState => ({...prevState, OpenBanner: false}));
      setOpen(false);
    }
  });

  // # Handle click for open Banner
  const onClickBanner = () => {
    SetOpenBanner(prevState => ({...prevState, OpenBanner: true}));
  };

  useEffect(() => {
    let visited = localStorage.getItem('BannerOpen');
    if (
      OpenBanner === false &&
      isAuthenticated &&
      (me?.selectedOffer?.firstPositioningTestStatus === 1 ||
        me?.selectedOffer?.firstPositioningTestStatus === 0 ||
        me?.selectedOffer?.lastPositioningTestStatus === 1 ||
        me?.selectedOffer?.lastPositioningTestStatus === 0)
    ) {
      setTimeout(() => {
        localStorage.setItem('BannerOpen', true);
      }, 5000);
    }
    if (positioningState === false) {
      SetOpenBanner(prevState => ({...prevState, OpenBanner: false}));
    }
    if (
      visited &&
      (me?.selectedOffer?.firstPositioningTestStatus === 0 ||
        me?.selectedOffer?.firstPositioningTestStatus === 1 ||
        me?.selectedOffer?.lastPositioningTestStatus === 0 ||
        me?.selectedOffer?.lastPositioningTestStatus === 1)
    ) {
      SetOpenBanner(prevState => ({...prevState, OpenBanner: true}));
    }
    if (window?.location?.pathname === '/positionnement') {
      SetOpenBanner(prevState => ({...prevState, OpenBanner: false}));
    }
  });

  // # Use state to store if test is already visited
  useEffect(() => {
    me?.selectedOffer?.firstPositioningTestStatus === 1 ||
    me?.selectedOffer?.lastPositioningTestStatus === 1
      ? SetAlreadyVisited(true)
      : SetAlreadyVisited(false);
  });

  // # handle open state for Popin (WIP REFACTORING)
  const handlePopinTest = () => {
    let visited = localStorage.getItem('popinAlreadyOpen');
    if (
      (me?.selectedOffer?.firstPositioningTestStatus < 2 &&
        me?.selectedOffer?.firstPositioningTestStatus !== -1 &&
        window?.location?.pathname !== '/positionnement' &&
        !isClosed &&
        !visited &&
        isAuthenticated) ||
      (me?.selectedOffer?.lastPositioningTestStatus < 2 &&
        me?.selectedOffer?.lastPositioningTestStatus !== -1 &&
        window?.location?.pathname !== '/positionnement' &&
        !isClosed &&
        !visited &&
        isAuthenticated) ||
      (me?.selectedOffer?.connexionAmount >= env.limitConnexionAmount &&
        (me?.selectedOffer?.firstPositioningTestStatus === 1 ||
          me?.selectedOffer?.firstPositioningTestStatus === 0 ||
          me?.selectedOffer?.lastPositioningTestStatus === 1 ||
          me?.selectedOffer?.lastPositioningTestStatus === 0) &&
        isAuthenticated &&
        window?.location?.pathname !== '/positionnement' &&
        !isClosed) ||
      (me?.selectedOffer?.connexionAmount >= env.limitConnexionAmount &&
        me?.selectedOffer?.firstPositioningTestStatus === 1 &&
        me?.selectedOffer?.lastPositioningTestStatus === (-1 || 0 || 1) &&
        window?.location?.pathname !== '/positionnement' &&
        isAuthenticated)
    )
      setOpen(true);
    else if (isClosed) {
      setOpen(false);
    } else {
      setOpen(false);
    }
    if (
      visited &&
      me?.selectedOffer?.lastPositioningTestStatus === 0 &&
      !isClosed
    ) {
      setOpen(true);
    }
  };

  // useEffect(() => {open ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';});

  // # handle onClose method Popin
  const handleOnClose = () => {
    setOpen(false);
    setIsClose(true);
    addToGTM({
      event: 'onboardingPositionnementValidation',
    });
    check();
  };

  useEffect(() => {
    handlePopinTest();
  });

  return (
    <React.Fragment>
      {open ? (
        <div className='onboarding'>
          {
            <React.Fragment>
              <div
                className={
                  'onboarding__inner pg-1 bg-white shadow bd-radius flex layout-column'
                }
                style={{ position: 'relative' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    position: 'absolute',
                    left: 0,
                  }}
                >
                  {counter < env.limitConnexionAmount ||
                  (alreadyVisited && counter === !env.limitConnexionAmount) ||
                  (counter >= env.limitConnexionAmount &&
                    (me?.selectedOffer?.lastPositioningTestStatus === 1 ||
                      me?.selectedOffer?.lastPositioningTestStatus === 0)) ? (
                      <button
                        type='button'
                        className='close icon pg-0_25'
                        style={{ border: 0, cursor: 'pointer' }}
                        data-dismiss='modal'
                        aria-label='Close'
                        onClick={() => {
                          handleOnClose();
                          onClickBanner();
                        }}
                      >
                        <span>
                          <svg className='w-3 h-3'>
                            <use xlinkHref='#icon-close' />
                          </svg>
                        </span>
                      </button>
                    ) : (
                      ''
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className="w-3 h-3 flex align-center-center mgb-0_5">
                    <svg className="text-primary">
                      <use xlinkHref="#icon-school" />
                    </svg>
                  </div>                </div>
                {me?.selectedOffer &&
                me?.selectedOffer?.lastPositioningTestStatus >= 0 &&
                !alreadyVisited ? (
                    <div>
                      <p>
                      Félicitations {me?.firstName}, votre formation est
                      maintenant terminée.
                      </p>
                      <p>
                      Vous avez réalisé un test de positionnement en début de
                      formation.{' '}
                      </p>
                      <p>
                      Réalisez maintenant ce test de positionnement de fin de
                      formation.{' '}
                      </p>
                      <p>
                      Vous pourrez ainsi mesurer votre progression et vos
                      acquis.{' '}
                      </p>
                      <p>20 questions vous seront posées. </p>
                      <p>Sa durée est de 3 minutes maximum.</p>
                    </div>
                  ) : (
                    ''
                  )}
                {alreadyVisited ? (
                  <p>
                    <p>Vous n’avez pas terminé le test de positionnement. </p>
                    <p>
                      Nous vous rappelons que cette étape est obligatoire pour
                      évaluer votre progression entre le début et la fin de
                      votre formation.
                    </p>
                    <p>
                      Pour valider définitivement cette étape obligatoire,
                      cliquez sur « Terminer ».
                    </p>{' '}
                  </p>
                ) : (
                  ''
                )}
                {counter <= env.limitConnexionAmount - 2 &&
                !alreadyVisited &&
                me?.selectedOffer &&
                me?.selectedOffer?.lastPositioningTestStatus === -1 ? (
                    <div>
                      <p>
                      Avant de démarrer votre formation, merci de réaliser un
                      test pour évaluer vos connaissances dans le domaine de
                      formation que vous avez choisi.
                      </p>
                      <p>
                      Ce test de 20 questions ne nécessite aucun prérequis. Sa
                      durée est d’environ 3 minutes.
                      </p>
                      <p>
                      Cette étape est obligatoire pour mesurer précisément votre
                      progression entre le début et la fin de votre formation.{' '}
                      </p>
                      <p>
                      Un test équivalent vous sera également proposé une fois
                      votre formation terminée.
                      </p>
                    </div>
                  ) : counter === env.limitConnexionAmount - 1 &&
                  !alreadyVisited &&
                  me?.selectedOffer &&
                  me?.selectedOffer?.lastPositioningTestStatus === -1 ? (
                      <div>
                        <p>
                      Vous n’avez pas encore réalisé votre test de
                      positionnement. C’est la dernière fois que vous pouvez
                      passer cette étape obligatoire. Elle vous sera imposée à
                      votre prochaine connexion. N’oubliez pas que ce test ne
                      nécessite aucun prérequis. Sa durée est d’environ 3
                      minutes.
                        </p>
                        <p>
                      Cette étape permettra de mesurer précisément votre
                      progression entre le début et la fin de votre formation.
                      Un test équivalent vous sera également proposé une fois
                      votre formation terminée.
                        </p>
                      </div>
                    ) : counter >= env.limitConnexionAmount &&
                  !alreadyVisited &&
                  me?.selectedOffer &&
                  me?.selectedOffer?.lastPositioningTestStatus === -1 ? (
                        <div>
                          <p>
                      Vous n’avez pas réalisé le test de positionnement
                      permettant d’évaluer vos connaissances dans le domaine de
                      formation que vous avez choisi.{' '}
                          </p>
                          <p>
                      Cette étape est obligatoire pour évaluer votre progression
                      entre le début et la fin de votre formation.
                          </p>
                          <p>
                      Ce test ne nécessite aucun prérequis. Sa durée est
                      d’environ 3 minutes.
                          </p>
                          <p>
                      Un test équivalent vous sera également proposé une fois
                      votre formation terminée.
                          </p>
                          <p>
                      Vous devez valider aujourd’hui cette étape importante pour
                      accéder de nouveau à votre plateforme
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {counter >= 0 && !alreadyVisited ? (
                    <EnhancedLink
                      to={`/${fr.route.position}`}
                      onClick={handleOnClose}
                      className='button primary mgb-0'
                    >
                      Réaliser le test
                    </EnhancedLink>
                  ) : alreadyVisited ? (
                    <EnhancedLink
                      to={`/${fr.route.position}`}
                      onClick={handleOnClose}
                      className='button primary mgb-0'
                    >
                      Terminer le test
                    </EnhancedLink>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </React.Fragment>
          }
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

OnboardingPositioningTest.propTypes = {
  getMe: PropTypes.func,
  check: PropTypes.func,
  SetOpenBanner: PropTypes.func,
  me: PropTypes.object,
  // pathname: PropTypes.string,
  goToNextSection: PropTypes.func,
  closeOnboarding: PropTypes.func,
  positioningState: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  displayedSection: PropTypes.number,
  OpenBanner: PropTypes.any,
};


export default connect(null, {getMe})(OnboardingPositioningTest);
