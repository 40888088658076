import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import fr from '@/lang/fr';
import './index.css';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router';
import { removeToast } from '@/stores/toasts';

const Toast = ({
  type,
  link,
  message,
  category,
  id,
  removeToast,
  clickOnToast,
}) => {
  const closeToast = () => {
    removeToast(id, type);
  };

  const [icon, setIcon] = useState(null);
  const [color, setColor] = useState(null);

  useEffect(() => {
    let newProps = (function (c) {
      switch (c) {
        case 2:
          return {
            icon: 'shield',
            color: 'primary',
          };
        case 3:
          return {
            icon: 'lightning',
            color: 'purple',
          };
        case 4:
          return {
            icon: 'podium',
            color: 'teal',
          };
        case 5:
          return {
            icon: 'folder',
            color: 'purple',
          };
        case 6:
          return {
            icon: 'clock-detailed',
            color: 'coral',
          };
        case 7:
          return {
            icon: 'calendar',
            color: 'pink',
          };
        case 8:
          return {
            icon: 'share',
            color: 'turquoise',
          };
        default:
          return {
            icon: 'note-3',
            color: 'gray-light',
          };
      }
    })(category);
    setIcon(newProps.icon);
    setColor(newProps.color);
  }, [category]);

  return (
    <div
      className='toast bg-white shadow bd-radius mgb-1 pgv-0_5 pgl-0_5 pgr-2'
      onClick={clickOnToast}
    >
      {type ? (
        <React.Fragment>
          <p className='no-margin mgb-0_5 h3 text-primary-dark'>
            {fr.toast[type].title}
          </p>
          <p className='no-margin text-primary-dark'>{fr.toast[type].text}</p>
        </React.Fragment>
      ) : (
        <div className='flex align-start-start'>
          <div className={`pg-0_25 bg-${color} text-white bd-radius mgr-0_5`}>
            <svg className='w-2 h-2'>
              <use xlinkHref={`#icon-${icon}`} />
            </svg>
          </div>
          <p
            className={`no-margin fz-13 lh-1_2 text-black ${
              link ? 'curp' : ''
            }`}
            onClick={(e) => {
              closeToast();
              if (link) {
                if (link.indexOf(window?.location?.hostname) !== -1) {
                  return window.location.href = link;
                } else {
                  return window.open(link, '_blank');
                }
              }
            }}
          >
            {message}
          </p>
        </div>
      )}
      <span className='toast__close' onClick={closeToast}>
        <svg className='w-2 h-2'>
          <use xlinkHref='#icon-close' />
        </svg>
      </span>
    </div>
  );
};

Toast.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  closeToast: PropTypes.func,
  clickOnToast: PropTypes.func,
};

export default connect(null, { removeToast })(Toast);
