import React, { useEffect, useState } from 'react';
import Icons from '../../atoms/Icons';
import { useDispatch, useSelector } from 'react-redux';
import {getSatisfactionSurveyQuestions, postSatisfactionSurveyAnswers, skipSatisfactionSurey } from '../../../stores/satisfactionSurvey';
import Block from '../../atoms/Block';
import './satisfactionSurvey.css';

const SatisfactionSurvey = ({closeSatisfactionSurvey, surveyId}) => {
  const dispatch = useDispatch();
  const surveyQuestions = useSelector((state) => state.satisafactionSurvey?.satisfactionSurveyQuestions);
  const satisfactionSurvey = useSelector((state) => state.me.selectedOffer?.satisfactionSurvey);

  const [showQuestions, setShowQuestions] = useState(false);
  const [showValidationbutton, setShowValidationbutton] = useState(false);

  useEffect(() => {
    surveyId && dispatch(getSatisfactionSurveyQuestions(surveyId));
  }, []);

  const createMarkup = (htmlContent) => {
    return {__html: htmlContent};
  };

  const goToQuestion = () => {
    setShowQuestions(true);
  };

  const [listQuestion, setListQuestion] = useState([]);
  const [freeAnswer, setFreeAnswer] = useState(null);
  const saveAnswer = (e, question) => {
    if(listQuestion.some(listQuestionItem => listQuestionItem.questionGroupId === question?.questionGroupId)){
      setListQuestion(() => listQuestion.map(item => {
        if (item.questionGroupId === question?.questionGroupId) {
          return {
            questionGroupId: question?.questionGroupId,
            questions: [{ id: question?.questions[0]?.id, answerId: parseInt(e.target.value) }]
          };
        } else {
          return item;
        }
      }));
    } else {
      setListQuestion([...listQuestion, {
        questionGroupId: question?.questionGroupId,
        questions: [{ id: question?.questions[0]?.id, answerId: parseInt(e.target.value) }]
      }]);
    }
  };


  const sendAnswers = () => {
    dispatch(postSatisfactionSurveyAnswers({ questionsList: listQuestion }, surveyId));
    closeSatisfactionSurvey(false);
  };

  const skipSurvey = () => {
    dispatch(skipSatisfactionSurey(surveyId));
    closeSatisfactionSurvey(false);
  };

  useEffect(() => {
    if (freeAnswer && listQuestion.some(listQuestionItem => listQuestionItem.questionGroupId === freeAnswer?.questionGroupId)) {
      setListQuestion(() => listQuestion.map(item => {
        return item.questionGroupId === freeAnswer?.questionGroupId ? { ...item, answerText: freeAnswer?.answerText } : item;
      }));
    } else if (freeAnswer) {
      setListQuestion([...listQuestion, { questionGroupId: freeAnswer?.questionGroupId, answerText: freeAnswer?.answerText }]);
    }
  }, [freeAnswer]);
  const hasAnswerAllQCUQuestion = () => {
    const filterQCU = questions =>  questions.filter(x => x.type === 3).length;
    let QCUnumbers = surveyQuestions?.reduce((acc, item) => acc + filterQCU(item.questionsList), 0);
    let QCUAnswerNumbers = listQuestion?.filter(x => x.questions?.length > 0)?.length;
    return QCUnumbers === QCUAnswerNumbers;
  };

  useEffect(() => {
    hasAnswerAllQCUQuestion() && setShowValidationbutton(true);
  }, [listQuestion]);

  const answerRender = answerName => {
    const iconRender = (color, icon) =>
      <>
        {answerName}
        <Icons
          className={`${color} cef-w-10 cef-h-10 cef-ml-3 cef-mb-1`}
          name={icon}
        />
      </>;

    switch (answerName) {
      case '1':
        return iconRender('cef-text-error', 'icon-cef-social-sentiment_dissatisfied');
      case '2':
        return iconRender('cef-text-orange-600', 'icon-cef-social-sentiment_dissatisfied');
      case '3':
        return iconRender('cef-text-yellow-400', 'icon-cef-social-sentiment_neutral');
      case '4':
        return iconRender('cef-text-green-400', 'icon-cef-social-sentiment_satisfied');
      case '5':
      default:
        return iconRender('cef-text-green-900', 'icon-cef-social-sentiment_satisfied');
    }
  };

  return (
    <div className='onboarding'>
      <div className='sm:cef-w-6/12 cef-w-11/12 cef-bg-white cef-p-5 cef-max-h-screen cef-overflow-auto'>
        <div className="cef-flex cef-justify-between">
          <Icons
            className="cef-w-14 cef-h-14 cef-text-primary"
            name="icon-cef-social-poll"
          />
          <span onClick={skipSurvey}>
            <Icons
              className="cef-w-12 cef-h-12 cef-text-black cef-cursor-pointer"
              name="icon-close"
            />
          </span>
        </div>
        {satisfactionSurvey && satisfactionSurvey?.lastSkip &&
          <Block
            className="cef-bg-warningbg cef-text-warning cef-flex cef-mx-auto cef-items-center cef-px-10 "
          >
            <div>
              <Icons
                className="cef-w-14 cef-h-14 cef-text-warning "
                name="icon-cef-alert-warning_amber"
              />
            </div>
            <div className="cef-ml-5">
            Attention c'est la dernière fois que vous pouvez ignorer cette enquête de satisfaction
            </div>
          </Block>
        }
        {!showQuestions ?
          <div className='cef-mt-3'>
            {satisfactionSurvey && satisfactionSurvey?.idType === 1 ?
              <div>
                A mi-parcours de votre prise en charge, nous aimerions connaître votre avis quant à la formation.
                Nous vous invitons à cliquer ci-dessous afin de démarrer le sondage :
              </div>
              : satisfactionSurvey && satisfactionSurvey?.idType === 2 ?
                <div>
                  Votre formation se termine prochainement : nous aimerions connaitre votre avis final !
                  Nous vous invitons à cliquer ci-dessous afin de démarrer la notation :
                </div> : ''
            }
            <div
              className='cef-rounded-full cef-bg-primary cef-text-white cef-font-semibold cef-w-1/4 cef-h-14 cef-mt-5 cef-mx-auto cef-cursor-pointer'
              onClick={goToQuestion}
            >
              <span
                className='cef-flex cef-justify-center cef-items-center cef-h-full'
              >
              Commencer
              </span>
            </div>
          </div>
          :
          <>
            <div className="cef-my-5 cef-text-3xl cef-font-bold cef-ml-3 cef-text-center">
              {satisfactionSurvey && satisfactionSurvey.description}
            </div>
            {surveyQuestions &&
              surveyQuestions.length > 0 &&
              surveyQuestions.map((theme, index) => (
                <div key={index}>
                  {theme?.themeName ?
                    <div className="cef-text-3xl cef-font-bold cef-mt-5">
                      {theme?.themeName}
                    </div> :
                    <div className="cef-h-14"/>
                  }
                  {theme?.questionsList.map(question => (
                    <div key={question?.questionGroupId}>
                      <h3 className="cef-mt-3 cef-font-normal">
                        {question?.entitled && (
                          <span dangerouslySetInnerHTML={createMarkup(question?.entitled)} />
                        )}
                      </h3>
                      {question?.type === 3 ? (
                        <div className="cef-flex cef-justify-around">
                          {question &&
                            question?.questions[0]?.answers?.map(answer => (
                              <div className="inputSatisfaction cef-flex" key={answer?.id}>
                                <input
                                  type="radio"
                                  value={answer.id}
                                  id={answer.id}
                                  name={question.questionGroupId}
                                  defaultChecked={null}
                                  onClick={(e) => saveAnswer(e, question)}
                                />
                                <label htmlFor={answer?.id} className="cef-border-none">
                                  {answerRender(answer?.name)}
                                </label>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div>
                          <textarea
                            name={question?.questionGroupId}
                            className="cef-border-solid cef-border-2 cef-border-black cef-min-h-min"
                            onChange={(e) => setFreeAnswer({ questionGroupId: question?.questionGroupId, answerText: e.target.value })}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </>
        }
        {showQuestions && showValidationbutton &&
          <div
            className="cef-rounded-full cef-bg-primary cef-text-white cef-font-semibold cef-w-1/4 cef-h-14 cef-mt-5 cef-mx-auto cef-cursor-pointer"
            onClick={sendAnswers}
          >
            <span
              className="cef-flex cef-justify-center cef-items-center cef-h-full"
            >
              Envoyer
            </span>
          </div>
        }
      </div>

    </div>
  );
};

export default SatisfactionSurvey;

