import React from 'react';
import './index.css';
import PropTypes from 'prop-types';

const Chip = ({onMenuToggle, icon}) => {
  return (
    <div style={{background: onMenuToggle ? 'red' : 'initial', }} className={`chip ${onMenuToggle && 'togglePosition'}`} >
      {icon && 
        (
          <svg className="w-4 h-4 icon big info text-alert" style={{cursor: 'pointer'}}>
            <use xlinkHref={ 
              '#icon-info'}/>
          </svg>
        )}
    </div>
  );
};

Chip.propTypes = {
  onMenuToggle: PropTypes.bool,
  icon: PropTypes.bool
};

export default Chip;