import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import IdleTimer from 'react-idle-timer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sendTime } from '@/stores/userTime';
import env from '../../../env';

const AuditTimer = ({ children, me, isAuthenticated, sendTime }) => {
  const [seconds, setSeconds] = useState(0);
  const [secondsTimer, setSecondsTimer] = useState(0);
  const [IntervalMethod, setIntervalMethod] = useState();
  const [IntervalMethodToSend, setIntervalMethodToSend] = useState();
  const [isActive, setIsActive] = useState(false);

  // ==> Timer for user active fn
  const Timer = () => {
    setIntervalMethod(
      setInterval(() => {
        setSeconds((prevState) => prevState + 1);
      }, 1000)
    );
  };

  // ==> TimerToSend Data fn
  const TimerToSend = () => {
    setIntervalMethodToSend(
      setInterval(() => {
        setSecondsTimer((secondsTimer) => secondsTimer + 1);
      }, 1000)
    );
  };

  // ==> resetTimerToSend Data fn
  const resetTimerToSend = () => {
    clearInterval(IntervalMethodToSend);
    setSecondsTimer(false);
  };

  // ==> StopTimer fn
  const stopTimer = () => {
    clearInterval(IntervalMethod);
  };

  useEffect(() => {
    if (seconds > 1) localStorage.setItem('storageTime', seconds);
  }, [seconds]);

  const SendTimeUser = (e, time) => {
    if (
      (secondsTimer > 0 &&
        window?.location?.pathname !== '/login' &&
        seconds > 0) ||
      time
    ) {
      let timeObject = {
        time: seconds || Number(time),
        pathName: null,
        // previousUrlObject && previousUrlObject.CurrentUrl ? previousUrlObject.CurrentUrl : '/',
      };
      sendTime(timeObject, me?.selectedOffer?.numContract);
      setSeconds(0);
      localStorage.removeItem('storageTime');
    }
  };

  useEffect(() => {
    if (me && me?.selectedOffer && me?.selectedOffer?.numContract) {
      let time = localStorage.getItem('storageTime');
      if (time > 0) {
        SendTimeUser(undefined, time);
      }
    }
  }, [me]);

  useEffect(() => {
    if (isAuthenticated) TimerToSend();
  }, [isAuthenticated]);

  // ==> Hooks for send data
  useEffect(() => {
    if (secondsTimer === env.TimeToSendData) {
      SendTimeUser();
      resetTimerToSend();
    }
  });

  // ==> Relaunch the timer for send data
  useEffect(() => {
    if (secondsTimer === false) {
      TimerToSend();
    }
  }, [secondsTimer]);

  useEffect(() => {
    if (isActive === true) {
      Timer();
    } else if (isActive === false) {
      stopTimer();
    }
  }, [isActive]);

  const mouseStop = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      let timeout;
      env.DefaultEventsTracking.map((c) =>
        document.body.addEventListener(c, (event) => {
          if (timeout) clearTimeout(timeout);
          setIsActive(true);
          timeout = setTimeout(mouseStop, env.IdleUserTimeout);
        })
      );
    }
  }, [isAuthenticated]);

  return <>{children}</>;
};

AuditTimer.defaultProps = {};
AuditTimer.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string,
  me: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  sendTime: PropTypes.func,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ sendTime }, dispatch);

const mapStateToProps = (state) => {
  return {
    me: state.me,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditTimer);
