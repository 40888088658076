import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';

const LazyImage = ({ src, alt, className, style, height, width }) => {
  const [imageSrc, setImageSrc] = useState();
  const [imageRef, setImageRef] = useState();
  const [classNames, setClassName] = useState();
  const [modifiedStyle, setStyle] = useState();
  const [fallbackImage, setFallbackImage] = useState();

  useEffect(() => {
    if (className)
      setClassName([...(className || '').split(' '), 'lazy-image'].join(' '));
  }, [className]);

  useEffect(() => {
    if (style || !style) {
      setStyle({
        '--ratio': `${(height / width) * 100}%`,
        width: typeof width === 'string' ? parseInt(width, 10) : width,
      });
    }
  }, [style, height, width]);

  useEffect(() => {
    let observer;
    let didCancel = false;
    if (imageRef && imageSrc === placeHolder) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (
              !didCancel &&
              (entry.intersectionRatio > 0 || entry.isIntersecting)
            ) {
              setImageSrc(src);
            }
          });
        });
        observer.observe(imageRef);
      } else {
        setImageSrc(src);
      }
    } else {
      src && setImageSrc(src);
    }
    return () => {
      didCancel = true;
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  });

  return (
    <>
      {!fallbackImage && (
        <img
          ref={setImageRef}
          onError={() => setFallbackImage(true)}
          className={classNames}
          src={imageSrc}
          style={modifiedStyle}
          alt={alt}
        />
      )}
    </>
  );
};

export default LazyImage;

LazyImage.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.any,
  className: PropTypes.any,
  style: PropTypes.any,
  height: PropTypes.any,
  width: PropTypes.any,
};
