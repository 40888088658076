import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_VIDEOS_REQUESTED = 'videos/GET_VIDEOS_REQUESTED';
export const GET_VIDEOS_SUCCESSFUL = 'videos/GET_VIDEOS_SUCCESSFUL';
export const GET_VIDEOS_FAILED = 'videos/GET_VIDEOS_FAILED';

const initialState = {};

export default function Videos(state = initialState, action) {
  switch (action.type) {
    case GET_VIDEOS_REQUESTED:
      return initialState;
    case GET_VIDEOS_SUCCESSFUL:
      return action.data;
    case GET_VIDEOS_FAILED:
      return initialState;
    default:
      return state;
  }
}

export const getVideos = (chapterId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_VIDEOS_REQUESTED });

  return Api(`/chapters/${chapterId}/videos`, { params: params })
    .then((res) => {
      let { videos: list, chapterName } = res.data;

      dispatch({
        type: GET_VIDEOS_SUCCESSFUL,
        data: {
          list,
          chapterName,
        },
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_VIDEOS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
