import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Notification from './Notification/Notification';

import { connect } from 'react-redux';

import { getNotifications } from '@/stores/notifications';

const Notifications = ({ notifications, unreadNotifications, getNotifications }) => {
  const [shouldShowNotifications, updateNotificationsStatus] = useState(false);
  const [shouldShowMoreNotifications, updateMoreNotificationsStatus] = useState(false);
  
  const onContainerRef = useRef(null);
  const onTriggerRef = useRef(null);
  
  // const handleClick = (e) => {
  //   let t = e.target;
  //   while (t !== document && t !== onContainerRef && t !== onTriggerRef) {
  //     if (t === document.body) {
  //       updateNotificationsStatus(false);
  //       updateMoreNotificationsStatus(false);
  //     }
  //     t = t.parentNode;
  //   }
  // };

  const toggleNotifications = () => {
    if (shouldShowNotifications) {
      updateMoreNotificationsStatus(false);
      // document.body.removeEventListener('click', handleClick);
    } else {
      getNotifications(5);
      // document.body.addEventListener('click', handleClick);
    }
    updateNotificationsStatus(!shouldShowNotifications);
  };

  const toggleMoreNotifications = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getNotifications();
    updateMoreNotificationsStatus(true);
  };

  return (
    <React.Fragment>
      <div
        ref={onTriggerRef}
        className='h-bg-primary-dark anim-all round notifications__trigger w-5 h-5 flex align-center-center curp mgr-0_5'
        onClick={() => toggleNotifications()}
      >
        <div className='relative'>
          <svg className='w-3 h-3 text-white'>
            <use xlinkHref='#icon-alert' />
          </svg>
          {unreadNotifications > 0 && (
            <span className='round bd-2-primary flex align-center-center bg-alert fz-12 fw-700 text-white lh-1'>
              {unreadNotifications}
            </span>
          )}
        </div>
        {shouldShowNotifications && (
          <div
            ref={onContainerRef}
            className={`notifications__list ${
              shouldShowNotifications ? 'active' : ''
            }`}
          >
            <div className= 'flex align-spacebetween-start bdb-1-gray-light pg-0_5'>
              <div><h3 className='no-margin pg-0_5  '>Notifications</h3></div>
              <div className= ' mgt-0_25 '>
                {notifications.length > 5 && !shouldShowMoreNotifications && (
                  <button
                    className='button primary outline small '
                    onClick={toggleMoreNotifications}
                  >
                    Voir tout
                  </button>
                )}
              </div>
            </div>
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                {...notification}
                toggleNotifications={toggleNotifications}
              />
            ))}
            {notifications.length === 0 && (
              <div className='notification flex align-start-start pgv-0_5 mgl-1'>
                <p className='no-margin fz-13 lh-1_425 text-black'>
                  Aucune notification
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Notifications.propTypes = {
  unreadNotifications: PropTypes.number,
  notifications: PropTypes.any,         
};

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps, { getNotifications })(Notifications);
