import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_CONTRACTS_REQUESTED = 'contracts/GET_CONTRACTS_REQUESTED';
export const GET_CONTRACTS_SUCCESSFUL = 'contracts/GET_CONTRACTS_SUCCESSFUL';
export const GET_CONTRACTS_FAILED = 'contracts/GET_CONTRACTS_FAILED';
export const PUT_TERMINATE_CONTRACT_REQUESTED = 'contracts/PUT_TERMINATE_CONTRACT_REQUESTED';
export const PUT_TERMINATE_CONTRACT_SUCCESSFUL = 'contracts/PUT_TERMINATE_CONTRACT_SUCCESSFUL';
export const PUT_TERMINATE_CONTRACT_FAILED = 'contracts/PUT_TERMINATE_CONTRACT_FAILED';


export const CONTRACTS_DOWNLOAD_REQUESTED =
  'contracts/CONTRACTS_DOWNLOAD_REQUESTED';
export const CONTRACTS_DOWNLOAD_SUCCESSFUL =
  'contracts/CONTRACTS_DOWNLOAD_SUCCESSFUL';
export const CONTRACTS_DOWNLOAD_FAILED = 'contracts/CONTRACTS_DOWNLOAD_FAILED';

const initialState = {
  contracts: [],
};

export default function Contracts(state = initialState, action) {
  switch (action.type) {
    case GET_CONTRACTS_SUCCESSFUL:
      return {
        ...state,
        contracts: action.contracts,
      };
    case PUT_TERMINATE_CONTRACT_REQUESTED:
      return {
        ...state,
        displayAlertModal: false
      };
    case PUT_TERMINATE_CONTRACT_SUCCESSFUL:
      return {
        ...state,
        contract: action.contract,
      };
    case PUT_TERMINATE_CONTRACT_FAILED:
      return {
        ...state,
        displayAlertModal: true
      };
    case GET_CONTRACTS_FAILED:
      return initialState;
    case CONTRACTS_DOWNLOAD_SUCCESSFUL:
      return {
        ...state,
        download: { file: action.download.file, name: action.download.name },
      };
    case CONTRACTS_DOWNLOAD_FAILED:
      return state;
    default:
      return state;
  }
}

export const getContracts = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_CONTRACTS_REQUESTED });

  return Api('/contracts', { params: params })
    .then((res) => {
      dispatch({
        type: GET_CONTRACTS_SUCCESSFUL,
        contracts: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_CONTRACTS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const terminateContract = (simulation = true, rae = null) => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: PUT_TERMINATE_CONTRACT_REQUESTED });

  return Api.put('/terminate_contract', {simulation, rae},{ params: params })
    .then((res) => {

      if (res.status === 200) {
        dispatch({
          type: PUT_TERMINATE_CONTRACT_SUCCESSFUL,
          contract: res.data,
        });
        return true;
      } else {
        dispatch({ type: PUT_TERMINATE_CONTRACT_FAILED });
      }

      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};