import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { withRouter } from 'react-router';
import { markAsRead } from '@/stores/notifications';
import { connect } from 'react-redux';

const Notification = ({
  id,
  category,
  message,
  link,
  read,
  date,
  markAsRead
}) => {

  const [icon, setIcon] = useState(null);
  const [color, setColor] = useState(null);
  
  const markAsReadFunc = () => {
    markAsRead(id).then(() => {
      if(link){
        if(link.indexOf(window?.location?.hostname) !== -1){
          return window.location.href = link;
        }else{
          return window.open(link, '_blank');
        }
      }
    });
  };

  useEffect(() => {
    let newProps = (function(c) {  
      switch(c) {
        case 2:
          return {
            icon: 'shield',
            color: 'primary'
          };
        case 3:
          return {
            icon: 'lightning',
            color: 'purple'
          };
        case 4:
          return {
            icon: 'podium',
            color: 'teal'
          };
        case 5:
          return {
            icon: 'folder',
            color: 'purple'
          };
        case 6:
          return {
            icon: 'clock-detailed',
            color: 'coral'
          };
        case 7:
          return {
            icon: 'calendar',
            color: 'pink'
          };
        case 8:
          return {
            icon: 'share',
            color: 'turquoise'
          };
        default:
          return {
            icon: 'note-3',
            color: 'gray-light'
          };
      }
    })(category);
    setIcon(newProps.icon);
    setColor(newProps.color);
  }, [category]);

  return (
    <div className={`notification flex align-start-start pgv-0_5 mgl-1 pgr-1 bdb-1-gray-light ${!read ? 'unread' : ''}`}>
      <div className={`pg-0_25 bg-${color} text-white bd-radius mgr-0_5`}>
        <svg className="w-2 h-2"><use xlinkHref={`#icon-${icon}`} /></svg>
      </div>
      <p
        className="no-margin fz-13 lh-1_425 text-black"
        onClick={ () => { markAsReadFunc(); } }
      >
        { message } <span className="text-gray-dark">• { formatDistance(new Date(date), new Date(), {locale: fr}) }</span>
      </p>
    </div>
  );
};


Notification.propTypes = {
  id: PropTypes.number,
  category: PropTypes.number,
  message: PropTypes.string,
  link: PropTypes.string,
  read: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  color: PropTypes.string,
  icon: PropTypes.string,
  markAsRead: PropTypes.func,
};

export default withRouter(connect(null, {markAsRead})(Notification));