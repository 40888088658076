import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const LoadIndicator = ({ size, isLoading }) => {
  const style = { width: size };
  const className = ['loadIndicator', isLoading && 'show'].join(' ');
  const classNameInner = 'loadIndicator__inner';

  return (
    <div className={className}>
      <div className={classNameInner} style={style}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

LoadIndicator.propTypes = {
  size: PropTypes.number,
  isLoading: PropTypes.bool,
  color: PropTypes.string
};

export default LoadIndicator;
