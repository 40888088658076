import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const SCHOOL_REPORT_REQUESTED = 'schoolReport/SCHOOL_REPORT_REQUESTED';
export const SCHOOL_REPORT_GET_SUCCESSFUL =
  'schoolReport/SCHOOL_REPORT_GET_SUCCESSFUL';
export const SCHOOL_REPORT_GET_FAILED =
  'schoolReport/SCHOOL_REPORT_GET_FAILEDL';
export const SCHOOL_REPORT_PDF_REQUESTED =
  'schoolReport/SCHOOL_REPORT_PDF_REQUESTED';
export const SCHOOL_REPORT_GET_PDF_SUCCESSFUL =
  'schoolReport/SCHOOL_REPORT_GET_PDF_SUCCESSFUL';
export const SCHOOL_REPORT_GET_PDF_FAILED =
  'schoolReport/SCHOOL_REPORT_GET_PDF_FAILED';
export const SEND_FILE_SUCCESSFUL = 'schoolReport/SEND_FILE_SUCCESSFUL';
export const SEND_FILE_FAILED = 'schoolReport/SEND_FILE_FAILED';
export const REMOVE_FILE_SUCCESSFULL = 'schoolReport/REMOVE_FILE_SUCCESSFULL';
export const REMOVE_FILE_FAILED = 'schoolReport/REMOVE_FILE_FAILED';

let utils = {
  formData: new FormData(),
  data: null,
};

const initialState = {
  schoolReport: {
    internships: {},
    documents: {},
    thePdf: {},
  },
};

export default function SchoolReport(state = initialState, action) {
  switch (action.type) {
    case SEND_FILE_SUCCESSFUL:
      return action.user;

    case SCHOOL_REPORT_GET_SUCCESSFUL:
      return action.schoolReport;

    case SCHOOL_REPORT_GET_FAILED:
      return {};

    case SEND_FILE_FAILED:
      return {};

    case SCHOOL_REPORT_GET_PDF_SUCCESSFUL:
      return {
        ...state,
        internships: state.internships,
        documents: state.documents,
        thePdf: action.thePdf,
      };

    case SCHOOL_REPORT_GET_PDF_FAILED:
      return { ...state };

    case REMOVE_FILE_SUCCESSFULL:
      return {
        ...state,
        parameter: {
          ...state.parameter,
          ...action.parameter,
        },
      };

    case REMOVE_FILE_FAILED:
      return state;

    default:
      return state;
  }
}

export const getSchoolReport = () => async (dispatch) => {
  let { data } = utils;

  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  return Api.get('/livret', { params: params })
    .then((res) => {
      data = res.data;

      dispatch({
        type: SCHOOL_REPORT_GET_SUCCESSFUL,
        schoolReport: data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: SCHOOL_REPORT_GET_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getSchoolReportPdf =
  (numContract, setStateInput) => async (dispatch) => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    // "/report/export/T304156U"
    return Api.get(`/livret/export/${numContract}`, {
      responseType: 'arraybuffer',
    })
      .then((res) => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        setStateInput('Télécharger');
        dispatch({
          type: SCHOOL_REPORT_GET_PDF_SUCCESSFUL,
          thePdf: file,
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(() => {
        dispatch({ type: SCHOOL_REPORT_GET_PDF_FAILED });
        setStateInput('Indisponible');
        dispatch({ type: ISNT_LOADING });
      });
  };

export const sendFile =
  (idPath, file, documentId, refreshData, setStateInput) =>
    async (dispatch) => {
      let { formData, data } = utils;

      formData.append('document', file);

      let route = documentId ? `2/${idPath}` : `${idPath}`;

      return Api.post(`/livret/${route}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: params,
      })
        .then((res) => {
          data = res.data;
          dispatch({
            type: SCHOOL_REPORT_GET_PDF_SUCCESSFUL,
            pdfR: data,
          });
          dispatch({ type: ISNT_LOADING });
          formData.delete('document', file);
          refreshData();
          setStateInput('Demander');
        })
        .catch((err) => {
          dispatch({ type: SCHOOL_REPORT_GET_PDF_FAILED });
          if (err) {
            formData.delete('document', file);
            dispatch({
              type: SET_ERROR,
              value: err,
            });
          }
          dispatch({ type: ISNT_LOADING });
          refreshData();
          setStateInput('Demander');
        });
    };

export const removeFile =
  (id, internshipsId, refreshData, setStateInput) => async (dispatch) => {
    // let {formData} = utils

    // formData.append('file', file);

    let route = internshipsId ? `2/${id}` : `${id}`;

    return Api.delete(`/livret/${route}`, { params: params })
      .then((res) => {
        dispatch({
          type: SCHOOL_REPORT_GET_PDF_SUCCESSFUL,
          pdfR: res.data,
        });
        dispatch({ type: ISNT_LOADING });
        refreshData();
        setStateInput('Demander');
      })
      .catch((err) => {
        dispatch({ type: SCHOOL_REPORT_GET_PDF_FAILED });
        if (err?.response?.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err,
          });
        }
        dispatch({ type: ISNT_LOADING });
        refreshData();
        setStateInput('Demander');
      });
  };
