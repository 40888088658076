import React from 'react';

const Block = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    onClick,
    fullWidth = false
  } = props;
  return (
    <div
      ref={ref}
      className={`${className} cef-border cef-rounded-v2 cef-border-neutral9 cef-text-current ${fullWidth ? 'cef-w-full' : 'cef-w-max'}`}
      onClick={onClick}
    >
      { children && children }
    </div>
  );
});

export default Block;