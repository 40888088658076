import React from 'react';

/**
 * Icons
 */

const Icons = ({
  className,
  name}) => {
  return (
    <div className={className}>
      <svg><use href={`#${name}`}/></svg>
    </div>
  );
};

export default Icons;