import React from 'react';
import { addToGTM } from '@components/molecules/gtm';
import AlertBox from '@components/atoms/alertBox';

class ErrorBoundary extends React.Component {
  constructor(props)
  {
    super(props);
    this.state = { hasError: false, redirect: false };
  }
    
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    addToGTM({
      errorMessage: error?.toString(),
      errorPreviousPage: errorInfo,
      errorPage: window?.location?.href,
      event: 'technicalError',
    });    
    setTimeout(() => {
      window.history.back();
      window.location.reload();
    }, 1500);
  }

  render() {
    return (
      <>
        {(this.state.hasError && window?.location?.pathname !== '/login') && (
          <AlertBox
            type="alert"
            className="text-center"
            message={
              this.state.hasError ? '' : 'Une erreur est survenue. La page va automatiquement se recharger d\'ici quelques instants.'
            }
          />
        )}
        <>
          {this.props.children}
        </>
      </>
    );
  }
}  

export default ErrorBoundary;
