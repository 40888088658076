let fr = {
  route: {
    courses: 'cours',
    learning: 'apprendre',
    videos: 'videos',
    revise: 'reviser',
    reviseV2: 'je_revise',
    evaluate: 'evaluer',
    homework: 'devoir',
    homeworkV2: 'devoirs',
    presentation: 'presentation',
    homeworkCorrection: 'correction-devoir',
    chapter: 'chapitre',
    subchapter: 'sous-chapitre',
    question: 'questions',
    questionV2: 'question',
    faq: 'faq',
    agenda: 'agenda',
    videoconferences: 'visioconferences',
    internships: 'stages',
    evaluationForm: 'formulaire-evaluation',
    jobOffers: 'profil-emploi',
    ranking: 'classement',
    challenges: 'defis',
    challenge: 'defi',
    challengeV2: 'mes_defis',
    agenda_week: 'hebdomadaire',
    agenda_year: 'annuel',
    profile: 'mon-profil',
    report: 'bulletin',
    badges: 'badges',
    chat: 'messagerie',
    ressources: 'ressources',
    playlist: 'playlist',
    profile_settings: 'paramètres',
    profile_ids: 'identifiants',
    profile_shippings: 'livraisons',
    profile_documents: 'documents',
    profile_examinations: 'examens',
    profile_payment: 'paiement',
    payment_success: 'paiement-succes',
    payment_failure: 'paiement-echec',
    profile_account: 'mon-compte',
    suspendedAccount: 'compte-suspendu',
    position: 'positionnement',
    schoolReport: 'livret-scolaire',
    account: 'mon-compte',
    account_contract: 'mon-contrat',
    account_cgu: 'mes-cgu',
    account_bills: 'factures',
    account_rules: 'reglement',
    preregistration_exam: 'preinscription-examen',
    quiz : 'quizz',
    doingHomework : 'question',
    home: 'accueil',
    learning_ressources : 'mon-apprentissage',
    mentoring : 'mon_mentorat'
  },
  meta: {
    login: {
      title: 'CEF Learning, Espace élève du Centre Européen de Formation',
      description:
        'Connexion à CEF learning, l\'espace élève en ligne du Centre Européen de Formation. Accédez à vos modules e-learning, à votre communauté et discutez avec vos professeurs.',
    },
  },
  login: {
    form: {
      username: {
        login: 'N° d\'élève',
        reset: 'N° d\'élève',
      },
      password: {
        login: 'Mot de passe',
        new: 'Définir un mot de passe',
      },
    },
    lostPassword: 'Mot de passe oublié ?',
    submit: {
      login: 'Valider',
      reset: 'Réinitialiser le mot de passe',
      new: 'Valider',
    },
    success: {
      new: 'Votre mot de passe a été mis à jour. Veuillez vous reconnecter.',
      reset:
        'Un lien de réinitialisation de mot de passe vous a été envoyé sur votre boîte mail.\n Vous n’avez pas reçu le mail ? Patientez quelques minutes et pensez à vérifier vos indésirables.',
    },
    error: {
      notLoggedIn: 'Veuillez vous reconnecter.',
      unauthorized:
        'Votre identifiant ou mot de passe est erroné.\n Rappel : pour votre première connexion à CEF Learning, vous devez cliquer sur "Mot de passe oublié ?"',
      error: 'Une erreur est survenue, veuillez essayer plus tard.',
      invalidToken:
        'Votre lien de réinitialisation semble invalide. Veuillez recommencer la procédure.',
    },
    backToLogin: {
      reset: 'Revenir à la page de connexion',
      new: 'Revenir à la page de connexion',
    },
    installBanner: {
      ios: {
        chrome:
          'https://www.centre-europeen-formation.fr/document/tutoriaux/index-iphone-chrome?.html',
        firefox:
          'https://www.centre-europeen-formation.fr/document/tutoriaux/index-iphone-firefox.html',
        safari:
          'https://www.centre-europeen-formation.fr/document/tutoriaux/index-iphone-safari.html',
      },
      android: {
        firefox:
          'https://www.centre-europeen-formation.fr/document/tutoriaux/index-android-firefox.html',
      },
    },
  },
  positionnement: {
    text: 'N\'oubliez pas de réaliser votre test de positionnement obligatoire pour bien démarrer votre formation ! Cela ne vous prendra que quelques minutes.',
    text2:
      'N\'oubliez pas de réaliser votre test de fin de formation ! Vous pourrez ainsi mesurer votre progression suite à votre formation. Cela ne vous prendra que quelques minutes.',
    alreadyVisited:
      'N\'oubliez pas de terminer votre test de positionnement ! Cela ne vous prendra que quelques minutes.',
    click_link: 'Cliquez ici pour commencer',
    not_realized:
      'Vous n’avez pas réalisé le test de positionnement permettant d’évaluer vos connaissances dans  le  domaine  de  formation  que  vous  avez  choisi.  Cette  étape  est  obligatoire  pour évaluer votre progression entre le début et la fin de votre formation.Ce test ne nécessite aucun prérequis. Sa durée est d’environ 3 minutes.Un test équivalent vous sera également proposé une fois votre formation terminée.Vous devez valider aujourd’hui cette étape importante pour accéder de nouveau à votre plateforme',
  },
  visioconference: {
    title: 'Mes visioconférences',
    join: 'Rejoindre',
    start: 'Début dans',
    completed: 'Terminée',
    future: 'A venir',
    inProgress: 'En cours...',
    speaker: 'Intervenant',
    status: 'Statut',
    rgpd: {
      introDashboard: 'Les visioconférences sont enregistrées par le CEF.',
      intro: 'Les visioconférences proposées dans cette section sont enregistrées par le Centre Européen de Formation.<br />En accédant à l’une de ces visioconférences, je reconnais avoir pris connaissance et accepter les dispositions relatives à leur enregistrement et à leur diffusion dans les conditions décrites.',
      textDashboard: 'Les visioconférences proposées dans cette section sont enregistrées par le Centre Européen de Formation.<br />En accédant à l’une de ces visioconférences, je reconnais avoir pris connaissance et accepter les dispositions relatives à leur enregistrement et à leur diffusion dans les conditions décrites.<br /><br />',
      text: '<strong>Finalités :</strong> Les enregistrements des visioconférences pourront être utilisés par le CEF à des fins :<br /><ul>' +
        '<li>De qualité et d’amélioration des services proposés aux élèves</li>' +
        '<li>De diffusion de contenu pédagogique pertinent sur le site CEF learning</li>' +
        '<li>De preuve, notamment en cas d’incident ou de réclamation.</li>' +
        '</ul>' +
        '<strong>Accessibilité :</strong> Les enregistrements ne sont accessibles qu’au personnel du CEF habilité et légitime à les consulter dans le cadre des finalités décrites. Certains enregistrements présentant un intérêt pédagogique sont également susceptibles d’être mis à disposition des élèves (sur le site CEF learning) inscrits dans la formation correspondante. <br />' +
        'Les enregistrements ne feront l’objet d’aucune utilisation commerciale et d’aucune transmission à des tiers par le CEF.<br /><br />' +
        '<strong>Base légale :</strong> Le dispositif repose sur l’intérêt légitime du CEF et sur l’exécution du contrat le cas échéant.<br /><br />' +
        '<strong>Durées de conservation :</strong> Les enregistrements sont conservés pour une durée maximale de 3 mois' +
        '<br /><br />' +
        '<span class=\'italic\'>Pour plus d’informations sur notre politique de protection des données personnelles, vous pouvez cliquer <a href=\'https://www.centre-europeen-formation.fr/politique-de-protection-de-vos-donnees-personnelles/\' target=\'_blank\'>ICI</a> .</span>' +
        '<br />' +
        '<span class=\'italic\'>Pour toute question relative à la protection de vos données personnelles ou si vous souhaitez exercer vos droits d’accès, d’opposition ou de suppression de vos données, vous pouvez adresser une demande à : donnee@cenef.fr</span><br />' +
        '<span class=\'italic bold\'>Attention : L’enregistrement et la diffusion d’une visioconférence par un élève, même partiellement (captures écrans, vidéos, photos,…), sont strictement interdits et peuvent être sanctionnés (article 226-1 et 226-2 du code pénal).</span>'
    },
    navigator:{
      text1: 'En cas de difficulté pour vous connecter à la visioconférence, merci de bien vouloir changer de navigateur internet en passant sur Google Chrome ou Mozilla Firefox.<br />',
      text2: 'Installez la dernière version, puis reconnectez vous à votre espace élève depuis ce navigateur.',
      chromeLink: 'https://www.google.com/intl/fr_fr/chrome/',
      chromeLinkText: 'Installez Chrome',
      firefoxLink: 'https://www.mozilla.org/fr/firefox/new/',
      firefoxLinkText: 'Installez Firefox'
    }
  },
  profile: {
    form: {
      firstName: 'Prénom',
      lastName: 'Nom',
      maidenName: 'Nom de jeune fille',
      birthDate: 'Date de naissance',
      gender: 'Civilité',
      family_situation: 'Situation familiale',
      choose_month: 'Sélectionner un mois',
      email: 'Adresse email',
      address: 'Adresse',
      address2: 'Appartement',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      phone: 'Téléphone',
      phone1: 'Téléphone 01',
      phone2: 'Téléphone 02',
      phone3: 'Téléphone 03',
      subscriptionDate: 'Date d\'inscription',
      lastDegreeSpeciality: 'Dernier diplôme obtenu',
    },
    changePassword: {
      title: 'Modifier votre mot de passe',
      message: {
        success: 'Votre mot de passe  bien été mis à jour.',
      },
    },
    changeInfos: {
      message: {
        success:
          'La demande de modification de vos informations a bien été transmise.',
      },
    },
    changePicture: {
      message: {
        success: 'Votre image de profil a bien été mise à jour.',
      },
    },
  },
  jobOffers: {
    changeCV: {
      message: {
        success: 'Votre CV a bien été mise à jour.',
        error: 'Une erreur est survenue. Veuillez recharger la page.',
      },
    },
    sendCorrectionFile: {
      message: {
        success: 'Votre document a bien été envoyé en correction.',
        error: 'Une erreur est survenue. Veuillez recharger la page.',
      },
    },
  },
  chat: {
    postTopic: {
      message: {
        success: 'Votre message a bien été envoyé.',
      },
    },
    blockTopic: {
      message: {
        success: 'La conversation a bien été bloquée.',
      },
    },
    unblockTopic: {
      message: {
        success: 'La conversation a bien été débloquée.',
      },
    },
    signalTopic: {
      message: {
        success: 'La conversation a bien été signalée.',
      },
    },
  },
  questionsGroups: {
    endPopin: {
      title: [
        'C\'est fini pour ce jeu',
        'Jeu terminé!',
        'Votre jeu est terminé!',
        'Ce jeu est terminé!',
        'C\'est la fin de ce jeu!',
      ],
      notation: [
        // Level 0
        [
          {
            subtitle: 'Ne vous découragez pas.',
            subtitle_secondary: 'Recommencez tout de suite pour progresser !',
          },
          {
            subtitle: 'Persévérez!',
            subtitle_secondary:
              'Recommencez ce jeu immédiatement pour améliorer votre score!',
          },
          {
            subtitle: 'N\'allez pas trop vite!',
            subtitle_secondary:
              'Refaites ce jeu tout de suite pour augmenter vos chances de réussite!',
          },
          {
            subtitle: 'Reprenez ce jeu maintenant.',
            subtitle_secondary:
              'Vous augmenterez ainsi vos probabilités de réussite!',
          },
          {
            subtitle: 'Recommencez ce jeu tout de suite.',
            subtitle_secondary:
              'Vos possibilités de gagner des étoiles seront plus grandes!',
          },
        ],
        // Level 1
        [
          {
            subtitle: 'Vous pouvez faire mieux !',
            subtitle_secondary: 'Battez le fer tant qu’il est chaud !',
          },
          {
            subtitle: 'Vous pouvez améliorer votre score.',
            subtitle_secondary: 'Recommencez ce jeu rapidement!',
          },
          {
            subtitle: 'Le secret de la réussite : la volonté.',
            subtitle_secondary:
              'Montrez que vous pouvez réussir et recommencez ce jeu sans plus attendre!',
          },
          {
            subtitle: 'Ce score ne vous ressemble pas.',
            subtitle_secondary:
              'Montrez que vous pouvez faire mieux, refaites ce jeu séance tenante!',
          },
          {
            subtitle: 'Vous avez la capacité de faire mieux.',
            subtitle_secondary:
              'A vous de le démontrer. Refaites ce jeu sans délai!',
          },
        ],
        // Level 2
        [
          {
            subtitle: 'Et si vous recommenciez ?',
            subtitle_secondary: 'La perfection n’est pas impossible !',
          },
          {
            subtitle: 'Ne vous contentez pas de ce score.',
            subtitle_secondary: 'Allez plus loin, recommencez ce jeu illico!',
          },
          {
            subtitle: 'La demi-teinte n\'est pas pour vous.',
            subtitle_secondary:
              'Faites preuve d\'éclat, augmentez votre score en refaisant ce jeu!',
          },
          {
            subtitle: 'Vous ne pouvez pas vous satisfaire de ce score.',
            subtitle_secondary: 'Recommencez ce jeu sans tarder!',
          },
          {
            subtitle: 'Rien n\'est impossible avec de l\'obstination.',
            subtitle_secondary:
              'Recommencez ce jeu pour atteindre les sommets!',
          },
        ],
        // Level 3
        [
          {
            subtitle: 'Pas mal du tout !',
            subtitle_secondary: 'Saurez-vous faire mieux ?',
          },
          {
            subtitle: 'Votre score est tout à fait correct.',
            subtitle_secondary:
              'Et vous pouvez encore l\'améliorer n\'en doutez pas!',
          },
          {
            subtitle: 'Bel effort.',
            subtitle_secondary:
              'Obtenez au moins 4 étoiles lors de votre prochaine tentative!',
          },
          {
            subtitle: 'Votre performance tient la route.',
            subtitle_secondary:
              'Recommencez et tentez d\'obtenir au moins 4 étoiles!',
          },
          {
            subtitle: 'Votre score est acceptable.',
            subtitle_secondary:
              'A vous de prouver que vous pouvez faire mieux!',
          },
        ],
        // Level 4
        [
          {
            subtitle: 'Très bon travail !',
            subtitle_secondary: 'La perfection n\'est pas loin !',
          },
          {
            subtitle: 'Jolie performance!',
            subtitle_secondary: 'Pensez-vous être capable d\'obtenir 5 étoiles?',
          },
          {
            subtitle: 'C\'est presque parfait.',
            subtitle_secondary:
              'Mais pas tout à fait! Faites encore un essai pour atteindre les 5 étoiles!',
          },
          {
            subtitle: 'Vous n\'êtes pas loin du sommet.',
            subtitle_secondary:
              'Encore un pas pour atteindre un score parfait!',
          },
          {
            subtitle: 'C\'est presque un sans faute.',
            subtitle_secondary:
              'Lancez-vous le défi d\'atteindre les 5 étoiles!',
          },
        ],
        // Level 5
        [
          {
            subtitle: 'Parfait !',
            subtitle_secondary: 'Passez à la suite!',
          },
          {
            subtitle: 'Vous êtes au top!',
            subtitle_secondary: 'Continuez dans la foulée!',
          },
          {
            subtitle: 'Rien à dire.',
            subtitle_secondary: 'C\'est excellent!',
          },
          {
            subtitle: 'La perfection existe.',
            subtitle_secondary: 'Vous venez de le prouver!',
          },
          {
            subtitle: 'Bravo!',
            subtitle_secondary: 'Vous êtes un(e) élève 5 étoiles!',
          },
        ],
      ],
    },
  },
  chapters: {
    endPopin: {
      title: [
        'C\'est fini pour cette partie',
        'Cette partie est terminée!',
        'Cette partie du chapitre est finie!',
        'Cette section du chapitre est terminée!',
        'Ce point du chapitre est terminé!',
      ],
      subtitle: [
        'Ne relâchez pas vos effort!\nPassez à la suite!',
        'Pour terminer le chapitre, passez à la suite!',
        'Poursuivez vos efforts!',
        'Restez concentré(e) et passez à l\'étape suivante.',
        'Ne relâchez pas votre attention.\nPassez à l\'étape suivante!',
      ],
    },
  },
  positioning: {
    endPopin: {
      title: ['Merci pour votre participation'],
    },
  },
  reviewing: {
    endPopin: {
      title: [
        'C\'est fini pour cette révision !',
        'Révision terminée !',
        'Votre révision est terminée !',
        'Cette révision est terminée !',
        'C\'est la fin de cette révision !',
      ],
      notation: [
        // Level 0
        [
          {
            subtitle: 'Ne vous découragez pas.',
            subtitle_secondary: 'Recommencez tout de suite pour progresser !',
          },
          {
            subtitle: 'Persévérez !',
            subtitle_secondary:
              'Révisez à nouveau ce cours pour améliorer votre score !',
          },
          {
            subtitle: 'N\'allez pas trop vite !',
            subtitle_secondary:
              'Reprenez cette révision tout de suite pour augmenter vos chances de réussite !',
          },
          {
            subtitle: 'Reprenez cette révision maintenant.',
            subtitle_secondary:
              'Vous augmenterez ainsi vos probabilités de réussite !',
          },
          {
            subtitle: 'Recommencez cette révision tout de suite.',
            subtitle_secondary:
              'Vos possibilité de gagner des étoiles seront plus grandes !',
          },
        ],
        // Level 1
        [
          {
            subtitle: 'Vous pouvez faire mieux !',
            subtitle_secondary: 'Battez le fer tant qu’il est chaud !',
          },
          {
            subtitle: 'Vous pouvez améliorer votre score.',
            subtitle_secondary: 'Recommencez cette révision rapidement !',
          },
          {
            subtitle: 'Le secret de la réussite : la volonté.',
            subtitle_secondary:
              'Montrez que vous pouvez réussir et recommencez cette révision sans plus attendre !',
          },
          {
            subtitle: 'Ce score ne vous ressemble pas.',
            subtitle_secondary:
              'Montrez que vous pouvez faire mieux, refaites cette révision séance tenante !',
          },
          {
            subtitle: 'Vous avez la capacité de faire mieux.',
            subtitle_secondary:
              'A vous de le démontrer. Refaites cette révision sans délai !',
          },
        ],
        // Level 2
        [
          {
            subtitle: 'Et si vous recommenciez ? ',
            subtitle_secondary: 'La perfection n’est pas impossible !',
          },
          {
            subtitle: 'Ne vous contentez pas de ce score.',
            subtitle_secondary:
              'Allez plus loin, recommencez cette révision illico!',
          },
          {
            subtitle: 'La demi-teinte n\'est pas pour vous.',
            subtitle_secondary:
              'Faites preuve d\'éclat, augmentez votre score en refaisant cette révision !',
          },
          {
            subtitle: 'Vous ne pouvez pas vous satisfaire de ce score.',
            subtitle_secondary: 'Recommencez cette révision sans tarder !',
          },
          {
            subtitle: 'Rien n\'est impossible avec de l\'obstination.',
            subtitle_secondary:
              'Recommencez cette révision pour atteindre les sommets !',
          },
        ],
        // Level 3
        [
          {
            subtitle: 'Pas mal du tout !',
            subtitle_secondary: 'Saurez-vous faire mieux ?',
          },
          {
            subtitle: 'Votre score est tout à fait correct. ',
            subtitle_secondary:
              'Et vous pouvez encore l\'améliorer n\'en doutez pas !',
          },
          {
            subtitle: 'Bel effort.',
            subtitle_secondary:
              'Obtenez au moins 4 étoiles lors de votre prochaine tentative !',
          },
          {
            subtitle: 'Votre performance tient la route.',
            subtitle_secondary:
              'Recommencez et tentez d\'obtenir au moins 4 étoiles !',
          },
          {
            subtitle: 'Votre score est acceptable.',
            subtitle_secondary:
              'A vous de prouver que vous pouvez faire mieux !',
          },
        ],
        // Level 4
        [
          {
            subtitle: 'Très bon travail !',
            subtitle_secondary: 'La perfection n’est pas loin !',
          },
          {
            subtitle: 'Jolie performance!',
            subtitle_secondary:
              'Pensez-vous être capable d\'obtenir 5 étoiles ?',
          },
          {
            subtitle: 'C\'est presque parfait.',
            subtitle_secondary:
              'Mais pas tout à fait! Faites encore un essai pour atteindre les 5 étoiles !',
          },
          {
            subtitle: 'Vous n\'êtes pas loin du sommet.',
            subtitle_secondary:
              'Encore un pas pour atteindre un score parfait !',
          },
          {
            subtitle: 'C\'est presque un sans faute.',
            subtitle_secondary:
              'Lancez-vous le défi d\'atteindre les 5 étoiles !',
          },
        ],
        // Level 5
        [
          {
            subtitle: 'Parfait !',
            subtitle_secondary: 'Passez à la suite !',
          },
          {
            subtitle: 'Vous êtes au top !',
            subtitle_secondary: 'Continuez dans la foulée !',
          },
          {
            subtitle: 'Rien à dire.',
            subtitle_secondary: 'C\'est excellent !',
          },
          {
            subtitle: 'La perfection existe.',
            subtitle_secondary: 'Vous venez de le prouver !',
          },
          {
            subtitle: 'Bravo !',
            subtitle_secondary: 'Vous êtes un / une élève 5 étoiles !',
          },
        ],
      ],
    },
  },
  homework: {
    homeworkIsSent: {
      message: {
        success: 'Votre devoir à bien été envoyé.',
        error:
          'Une erreur est survenue lors de l\'envoi de votre devoir. Merci de retenter ultérieurement. Si le problème persiste, vous avez la possibilité de nous transmettre votre devoir par mail à &{sendingErrorEmail} en précisant votre numéro d\'élève et l\'intitulé du devoir.',
      },
    },
    endPopin: {
      autocorrect: {
        true: 'Devoir terminé',
        false: 'Devoir en attente de correction',
      },
    },
    sendWork: {
      send: 'Envoyer mon devoir',
      addFile: 'Ajouter un fichier',
      selectWork: 'Sélectionner mon fichier',
      acceptedFiles: 'Formats acceptés: ',
      sortInfo:
        'Les fichiers seront envoyés en correction dans l\'ordre fourni.',
      sendFiles: 'Envoyer &{files} fichier(s)',
    },
  },
  challenge: {
    endPopin: {
      title: 'Défi terminé',
      winner: {
        true: {
          subtitle: 'Vous avez gagné !',
          subtitle_secondary: 'Continuez comme ça.',
        },
        false: {
          subtitle: 'Vous avez perdu.',
          subtitle_secondary: 'Vous ferez mieux la prochaine fois.',
        },
        null: {
          subtitle: '',
          subtitle_secondary:
            'En attente de votre adversaire pour déterminer le vainqueur.',
        },
      },
    },
  },
  agenda: {
    configuration: {
      message: {
        success: 'La configuration de votre agenda a bien été prise en compte.',
        error:
          'Votre agenda, tel que vous l\'avez personnalisé, ne vous permet pas d\'étudier dans les meilleures conditions. C\'est pourquoi nous vous conseillons d’augmenter votre durée d’étude par jour. Si vous souhaitez allonger la durée de votre formation, contactez votre coach. Vos modifications n\'ont pas été enregistrées.',
      },
      offer: {
        title: 'Ma formation',
        startDate: 'Début de ma formation',
        yearEnding: 'Année de passage de l\'examen',
        duration: 'Durée de la formation',
      },
      weeklySchedules: {
        title: 'Semaine type',
        workingOn: 'Je travaille le :',
        days: [
          'Lundi pendant',
          'Mardi pendant',
          'Mercredi pendant',
          'Jeudi pendant',
          'Vendredi pendant',
          'Samedi pendant',
          'Dimanche pendant',
        ],
      },
      internship: {
        title: 'Mes stages',
        studyDuringInternship: 'J\'étudie pendant mes périodes de stages',
        start: 'Début',
        end: 'Fin',
        weeklyHours: 'Nombre d\'heures hebdomadaire',
        message: 'Nom du stage',
        add: 'Ajouter un stage',
      },
      unavailability: {
        title: 'Mes périodes d\'indisponibilités',
        start: 'Début de la période',
        end: 'Fin de la période',
        add: 'Ajouter une période d\'indisponibilité',
        message: 'Nom de la période',
      },
      adjustment: {
        title: 'Ajustement',
        reBuildFromToday:
          'Planifier mes prochains cours à partir d’aujourd’hui',
      },
      submit: 'Demande de modifications',
      cancel: 'Annuler',
    },
  },
  onboarding_agenda: {
    stepArray: [
      'Durée',
      'Semaine',
      'Indisponibilité',
      'Spécialisations',
      'Cours optionnels',
    ],
  },
  internships: {
    title: 'Mes stages',
    documents: 'Mes documents',
    wordConvention: 'Convention au format .word',
    pdfConvention: 'Convention au format .pdf',
    guide: 'Guide de rédaction de mon CV et de ma lettre de motivation',
    table: {
      headers: [
        'Structure',
        'Durée',
        'Date de réception de convention',
        'Rapport',
      ],
      hours: 'heures en',
      weeks: 'semaines',
      from: 'Du',
      to: 'au',
      certificate: 'Attestation',
    },
  },
  report: {
    title: 'Mon bulletin de notes',
    downloadReport: 'Télécharger mon bulletin',
    result: 'Résultats',
    congratsDescription: {
      1: 'Restez positif et étudiez chaque jour pour atteindre vos objectifs!',
      2: 'Votre moyenne est correcte. Poursuivez vos efforts!',
      3: 'Bon travail! Votre implication est récompensée!',
      4: 'C\'est bien! Votre persévérance fait plaisir!',
      5: 'Très bon travail ! Vous êtes au top!',
    },
    reminder: [
      {
        title: 'Bravo !',
        color: 'green',
        svg: 'icon-checkmark',
        text: 'Vous êtes à jour dans votre programme de formation. N’oubliez pas votre objectif et poursuivez vos efforts !',
      },
      {
        title: 'Rappel !',
        color: 'orange',
        svg: 'icon-warningRound',
        text: 'Vous avez 1 devoir en retard. N’oubliez pas de le réaliser dans les meilleurs délais ! ',
      },
      {
        title: 'Attention !',
        color: 'orange',
        svg: 'icon-warningRound',
        text_start: 'Vous avez ',
        text_end:
          ' devoirs en retard. Il est encore temps de rattraper votre retard pour atteindre votre objectif !',
      },
      {
        title: 'Important !',
        color: 'red',
        svg: 'icon-warningRound',
        text: 'Vous n’avez pas réalisé de devoirs depuis maintenant 2 mois ! Il n’est pas encore trop tard pour reprendre votre formation et réaliser votre projet professionnel.',
      },
      {
        title: 'Important !',
        color: 'red',
        svg: 'icon-warningRound',
        text: 'Vous n’avez pas réalisé de devoirs depuis maintenant 3 mois ! N’oubliez pas votre engagement à suivre avec assiduité votre formation !',
      },
      {
        title: 'Important !',
        text: 'Vous n’avez pas réalisé de devoirs depuis maintenant 3 mois ! N’oubliez pas votre engagement à suivre avec assiduité votre formation !',
      },
      {
        title: 'Important !',
        text: 'Vous n’avez pas réalisé de devoirs depuis maintenant 3 mois ! N’oubliez pas votre engagement à suivre avec assiduité votre formation !',
      },
    ],
    homeworks: {
      summary: 'Résumé des devoirs',
      detail: 'Détail des devoirs',
      myAverage: 'Ma moyenne',
      offerAverage: 'La moyenne de votre promo est',
      completed: 'Complétés',
      notCompleted: 'Il en reste',
      toCorrect: 'à corriger',
      toDo: 'à réaliser',
      table: {
        headers: [
          '',
          'Coefficient',
          'Statut',
          'Date de réalisation',
          'Date de correction',
          'Correcteur',
          'Note',
          'Note finale',
          '',
        ],
      },
    },
  },
  badges: {
    rank: ['Bronze', 'Argent', 'Or', 'Diamant'],
    newBadge: [
      {
        type: [
          {
            first: 'Il vous reste ',
            second:
              ' rendez-vous avec votre coach pour atteindre le badge Argent.',
          },
          {
            first: '',
            second: '',
          },
          {
            first: 'Il vous reste ',
            second:
              ' chapitres à 4 étoiles ou plus pour atteindre le prochain niveau !',
          },
          {
            first: 'Il vous reste ',
            second:
              ' devoirs notés 14/20 ou plus pour atteindre le prochain niveau !',
          },
          {
            first: 'Il vous reste ',
            second: ' défis à remporter pour atteindre le prochain niveau !',
          },
          {
            first: 'Vous avez réalisé ',
            second:
              ' devoirs obligatoires. Une première étape franchie avec succés. Gardez le rythme !',
          },
        ],
      },
      {
        type: [
          {
            first: 'Il vous reste ',
            second: ' rendez-vous avec votre coach pour atteindre le badge Or.',
          },
          {
            first: '',
            second: '',
          },
          {
            first: 'Il vous reste ',
            second:
              ' chapitres à 4 étoiles ou plus pour atteindre le prochain niveau !',
          },
          {
            first: 'Il vous reste ',
            second:
              ' devoirs notés 14/20 ou plus pour atteindre le prochain niveau !',
          },
          {
            first: 'Il vous reste ',
            second: ' défis à remporter pour atteindre le prochain niveau !',
          },
          {
            first: 'Vous avez réalisé ',
            second:
              ' devoirs obligatoires. Vous êtes à mi-parcours de votre formation. Votre assiduité est récompensée !',
          },
        ],
      },
      {
        type: [
          {
            first: 'Il vous reste ',
            second:
              ' rendez-vous avec votre coach pour atteindre le badge Diamant !',
          },
          {
            first: '',
            second: '',
          },
          {
            first: 'Il vous reste ',
            second:
              ' chapitres à 4 étoiles ou plus pour atteindre le prochain niveau !',
          },
          {
            first: 'Il vous reste ',
            second:
              ' devoirs notés 14/20 ou plus pour atteindre le prochain niveau !',
          },
          {
            first: 'Il vous reste ',
            second: ' défis à remporter pour atteindre le prochain niveau !',
          },
          {
            first: 'Vous avez réalisé ',
            second:
              ' devoirs obligatoires. Vous approchez à grand pas de votre objectif ! Ne lachez rien !',
          },
        ],
      },
      {
        type: [
          {
            first:
              'Vous avez honoré vos premiers rendez-vous avec votre coach qui va continuer à vous accompagner tout au long de votre formation !',
            second: '',
          },
          {
            first: '',
            second: '',
          },
          {
            first: 'Vous avez atteint une moyenne de ',
            second: ' . Vous êtes au sommet !',
          },
          {
            first: 'Vous avez atteint une moyenne de ',
            second: ' à vos devoirs. Un seul mot s\'impose : Félicitations !',
          },
          {
            first: 'Vous avez gagné tous vos défis ! ',
            second: 'Le titre de Fighter vousn va parfaitement !',
          },
          {
            first:
              'Vous avez réalisé tous les devoirs de votre programme obligatoire ',
            second: ' . Félicitations pour tous vos efforts !',
          },
        ],
      },
    ],
    description: {
      single: [
        'rendez-vous coach honoré sur',
        'cours terminé sur',
        'chapitre à 4 étoiles ou plus sur',
        'devoir noté 14 / 20 ou plus sur',
        'défi remporté sur',
        'devoir réalisé sur',
        'contenu pédagogique sur'
      ],
      plural: [
        'rendez-vous coach honorés sur',
        'cours terminés sur',
        'chapitres à 4 étoiles ou plus sur',
        'devoirs notés 14 / 20 ou plus sur',
        'défis remportés sur',
        'devoirs réalisés sur',
        'contenus pédagogiques sur'
      ],
      completed: [
        'rendez-vous coach honorés',
        'cours terminés',
        'chapitres à 4 étoiles ou plus',
        'devoirs notés 14 / 20 ou plus',
        'défis remportés',
        'Tous les devoirs réalisés',
      ],
      contents : [
        '25 % du parcours complété : Badge bronze',
        '50 % du parcours complété : Badge argent',
        '75 % du parcours complété : Badge or',
        '100 % du parcours complété : Badge diamant',
      ]
    },
    threshold: {
      single: [
        'rendez-vous coach à honorer',
        'cours à terminer',
        'chapitre à 4 étoiles ou plus',
        'devoir noté 14 / 20 ou plus',
        'défi à remporter',
        'devoir à réaliser',
        'contenu pédagogique à réaliser',
      ],
      plural: [
        'rendez-vous coach à honorer',
        'cours à terminer',
        'chapitres à 4 étoiles ou plus',
        'devoirs notés 14 / 20 ou plus',
        'défis à remporter',
        'devoirs à réaliser',
        'contenus pédagogiques à réaliser',
      ],
    },
  },
  input: {
    required: 'Ce champs est requis.',
    email: 'Veuillez saisir une adresse email valide',
    tel: 'Veuillez saisir un numéro de téléphone valide',
    date: 'Veuillez saisir une date au format JJ/MM/AAAA',
    year: 'Veuillez saisir une année au format AAAA',
    number: 'Veuillez saisir un chiffre valide',
    studentNumber:
      'Veuillez entrer votre numéro d\'élève : 1 lettre, 6 chiffres, 1 lettre. Exemple : F123456Z.',
  },
  error: {
    default: 'Erreur lors du chargement de la page',
    noChapter:
      'Il semblerait que ce cours ne contienne aucun chapitre pour le moment !',
    noSubchapter:
      'Il semblerait que ce chapitre ne contienne aucun sous-chapitre pour le moment !',
    noHomework:
      'Chanceux, chanceuse, il semblerait qu\'aucun devoir ne soit à faire pour ce cours !',
  },
  toast: {
    newContent: {
      title: '🚀 Nouvelles fonctionnalités disponibles !',
      text: 'La page rechargera dans quelques secondes pour effectuer la mise à jour.',
    },
    offline: {
      title: '🙈 Pas de réseau ?',
      text: 'Votre application est maintenant disponible, même lorsque vous n\'avez plus de connexion !',
    },
    test: {
      title: 'Notification de test',
      text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ut magnam fuga obcaecati possimus ipsum odio sit architecto officiis incidunt dignissimos rem, autem, quis animi at eius iste ratione impedit sed?',
    },
  },
  examinations: {
    title: 'Mes examens',
    testAccount:
      'Vous êtes sur un compte d’essai. Aucune information disponible sur les examens.',
    phone: 'Téléphone',
    website: 'Voir le site',
    titleAttachedFiles: 'Mes pièces justificatives',
    list: {
      title: 'Mes résultats',
      year: 'Année',
      average: 'Moyenne',
      documents: 'Documents',
      status: 'Statut',
      certification: 'Attestation de réussite',
      success: 'Obtenu',
      failure: 'Ajourné',
      noCertification: 'Aucun document',
    },
    popin: {
      add: 'Ajouter un résultat',
      taken: 'Examen passé :',
      takingYear: 'Année de passage :',
      passed: 'Examen obtenu :',
      average: 'Moyenne obtenue :',
      yourAverage: 'Votre moyenne',
      averageRequired: 'Veuillez saisir votre moyenne',
      file: 'Copie de mon diplôme ou relevé de notes :',
      addFile: 'Ajouter un fichier',
      comment: 'Des remarques? Confiez-les nous :',
      yourComment: 'Votre réponse',
      doAdd: 'Valider',
      yes: 'Oui',
      no: 'Non',
      examAddedNotTaken: 'Votre réponse a été enregistrée.',
      examAddedNotPassed: 'Ne vous découragez pas !',
      examAddedPassed: 'Résultat ajouté !',
      closeWindow: 'Fermer la fenêtre',
      contact: 'Contactez-moi',
      notTaken:
        'Merci d’avoir renseigné ce formulaire. Toute l’équipe du Centre Européen de Formation reste à votre disposition pour vous accompagner dans votre formation.',
      notPassed:
        'Toute l’équipe du Centre Européen de Formation est à vos côtés pour vous accompagner. Vous avez besoin de faire le point avec votre coach? Cliquez sur « Contactez-moi ». Vous serez rappelé(e) dans les meilleurs délais.',
      doPassed:
        'Bravo! Toute l’équipe du Centre Européen de Formation vous félicite pour l’obtention de votre diplôme !',
      sendingError:
        'Une erreur est survenue lors de l\'envoi du devoir. Veuillez réessayer plus tard.',
      contactError:
        'Une erreur est survenue lors de la demande de contact. Veuillez réessayer plus tard.',
      contactSuccess: 'Votre demande de contact a bien été envoyée.',
      passedAverageError: 'L\'examen a été obtenu mais la moyenne est inférieure à 10.',
      notPassedAverageError: 'L\'examen n\'a pas été obtenu mais la moyenne est supérieure à 10.',
      preRegistrationText: {
        first: 'Formulaire de demande de pré-inscription à l’examen du CAP',
        second: 'Vous souhaitez passer le CAP à la session 2024 ? Pour cela, nous mettons à votre disposition un formulaire afin de permettre à nos équipes de vous pré-inscrire à l’examen auprès de votre académie de référence.',
        third: 'Ce formulaire sera accessible jusqu’au 17/11/2023, n’hésitez pas à consulter la liste des informations et documents à fournir disponible ',
        fourth: 'Un récapitulatif est disponible à la fin du formulaire, pensez à bien le relire attentivement afin d’éviter les erreurs ou les oublis.',
        fifth: 'Vous pouvez également retrouver toutes les informations relatives aux inscriptions directement sur votre page « Mes examens ». Pensez à bien vérifier les dates d’ouverture du portail de votre académie.',
        sixth: 'Il s’agit de la première étape importante pour le passage de l’examen, restez attentif durant cette période cruciale !',
        seventh: 'La Team CEF',
        button: 'Remplir le formulaire'
      }
    },
  },
  banners: {
    bannerPecInscriptionForm: {
      first: 'La période des préinscriptions à l’examen du CAP approche à grands pas ! Dans le cadre de votre prise en charge, vous avez l’obligation de vous inscrire. Si vous souhaitez passer l’examen à la session ',
      second: ', n’attendez plus, cliquez ',
      third: '. Nous pourrons ainsi vérifier les informations saisies !',
    },
    bannerPreInscriptionNotPecCpf: {
      first: 'Vous souhaitez passer votre examen du CAP en ',
      second: ' ? Le CEF vous accompagne dans vos démarches. L’école vous propose des séances en visio afin de vous guider dans la pré-inscription à l’examen national. Cliquez-ici pour en ',
      link: 'https://www.centre-europeen-formation.fr/notices-academiques/'
    }
  },
  molecules: {
    here: 'ici',
    more: 'savoir plus',
    manyFiles: 'Plusieurs fichiers peuvent être ajoutés',
  },
  preRegistrationExamination: {
    title: 'Je prépare ma pré-inscription à l’examen',
    origin: {
      title: 'Origine de la session précédente',
      field1: 'Est-ce votre première inscription au CERTIFICAT D’APTITUDE PROFESSIONNELLE ?',
      field2: 'Vous êtes-vous présenté(e) au CERTIFICAT D’APTITUDE PROFESSIONNELLE à la session de cette année dans la même académie ?',
      field3: 'Indiquez votre numéro redoublant :',
    },
    identity: {
      title: 'Identité',
      field1: 'Titre de civilité :',
      field2: {
        first: 'Nom de famille :',
        second: '(= nom de naissance)'
      },
      field3: {
        first: 'Nom d\'usage :',
        second: '(= nom marital)'
      },
      field4: {
        first: 'Prénom(s) :',
        second: 'Renseignez bien tous les prénoms présents sur votre pièce d\'identité. Exemple : Juliette Odile Marguerite.'
      },
      field5: 'Nationalité :',
      field6: 'Date de naissance :',
      field7: 'Commune de naissance :',
      field8: 'Département de naissance (exemple 059 pour le Nord) :',
      field9: 'Arrondissement de naissance (exemple 05 pour PARIS 5ème) :',
      field10: {
        first: 'Pays de naissance :',
        second: 'Ne rien sélectionner sauf si vous êtes né(e) à l\'étranger ou dans les DOM-TOM. Dans ce cas,\n' +
          'renseignez 099 dans le champ département de naissance.'
      },
      field11: 'Photocopie recto-verso de votre pièce d\'identité (passeport ou carte d\'identité) en cours de validité :',
      field12: 'Photocopie de votre attestation de recensement ou photocopie de votre certificat individuel de participation à la journée défense et citoyenneté (JDC, anciennement JAPD) :',
    },
    contactInformation: {
      title: 'Coordonnées',
      field1: 'Adresse (exemple : 19 rue Nicolas Appert) :',
      field2: 'Suite adresse (exemple : appt 3) :',
      field3: 'Lieu dit :',
      field4: {
        first: 'Code postal (exemple : 59000) :',
        second: 'L\'adresse que vous renseignez doit se trouver dans le même département que l\'académie au sein de laquelle vous passerez l\'examen.'
      },
      field5: 'Localité (exemple : Lille) :',
      field6: 'Téléphone mobile (exemple : 0605040302) :',
      field7: 'Adresse électronique (exemple : jdupont@gmail.fr) :',
      field8: 'Confirmation de l\'adresse électronique :'
    },
    otherPersonalData: {
      title: 'Autres données personnelles',
      field1: 'Êtes-vous en situation de handicap ?',
      field2: 'Photocopie du formulaire de demande d\'aménagement d\'épreuves complété et signé par vous : ',
      text1: 'La décision sur le type d\'aménagement dont vous pourrez bénéficier sera prise par les autorités en charge de l\'examen, en concertation avec les instances médicales.'
    },
    passageForm: {
      title: 'Forme de passage',
      text1: '<strong>Forme progressive :</strong> Elle vous concerne si vous souhaitez étaler le passage du CAP sur deux années. Vous devrez alors choisir de vous inscrire aux seules épreuves que vous souhaitez passer à la session de l\'examen de cette année.',
      text2: '<strong>Forme globale :</strong> Elle vous concerne si vous souhaitez obtenir le CAP sur une seule session.',
      text3: '<u>Nous vous conseillons de choisir la forme globale.</u> Si vous choisissez la forme progressive, vous ne pourrez pas changer d\'avis et ne serez convoqué(e) qu\'aux seules épreuves que vous aurez choisies à l\'inscription. La forme globale vous permet donc de vous laisser la porte ouverte à toutes les épreuves.',
      text4: 'Dans le cadre d\'une formation à distance, vous pouvez être dispensé(e) de l\'épreuve d\'EPS. Nous ne vous préparons pas au passage de cette épreuve. Ne décidez de la passer que si vous possédez un excellent niveau sportif. Si vous obtenez une note inférieure à la moyenne, vous risquez de perdre des points.',
      text5: 'Ne décidez de passer l\'épreuve d\'Arts appliqués et cultures artistiques que si vous êtes particulièrement à l\'aise en dessin et disposez d\'une bonne culture artistique. Cette épreuve étant facultative, seuls les points au-dessus de 10 seront pris en compte.',
      alert1: 'Attention : si vous désirez passer toutes les épreuves lors de la session de\n' +
               '                l\'examen de',
      alert2:', nous vous invitons à choisir la "forme globale" pour le passage des épreuves de\n' +
               '                votre examen.',
      field1: {
        first: 'Sous quelle forme souhaitez-vous passer votre examen ?',
        options: {
          0: {
            value: 'globale',
            text: 'Forme globale'
          },
          1: {
            value: 'progressive',
            text: 'Forme progressive'
          },
        }
      },
      field2: 'Sélectionnez la ou les épreuves que vous souhaitez passer pour la session de cette année'
    },
    noteBenefit: {
      title: 'Bénéfices ou reports de notes',
      field1: 'Possédez-vous des bénéfices ou des reports de notes ?',
      field2: 'Année de passage du CAP auquel vous vous êtes précédemment présenté(e) :',
      field3: {
        first: 'Photocopie du relevé de notes de la session du CAP auquel vous vous êtes précédemment présenté(e) :',
        button: 'Joindre un fichier'
      },
      field4: 'Indiquez les épreuves pour lesquelles vous désirez un bénéfice de note :',
      field5: 'Indiquez les épreuves pour lesquelles vous désirez un report de note :',
      field6: 'Indiquez les épreuves pour lesquelles vous désirez un bénéfice de note :',
      field7: 'Indiquez les épreuves pour lesquelles vous désirez un report de note :',
      text1_a: 'Lorsque vous avez été ajourné(e), vous pouvez garder le bénéfice des notes de certaines épreuves (cette possibilité est en général inscrite, le cas échéant, sur le relevé de notes), vous pouvez demander à bénéficier d\'une, de plusieurs ou de toutes ces notes selon votre choix.',
      text1_b : 'Pour l\'examen du CAP vous pouvez également conserver les notes inférieures à 10/20. Vous bénéficiez dans ce cas d\'un report de note.',
      text1_c : 'Ce bénéfice ou ce report est possible les cinq années suivant l\'année d\'obtention de la note. Attention, si vous décidez de renoncer à un bénéfice ou à un report, ce renoncement est définitif pour toutes les sessions à venir.',

    },
    testExemption: {
      title: 'Dispenses d\'épreuves',
      field1: 'Possédez-vous des dispenses d\'épreuves ?',
      field2: 'Bénéficiez-vous d\'une dispense pour certaines épreuves professionnelles du CAP AEPE ?',
      field3: 'Sélectionner le ou les diplômes qui vous permettront d\'obtenir une dispense :',
      field5: {
        first: 'Photocopie de votre relevé de notes à l\'examen ou de votre diplôme :',
        second: 'Plusieurs fichiers peuvent être ajoutés'
      },
      field6: 'Bénéficiez-vous d\'une dispense pour l\'épreuve de langue vivante ?',
      field7: 'Appellation complète du diplôme ou du titre :',
      field8: 'Année de passage du diplôme ou du titre :',
      field9: 'Photocopie de votre relevé de notes :',
      field10: {
        first: 'Bénéficiez-vous d\'une dispense pour les matières générales ?',
        second: 'Si vous êtes titulaire d\'un CAP, BEP ou d\'un diplôme équivalent ou supérieur, vous êtes dispensé(e) des matières du domaine général.',
        third: 'L\'épreuve de Prévention Santé et Environnement (PSE) devient une matière générale obligatoire à\n' +
          'partir de la session 2021. Même si vous bénéficiez d\'une dispense pour les autres matières générales,\n' +
          'vous serez obligé(e) de passer l\'épreuve de PSE pour valider votre examen.'
      },
      field11: 'Appellation complète du diplôme ou du titre:',
      field12: 'Année de passage du diplôme ou du titre :',
      field13: 'Photocopie de votre relevé de notes à l\'examen ou de votre diplôme :',
      text1: '* Pour être dispensé(e) de l\'EP1 et l\'EP2, vous devez obligatoirement être titulaire du BEP ASSP.\n' +
        '            Le BAC PRO ASSP ne dispense pas de ces unités, s\'il n\'est pas obtenu en continuité du BEP ASSP."<br/><br/>\n' +
        '            Faites bien attention à l\'année d\'obtention de votre diplôme ou de votre titre. Exemple : Seuls les BEP ASSP\n' +
        '            obtenus depuis 2013 permettent une dispense d\'épreuves professionnelles.<br/><br/>\n' +
        '            "Attention, même si vous pouvez bénéficier d\'une dispense pour certaines épreuves professionnelles, vous\n' +
        '            devrez réaliser 5 semaines de stage par épreuve professionnelle restante.\n' +
        '            Exemple : Vous possédez un BEPA Services aux personnes et êtes dispensé(e) d\'EP1 et d\'EP2. Vous devrez donc\n' +
        '            justifier de 5 semaines de stage pour l\'EP3.',
      text2: 'La dispense de l\'épreuve de langue vivante (Anglais, Allemand ou Espagnol) s\'applique si vous êtes titulaire\n' +
        '            d\'un diplôme contenant une de ces langues vivantes. Si vous possédez un diplôme de niveau V sans une de ces\n' +
        '            langues, vous serez dispensé(e) des matières générales, à l\'exception de la langue vivante à passer.',
    },
    mandatoryTestLanguage: {
      title: 'Epreuve obligatoire de langue vivante',
      field1: 'Choisissez la langue dans laquelle vous souhaitez passer l\'épreuve obligatoire de langue vivante :',
      text1: 'Attention, nos cours ne vous préparent qu\'à l\'Anglais.'
    },
    up3MandatoryTest: {
      title: 'Épreuve obligatoire EP3 : Présenter un projet d\'accueil réel',
      field1: 'Êtes-vous assistant(e) maternel(le) agréé(e) ou garde d’enfants à domicile ?',
      field2: {
        first: 'Souhaitez-vous présenter un projet d\'accueil réel qui prend appui sur votre contexte d\'intervention\n' +
          '              professionnel du domicile ?',
        second: 'Si vous cochez « Oui », vous devrez déposer un dossier de 5 pages maximum à la date fixée par le rectorat. En l\'absence de dépôt du projet d\'accueil à temps, vous ne serez pas autorisé(e) à passer l\'épreuve.'
      }
    },
    optionalTest: {
      title: 'Épreuves facultatives',
      field1: 'Désirez-vous passer l\'épreuve de langue vivante ?',
      field2: 'Désirez-vous passer l\'épreuve d\'EPS ?',
      field3: 'Désirez-vous passer l\'épreuve Arts appliqués et cultures artistiques ?',
      optionalField1: 'Choisissez la langue dans laquelle vous souhaitez passer l\'épreuve obligatoire de langue vivante :',
      text1: 'Dans le cadre d\'une formation à distance, vous pouvez être dispensé(e) de l\'épreuve d\'EPS. Nous ne vous préparons pas au passage de l\'épreuve d\'EPS. Ne décidez de la passer que si vous possédez un excellent niveau sportif. Si vous obtenez une note inférieure à la moyenne, vous risquez de perdre des points.',
      text2: 'Cette épreuve étant facultative, seuls les points au-dessus de 10 seront pris en compte. ',
      text3: 'Ne décidez de passer cette épreuve que si vous êtes particulièrement à l\'aise en dessin et disposez d\'une bonne culture artistique.',
      optionalText1: 'Attention, nos cours ne vous préparent qu\'à l\'Anglais'
    },
    optionalTestLanguage: {
      title: 'Epreuve facultative de langue vivante',
      field1: 'Choisissez la langue dans laquelle vous souhaitez passer l\'épreuve facultative de langue vivante :',
      text1: 'Attention, nos cours ne vous préparent qu\'à l\'Anglais.'
    },
    traineeship: {
      title: 'Stages',
      field1: 'Avez-vous réalisé tout ou partie de ces semaines de stage / bénéficiez-vous d\'une expérience professionnelle dans un ou plusieurs secteurs du ',
      field2: 'Photocopie(s) attestation(s) de stage ou expérience(s) professionnelle(s) complétée(s) et signée(s) : ',
      text3: 'Plusieurs fichiers peuvent être ajoutés.',
      text4: 'Vous devez uniquement nous joindre les documents fournis par votre académie.',
      text5: 'Si vous cochez « Oui », cliquez sur le bouton « Joindre un fichier » pour nous faire parvenir tous vos documents.',
      text6: 'Si vous cochez « Non » ou que vous n’avez pas encore réalisé toutes vos semaines de stage, vous devrez nous transmettre vos attestations de stage avant la date imposée par votre académie. Vous nous transmettrez vos attestations depuis la thématique « Mes examens » de votre messagerie.',
      domaines: {
        1: {
          name: 'CAP AEPE',
          text1: 'Pour le passage de votre examen, vous devez réaliser <strong>14 semaines de stage obligatoires</strong> avec des enfants de 0 à 6 ans : dont 1 période obligatoire avec les 0 à 3 ans et dans deux contextes professionnels distincts.',
          text2: '<strong>IMPORTANT : </strong>Certaines académies demandent d\'avoir terminé les stages au moment de l\'inscription (fin d\'année en cours), d\'autres au printemps de l\'année de passage de l\'examen. Contactez votre académie pour vous assurer de la date qui vous concerne.',
          text3: ', les semaines de stage que vous avez réalisées en 2020 peuvent être acceptées.',
        },
        4: {
          name: 'CAP Esthétique',
          text1: 'Pour passer l’examen du CAP vous devez justifier de 12 semaines de stage obligatoires avant le mois de mars.',
        },
        8: {
          name: 'CAP Cuisine',
          text1: 'Pour passer l’examen du CAP vous devez justifier de 14 semaines de stage obligatoires avant le mois de mars.',
        },
        9: {
          name: 'CAP Coiffure',
          text1: 'Pour passer l’examen du CAP vous devez justifier de 12 semaines de stage obligatoires avant le mois de mars.',
        },
        32: {
          name: 'CAP Pâtissier',
          text1: 'Pour vous inscrire à l’examen du CAP vous devez justifier de 14 semaines de stage obligatoires.',
        },
        44: {
          name: 'CAP Boulanger',
          text1: 'Pour passer l’examen du CAP vous devez justifier de 16 semaines de stage obligatoires avant le mois de mars.',
        },
      }
    },
    latestInformations: {
      title: 'Dernières informations',
      field1: 'Souhaitez-vous communiquer vos résultats aux sociétés de droit privé ?',
      field2: 'Souhaitez-vous communiquer vos résultats aux collectivités territoriales ?',
      text1: 'Publication par la presse ou sur les sites internet de sociétés de droit privé. Nous vous conseillons de cocher « Oui ». Si vous cochez  « Non », vous devrez attendre que votre académie vous envoie votre relevé de notes. ',
      text2: 'Publication aux collectivités territoriales en vue d\'éventuelles félicitations. Nous vous conseillons de cocher « Oui ».',
      text3: 'Le fait d\'accepter la communication des résultats permet de les obtenir plus facilement et plus rapidement !',
    },
    recap: {
      title: 'Récapitulatif',
      text1: 'Veuillez trouver ci-dessous un récapitulatif de votre formulaire, avant de nous le transmettre pour vérification, nous vous invitons à prendre le temps de relire les informations saisies et apporter une modification si nécessaire. ',
      text2: 'Nous vous rappelons que la complétude du formulaire ne signifie pas que vous êtes pré-inscrit à l’examen du CAP.',
      buttonSave: 'Enregistrer les modifications en cours',
      buttonSend: 'Envoyer mon formulaire'
    },
    popinFinal: {
      text1: 'Nous avons bien reçu votre formulaire !',
      text2: 'Nos équipes sont en charge de vérifier les informations que vous avez saisies, et reviendront vers vous si des éléments sont manquants, restez actifs sur votre CEF Learning.',
      text3: 'Vous recevrez prochainement une version validée de votre formulaire par mail.',
      text4: 'Rassurez-vous, s’il manque des informations, nos équipes vous le signaleront également !',
      text5: 'À très bientôt !',
      text6: 'La Team CEF',
    }
  },
  cgu: {
    titre: 'Mes conditions générales d\'utilisation',
    soustitre: 'Conditions générales d’utilisation du site eleve.centre-europeen-formation.fr',
    articles: {
      1: {
        titre: 'Objet',
        content: {
          text1: '<strong>1.1</strong> Les présentes (« CGU ») ont pour objet de fixer et préciser les modalités dans lesquelles le Site est mis à disposition de tout Utilisateur . Elles s’appliquent et sont pleinement opposables à tout Utilisateur lors de sa visite et utilisation des fonctionnalités du Site.',
          text2: '<strong>1.2</strong> Tout Utilisateur déclare avoir lu et doit accepter sans la moindre réserve les CGU pour pouvoir utiliser le Site Internet et ses services/fonctionnalités. Les CGU sont opposables à tous Utilisateurs à compter de leur acceptation et ont valeur contractuelle entre eux et le CENTRE EUROPEEN DE FORMATION (« CEF »).',
          text3: '<strong>1.3</strong> La mise à jour/modification des CGU pourra intervenir à l’initiative du CEF à tout moment sans avoir à en référer préalablement aux Utilisateurs. Les CGU opposables à l’Utilisateur sont celles en vigueur et éditée sur le Site. Tout manquement aux CGU pourra justifier le blocage de l’accès de l’Utilisateur fautif aux services/fonctionnalités sur le Site.',
        }
      },
      2: {
        titre: 'Mentions légales',
        content: {
          text1: 'L’édition du site <i>eleve.centre-europeen-formation.fr</i> est assurée par la Société Centre Européen de Formation, SAS Société par actions simplifiée au capital de 40000 euros dont le siège social est situé au 19/21 rue Nicolas Appert 59650 Villeneuve d’Ascq. RCS LILLE Métropole 435 086 285',
          text2: 'Le Directeur de la publication est Monsieur Bernard Canetti.',
          text3: 'Le site www.eleve.centre-europeen-formation.fr est hébergé par la société DIGDEO, BP 104 92604 Asnières sur Seine',
        }
      },
      3: {
        titre: 'Définitions',
        content: {
          text1: 'La présente clause a pour objet de définir les différents termes essentiels du contrat :',
          text2: 'Utilisateur : ce terme désigne toute personne qui utilise le site ou l’un des services proposés par le site.',
          text3: 'Contenu utilisateur : ce sont les données transmises par l’Utilisateur au sein du site.',
          text4: 'Membre : l’Utilisateur devient membre lorsqu’il est identifié sur le site.',
          text5: 'Identifiant et mot de passe : c’est l’ensemble des informations nécessaires à l’identification d’un Utilisateur sur le Site. L’identifiant et le mot de passe permettent à l’Utilisateur d’accéder à des services réservés aux membres du site. Le mot de passe est confidentiel.',
          text6: 'Site ou Site Internet : désigne le site internet accessible à l’adresse URL : www.eleve.centre-europeen-formation.fr',
        }
      },
      4: {
        titre: 'Accès au service',
        content: {
          text1: 'Le site permet à l’Utilisateur un accès aux services suivants :',
          text2: {
            content: '<ul>\n' +
              '          <li>formation en ligne ;</li>\n' +
              '          <li>accès à du contenu vidéo éducatif ;</li>\n' +
              '          <li>mise en relation de personnes (élèves ou administration) ; chat et discussion</li>\n' +
              '          <li>publication de contenus</li>\n' +
              '        </ul>'
          },
          text3: 'Le site est accessible en tout lieu à tout Utilisateur ayant souscrit à un contrat de formation auprès de la société Centre Européen de Formation. Pour se connecter au site, l’Utilisateur a besoin d’un accès à Internet. Tous les frais supportés par l’Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.',
          text4: 'Le site met en œuvre tous les moyens mis à sa disposition pour assurer un accès de qualité à ses services. L’obligation étant de moyens, le site ne s’engage pas à atteindre ce résultat.',
          text5: 'Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du réseau ou du serveur n’engage pas la responsabilité de <i>eleve.centre-europeen-formation.fr</i>.',
          text6: 'L’accès aux services du site peut à tout moment faire l’objet d’une interruption, d’une suspension, d’une modification sans préavis pour une maintenance ou pour tout autre cas. L’Utilisateur s’oblige à ne réclamer aucune indemnisation suite à l’interruption, à la suspension ou à la modification du présent contrat.',
          text7: 'L’Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse info@cenef.fr',
          text8: 'L’Utilisateur doit se connecter sur le Site Internet au moyen de l’identifiant et mot de passe de connexion.',
          text9: 'Les Identifiants et mots de passe sont strictement personnels et il appartient à cet égard à l’Utilisateur d’assurer leur parfaite confidentialité. Ils sont créés, gérés, modifiés et utilisés sous sa seule responsabilité. CEF ne pourra être responsable de toutes conséquences liées à une divulgation, délibérée ou non, des Identifiant/mot de passe auprès de tout tiers. La Société ne supportera aucune responsabilité du fait des conséquences de leur utilisation frauduleuse par un tiers (notamment en cas d’édition de contenu par l’usurpateur qui contreviendrait à la loi ou en cas de conséquences sur le suivi de la formation par l’Utilisateur), à charge pour l’Utilisateur de se retourner contre l’usurpateur. Dans l’hypothèse où l’Utilisateur aurait connaissance de ce qu’une autre personne accède ou peut accéder à son Compte Utilisateur via ses identifiants et mot de passe, il changera ces derniers et en informera le CEF sans délai.',
        }
      },
      5: {
        titre: 'Social learning',
        content: {
          text1: 'Pour faciliter l’apprentissage de ses utilisateurs, le Site propose des fonctions dites de « social learning » :',
          text2: {
            content: '<ul>\n' +
              '          <li>Messagerie communautaire</li>\n' +
              '          <li>Lancement de défis. Défier un autre utilisateur de la communauté sur la réalisation d’un exercice. </li>\n' +
              '          <li>Classement promotion</li>\n' +
              '        </ul>'
          },
          text3: 'La fonction défi est ouverte par défaut à la première connexion de l’Utilisateur. Ce dernier à la possibilité à tout le moment, de la modifier et de sortir de la fonction défi.',
        }
      },
      6: {
        titre: 'Publication par l’Utilisateur',
        content: {
          text1: 'Le site permet aux membres de publier et partager du contenu, de dialoguer avec un ou plusieurs autres Utilisateurs du site par messagerie. Dans ses publications ou messages, l’Utilisateur s’engage à respecter les règles de la Netiquette et les règles de droit en vigueur.',
          text2: 'L’Utilisateur s’engage à se conformer strictement à un comportement adéquate et modéré dans le cadre des contenus qu’il édite sur le Site, en particulier en cas d’échanges avec un/d’autre(s) élève(s).',
          text3: 'L’Utilisateur est seul responsable de tout contenu qu’il édite, notamment en cas de propos contraires à la loi ou à l’ordre public tels que des propos diffamatoires, injurieux, raciste, xénophobe ou antisémite. L’Utilisateur s’interdit de faire tout prosélytisme religieux.',
          text4: 'Tout comportement ou contenu contraire à l’ordre public ou aux lois fera l’objet d’une interdiction pour l’Utilisateur de poursuivre son parcours de formation. Son contrat de formation pourra être résilié sans préavis et sans indemnité à la discrétion du CEF.',
          text5: 'CEF est hébergeur des contenus des utilisateurs et sa responsabilité ne peut être engagée que dans les conditions de l’article -6 de la loi LCEN du 21 janvier 2004.',
          text6: 'Le CEF pourra supprimer tout contenu qu’il estimerait non-conforme au comportement exigé sans avoir à en référer à l’utilisateur l’ayant édité ou si un contenu non-conforme lui aurait été notifié par tout tiers dans le cadre des dispositions légales LCEN.',
          text7: 'Le membre reste titulaire de l’intégralité de ses droits de propriété intellectuelle. Mais en publiant une publication sur le site, il cède à la société éditrice le droit non exclusif et gratuit de représenter, reproduire, adapter, modifier, diffuser et distribuer sa publication, directement ou par un tiers autorisé, dans le monde entier, sur tout support (numérique ou physique), pour la durée de la propriété intellectuelle. Le Membre cède notamment le droit d’utiliser sa publication sur internet et sur les réseaux de téléphonie mobile.',
        }
      },
      7: {
        titre: 'Propriété intellectuelle',
        content: {
          text1: 'Il est formellement interdit de recopier intégralement ou partiellement un texte, des photos et le travail d\'autrui sans faire référence à la source d\'origine. Si une reproduction illégale devait être constatée, le conseil de discipline se saisira automatiquement du dossier et appliquera une sanction pouvant aller jusqu\'à l\'exclusion de la formation.',
          text2: 'De même, si un cas de triche ou de reproduction du travail d\'un autre Stagiaire était avéré, le conseil de discipline se saisira automatiquement du dossier et appliquera une sanction pouvant aller jusqu\'à l\'exclusion de la formation.',
        }
      },
      8: {
        titre: 'Données personnelles',
        content: {
          text1: 'Les informations demandées à première connexion au site sont nécessaires et obligatoires pour la création du profil de l’Utilisateur. L’adresse électronique et le numéro de téléphone de l’Utilisateur pourront être utilisées par le site pour l’administration et la gestion du service pédagogique.',
          text2: 'Afin d’assurer un service pédagogique de qualité et une bonne communication, le site communiquera via email, push notification (via le navigateur) ou appel téléphonique.',
          text3: 'Le CEF doit collecter et traiter certaines données personnelles de l’Utilisateur. L’Utilisateur peut prendre connaissance de la politique de protection des données personnelles sur le site internet du CEF à l’adresse suivante : ',
          text4: '<a href=\'https://www.centre-europeen-formation.fr/politique-de-protection-de-vos-donnees-personnelles/\' target=\'_blank\' rel=\'noopener noreferrer\'>https://www.centre-europeen-formation.fr/politique-de-protection-de-vos-donnees-personnelles/</a>',
        }
      },
      9: {
        titre: 'Utilisation des cookies',
        content: {
          text1: 'Afin proposer aux Utilisateurs un contenu qualitatif et en rapport avec leurs préoccupations et leurs attentes, le Centre Européen de Formation analyse les données issues du site <i>eleve.centre-europeen-formation.fr</i> via Google Analytics.',
          text2: 'Un cookie est un fragment de texte qui est ajouté à la mémoire de votre navigateur par un site web, permettant au site de stocker des informations sur votre ordinateur et de les récupérer plus tard. Comme expliqué ci-dessus, ce site utilise des cookies afin de pouvoir améliorer votre expérience de navigation.',
          text3: 'Tous les navigateurs Internet permettent d’activer ou de désactiver l’utilisation de cookies. Si vous ne souhaitez pas accepter les cookies, veuillez les désactiver avant de continuer à utiliser ce site.',
        }
      },
      10: {
        titre: 'Responsabilité et force majeure',
        content: {
          text1: 'Les sources des informations diffusées sur le site sont réputées fiables. Toutefois, le site se réserve la faculté d’une non-garantie de la fiabilité des sources. Les informations données sur le site le sont à titre purement informatif. Ainsi, l’Utilisateur assume seul l’entière responsabilité de l’utilisation des informations et contenus du présent site.',
          text2: 'L’Utilisateur s’assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite.',
          text3: 'L’Utilisateur assume les risques liés à l’utilisation de son identifiant et mot de passe. Le site décline toute responsabilité.',
          text4: 'Tout usage du service par l’Utilisateur ayant directement ou indirectement pour conséquence des dommages doit faire l’objet d’une indemnisation au profit du site.',
          text5: 'Une garantie optimale de la sécurité et de la confidentialité des données transmises n’est pas assurée par le site.',
          text6: 'Le site s’engage à mettre en œuvre tous les moyens nécessaires afin de garantir au mieux la sécurité et la confidentialité des données. Toutefois, le site ne pourrait être tenu responsable d’un vol de données.',
          text7: 'La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.',
        }
      },
      11: {
        titre: 'Liens hypertextes',
        content: {
          text1: 'De nombreux liens hypertextes sortants sont présents sur le site, cependant les pages web où mènent ces liens n’engagent en rien la responsabilité de <i>eleve.centre-europeen-formation.fr</i> qui n’a pas le contrôle de ces liens.',
          text2: 'L’Utilisateur s’interdit donc à engager la responsabilité du site concernant le contenu et les ressources relatives à ces liens hypertextes sortants.',
        }
      },
      12: {
        titre: 'Evolution du contrat',
        content: {
          text1: 'Le site se réserve à tout moment le droit de modifier les clauses stipulées dans les CGU.',
        }
      },
      13: {
        titre: 'Durée',
        content: {
          text1: 'Les CGU s’appliquent tant que l’Utilisateur dispose d’un compte utilisateur auprès du CEF sur le Site.',
        }
      },
      14: {
        titre: 'Droit applicable et juridiction compétente',
        content: {
          text1: 'La législation française s’applique au présent contrat. En cas d’absence de résolution amiable d’un litige né entre les parties, seuls les tribunaux de la Cour d’appel de Lille ou Paris sont compétents.',
        }
      }
    }
  }
};

export default fr;
