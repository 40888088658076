import Api , {params} from '../modules/Api.js';

import {IS_LOADING , ISNT_LOADING} from './isLoading';
import {SET_ERROR , RESET_ERROR} from './error';

export const GET_ME_SUCCESSFUL = 'users/GET_ME_SUCCESSFUL';
export const GET_ME_FAILED = 'users/GET_ME_FAILED';

export const CHANGE_ME_REQUESTED = 'users/CHANGE_ME_REQUESTED';
export const CHANGE_ME_SUCCESSFUL = 'users/CHANGE_ME_SUCCESSFUL';
export const CHANGE_ME_FAILED = 'users/CHANGE_ME_FAILED';

export const CHANGE_PASSWORD_REQUESTED = 'users/CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_SUCCESSFUL = 'users/CHANGE_PASSWORD_SUCCESSFUL';
export const CHANGE_PASSWORD_FAILED = 'users/CHANGE_PASSWORD_FAILED';

export const CHANGE_OFFER_REQUESTED = 'users/CHANGE_OFFER_REQUESTED';
export const CHANGE_OFFER_SUCCESSFUL = 'users/CHANGE_OFFER_SUCCESSFUL';
export const CHANGE_OFFER_FAILED = 'users/CHANGE_OFFER_FAILED';

export const DELETE_PICTURE_REQUESTED = 'users/DELETE_PICTURE_REQUESTED';
export const DELETE_PICTURE_SUCCESSFUL = 'users/DELETE_PICTURE_SUCCESSFUL';
export const DELETE_PICTURE_FAILED = 'users/DELETE_PICTURE_FAILED';

export const CHANGE_PICTURE_REQUESTED = 'users/CHANGE_PICTURE_REQUESTED';
export const CHANGE_PICTURE_SUCCESSFUL = 'users/CHANGE_PICTURE_SUCCESSFUL';
export const CHANGE_PICTURE_FAILED = 'users/CHANGE_PICTURE_FAILED';

export const DOCUMENT_REQUEST_REQUESTED = 'users/DOCUMENT_REQUEST_REQUESTED';
export const DOCUMENT_REQUEST_SUCCESSFUL = 'users/DOCUMENT_REQUEST_SUCCESSFUL';
export const DOCUMENT_REQUEST_FAILED = 'users/DOCUMENT_REQUEST_FAILED';

const initialState = {
  me: {} ,
};

export default function User(state = initialState , action) {
  switch (action.type) {
    case GET_ME_SUCCESSFUL:
      return {
        ...state ,
        me: action.me ,
        passwordHasChanged: undefined ,
      };
    case GET_ME_FAILED:
      return {
        ...state ,
        me: initialState.me ,
        passwordHasChanged: undefined ,
      };

    case CHANGE_PASSWORD_SUCCESSFUL:
      return {
        ...state ,
        passwordHasChanged: action.passwordHasChanged ,
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state ,
        passwordHasChanged: action.passwordHasChanged ,
      };

    case CHANGE_ME_SUCCESSFUL:
      return {
        ...state ,
        infosHasChanged: action.infosHasChanged ,
      };
    case CHANGE_ME_FAILED:
      return {
        ...state ,
        infosHasChanged: action.infosHasChanged ,
      };

    case CHANGE_OFFER_SUCCESSFUL:
      return {
        ...state ,
      };
    case CHANGE_OFFER_FAILED:
      return {
        ...state ,
      };

    case DELETE_PICTURE_SUCCESSFUL:
      return {
        ...state ,
      };
    case DELETE_PICTURE_FAILED:
      return {
        ...state ,
      };

    case CHANGE_PICTURE_SUCCESSFUL:
      return {
        ...state ,
        pictureHasChanged: action.pictureHasChanged ,
      };
    case CHANGE_PICTURE_FAILED:
      return {
        ...state ,
        pictureHasChanged: action.pictureHasChanged ,
      };

    case DOCUMENT_REQUEST_SUCCESSFUL:
      return {
        ...state ,
      };
    case DOCUMENT_REQUEST_FAILED:
      return {
        ...state ,
      };

    default:
      return state;
  }
}

export const getMe = () => (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});

  return Api('/users/me' , {params: params})
    .then((res) => {
      dispatch({
        type: GET_ME_SUCCESSFUL ,
        me: res.data ,
      });
      dispatch({type: ISNT_LOADING});
    })
    .catch((err) => {
      dispatch({type: GET_ME_FAILED});
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR ,
          value: err ,
        });
      }
      dispatch({type: ISNT_LOADING});
    });
};

export const changeMe = (data) => (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});
  dispatch({type: CHANGE_ME_REQUESTED});

  return Api.put('/users/me' , data , {params: params})
    .then((res) => {

      dispatch({
        type: CHANGE_ME_SUCCESSFUL ,
        infosHasChanged: res.status !== 500 ? true : false ,
      });
      dispatch({type: ISNT_LOADING});
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: CHANGE_ME_FAILED ,
        infosHasChanged: false ,
      });
      dispatch({
        type: SET_ERROR ,
        value: err ,
      });
      dispatch({type: ISNT_LOADING});
    });
};

export const changePassword = (newPassword) => (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});
  dispatch({type: CHANGE_PASSWORD_REQUESTED});

  return Api.put('/change_password' , newPassword , {params: params})
    .then((res) => {
      dispatch({
        type: CHANGE_PASSWORD_SUCCESSFUL ,
        passwordHasChanged: true ,
      });
      dispatch({type: ISNT_LOADING});
    })
    .catch((err) => {
      dispatch({
        type: CHANGE_PASSWORD_FAILED ,
        passwordHasChanged: false ,
      });
      dispatch({
        type: SET_ERROR ,
        value: err ,
      });
      dispatch({type: ISNT_LOADING});
    });
};

export const changeOffer = (newOffer) => (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});
  dispatch({type: CHANGE_OFFER_REQUESTED});

  return Api.put('/users/selected_domain_offer' , newOffer , {params: params})
    .then((res) => {
      dispatch({
        type: CHANGE_OFFER_SUCCESSFUL ,
      });
      dispatch({type: ISNT_LOADING});
    })
    .catch((err) => {
      dispatch({type: CHANGE_OFFER_FAILED});
      dispatch({
        type: SET_ERROR ,
      });
      dispatch({type: ISNT_LOADING});
    });
};

export const deletePicture = () => (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});
  dispatch({type: DELETE_PICTURE_REQUESTED});

  return Api.delete('/users/picture' , {params: params})
    .then((res) => {
      dispatch({
        type: DELETE_PICTURE_SUCCESSFUL ,
      });
      dispatch({type: ISNT_LOADING});
    })
    .catch((err) => {
      dispatch({type: DELETE_PICTURE_FAILED});
      dispatch({type: ISNT_LOADING});
    });
};

export const changePicture = (picture) => (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});
  dispatch({type: CHANGE_PICTURE_REQUESTED});

  let formData = new FormData();
  formData.append('picture' , picture);

  return Api.post('/users/picture' , formData , {
    headers: {'Content-Type': 'multipart/form-data'} ,
    params: params ,
  })
    .then(() => {
      dispatch({
        type: CHANGE_PICTURE_SUCCESSFUL ,
        pictureHasChanged: true ,
      });
      dispatch({type: ISNT_LOADING});
    })
    .catch((err) => {
      dispatch({
        type: CHANGE_PICTURE_FAILED ,
        pictureHasChanged: false ,
      });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR ,
          value: err ,
        });
      }
      dispatch({type: ISNT_LOADING});
    });
};

export const requestDocument = (documentId) => (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: IS_LOADING});
  dispatch({type: DOCUMENT_REQUEST_REQUESTED});

  return Api.post('/user_documents' , documentId , {params: params})
    .then(() => {
      dispatch({
        type: DOCUMENT_REQUEST_SUCCESSFUL ,
      });
      dispatch({type: ISNT_LOADING});
    })
    .catch((err) => {
      dispatch({
        type: DOCUMENT_REQUEST_FAILED ,
      });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR ,
          value: err ,
        });
      }
      dispatch({type: ISNT_LOADING});
    });
};
