import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { forced_logout } from '@/stores/auth';

import env from '../../../env';
// import { history } from '../../../store';

const storageKey = 'inactivityTimer';

const Idle = ({ children, logout }) => {
  const doLogout = () => {
    localStorage.removeItem(storageKey);
    logout();
    // history.push('/login', { from: history.location });
  };

  useEffect(() => {
    const stringTimer = localStorage.getItem(storageKey);
    if (stringTimer) {
      localStorage.setItem(storageKey, new Date().getTime());
      const timer = parseInt(stringTimer, 10);
      const delay = new Date().getTime() - timer;
      if (delay > env.inactivityTime) {
        doLogout();
      }
    }
  }, []);

  const onIdle = () => {
    if (
      window?.location?.pathname !== '/login'
      // history.location?.pathname !== '/login'
    ) {
      doLogout();
    }
  };

  const onAction = (e) => {
    localStorage.setItem(storageKey, new Date().getTime());
  };

  return (
    <IdleTimer
      element={document}
      onIdle={onIdle}
      timeout={env.inactivityTime}
      throttle={2000}
      onAction={onAction}
    >
      {children}
    </IdleTimer>
  );
};

Idle.defaultProps = {};
Idle.propTypes = {
  children: PropTypes.node.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout: () => forced_logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Idle);
