import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_USER_DOMAIN_OFFER_EXAMINATIONS_REQUESTED = 'userDomainOfferExaminations/GET_USER_DOMAIN_OFFER_EXAMINATION_REQUESTED';
export const GET_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL = 'userDomainOfferExaminations/GET_USER_DOMAIN_OFFER_EXAMINATION_SUCCESSFUL';
export const GET_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED = 'userDomainOfferExaminations/GET_USER_DOMAIN_OFFER_EXAMINATION_FAILED';

export const POST_USER_DOMAIN_OFFER_EXAMINATIONS_REQUESTED = 'userDomainOfferExaminations/POST_USER_DOMAIN_OFFER_EXAMINATION_REQUESTED';
export const POST_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL = 'userDomainOfferExaminations/POST_USER_DOMAIN_OFFER_EXAMINATION_SUCCESSFUL';
export const POST_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED = 'userDomainOfferExaminations/POST_USER_DOMAIN_OFFER_EXAMINATION_FAILED';

export const PUT_USER_DOMAIN_OFFER_EXAMINATIONS_REQUESTED = 'userDomainOfferExaminations/PUT_USER_DOMAIN_OFFER_EXAMINATION_REQUESTED';
export const PUT_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL = 'userDomainOfferExaminations/PUT_USER_DOMAIN_OFFER_EXAMINATION_SUCCESSFUL';
export const PUT_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED = 'userDomainOfferExaminations/PUT_USER_DOMAIN_OFFER_EXAMINATION_FAILED';

const initialState = {};

export default function userDomainOfferReducer(state = initialState, action){
  switch (action.type) {
    case GET_USER_DOMAIN_OFFER_EXAMINATIONS_REQUESTED:
      return initialState;
    case GET_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL:
      return action.data;
    case GET_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED:
      return initialState;

    case POST_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL:
      return {
        ...state,
        preRegistrationSend: action.preRegistrationSend
      };
    case POST_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED:
      return {
        ...state,
        preRegistrationSend: action.preRegistrationSend
      };

    case PUT_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL:
      return {
        ...state,
        preRegistrationSend: action.preRegistrationSend
      };
    case PUT_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED:
      return {
        ...state,
        preRegistrationSend: action.preRegistrationSend
      };

    default:
      return state;
  }
}

export const getUserDomainOfferExaminations = () =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_USER_DOMAIN_OFFER_EXAMINATIONS_REQUESTED });

    return Api('/user_domain_offer_examinations', { params: params })
      .then(res => {
        dispatch({
          type: GET_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL,
          data: {
            ...res.data
          }
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED });
        if (err.response.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const postPreregistration = informations =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: POST_USER_DOMAIN_OFFER_EXAMINATIONS_REQUESTED });

    return Api.post('/user_domain_offer_examinations/preregistration', informations, { params: params })
      .then(res => {
        res.status !== 404 && res.status !== 500 ?
          dispatch({
            type: POST_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL,
            preRegistrationSend: true
          }) :
          dispatch({
            type: POST_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED,
            preRegistrationSend: false
          });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({
          type: POST_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED,
          preRegistrationSend: false
        });
        if (err.response.status !== 401) {
          dispatch({
            type: POST_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED,
            value: err,
            preRegistrationSend: false
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };


export const putPreregistration = informations =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: PUT_USER_DOMAIN_OFFER_EXAMINATIONS_REQUESTED });
    return Api.put('/user_domain_offer_examinations/preregistration', informations, { params: params })
      .then(res => {
        res.status !== 404 && res.status !== 500 ?
          dispatch({
            type: PUT_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL,
            preRegistrationSend: true
          }) :
          dispatch({
            type: PUT_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED,
            preRegistrationSend: false
          });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({
          type: PUT_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED,
          preRegistrationSend: false
        });
        if (err.response.status !== 401) {
          dispatch({
            type: PUT_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED,
            preRegistrationSend: false,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };
