import 'cross-fetch/polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from '@/pages/app';
import { createToast } from '@/stores/toasts';
import registerServiceWorker from './registerServiceWorker';
import 'sanitize.css/sanitize.css';
import './main.css';
import './app.min.css';
import ErrorBoundary from '@/pages/app/ErrorBoundary';

const container = document.querySelector('#root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App/>
    </ErrorBoundary>
  </Provider>
);

registerServiceWorker(store, createToast);
