import React from 'react';
import PropTypes from 'prop-types';

const Final = ({ closeOnboarding, displayedSection }) => (
  <div className="onboarding__inner pgt-1 bg-white shadow bd-radius flex layout-column">
    <div className="flex layout-column align-center-center pg-1">
      <div className="w-7 h-7 round bg-success text-white flex align-center-center mgb-1">
        <svg className="w-4 h-4"><use xlinkHref="#icon-check" /></svg>
      </div>
      <h2 className="no-margin mgb-0_5 no-shadow">Bravo</h2>
      <p className="no-margin mgb-2">Vous avez validé les premières étapes de mise en place de votre plateforme d'apprentissage.</p>
      <div className="bd-1-gray-light bd-radius size-100p flex layout-column align-center-center pg-1 mgb-1">
        <div className="w-3 h-3 bd-radius bg-pink text-white flex align-center-center mgb-0_5">
          <svg className="w-2 h-2"><use xlinkHref="#icon-calendar" /></svg>
        </div>
        <p className="no-margin text-center">Vous pouvez personnaliser votre rythme d'apprentissage depuis votre agenda.</p>
      </div>
      <button className="button primary mgb-0" onClick={closeOnboarding}>Commencer</button>
    </div>
  </div>
);

Final.propTypes = {
  closeOnboarding: PropTypes.func,
  displayedSection: PropTypes.number
};

export default Final;
